import React from 'react';
import { Link } from 'react-router-dom';

const StartMyBusiness = () => {
  return (
    <>
    <div className="box-part">
      <div className="container">
        <div className="box-part11">
          <div className="row">
            <div className="col-md-4">
              <img src="images/box1.png" alt=""/>
              <div className="contant-box11">
                <h5>Stay Compliant </h5>
                <p>
                  We offer real-time updates and expert guidance to keep you
                  aligned with regulatory requirements.
                </p>
              </div>
            </div>
            <div className="col-md-4">
              <img src="images/box2.png" alt=""/>
              <div className="contant-box11">
                <h5>Over 1 Million Business Created</h5>
                <p>
                  Corpquick makes it easy and fast with tailored support, and
                  resources. We empower you to focus on your business.
                </p>
              </div>
            </div>
            <div className="col-md-4">
              <img src="images/box3.png" alt=""/>
              <div className="contant-box11">
                <h5>Easy To Use Platform</h5>
                <p>
                  Simplicity. That's what our platform is about. Entrepreneurs of
                  all skill levels can easily navigate the Corpquick process.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div className="greent-box">
      <div className="container">
        <div className="button-art1">
          <Link to="/form-order" className="start">
            Start My Business
          </Link>
        </div>
      </div>
    </div>
  </>
  
  )
}

export default StartMyBusiness
