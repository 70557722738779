import React, { useState, useEffect, useRef } from 'react';
import config from '../../config';
import { Link } from 'react-router-dom';

const Banner = () => {
    const [entityNames, setEntityNames] = useState([]);
    const [stateNames, setStateNames] = useState([]);
    const [selectedEntity, setSelectedEntity] = useState('');
    const [selectedEntityId, setSelectedEntityId] = useState('');
    const [selectedState, setSelectedState] = useState('');
    const [selectedStateId, setSelectedStateId] = useState('');
    const comparisonRef = useRef(null);  // Reference to the section to scroll

    useEffect(() => {
        fetch(`${config.baseUrl}/entity/list`)
            .then(response => response.json())
            .then(data => {
                if (data.status === 'success') {
                    setEntityNames(data.data);
                } else {
                    console.error('Failed to fetch entity names');
                }
            })
            .catch(error => {
                console.error('Error fetching entity names:', error);
            });

        fetch(`${config.baseUrl}/state/list`)
            .then(response => response.json())
            .then(data => {
                if (data.status === 'success') {
                    setStateNames(data.data);
                } else {
                    console.error('Failed to fetch state names');
                }
            })
            .catch(error => {
                console.error('Error fetching state names:', error);
            });
    }, []);

    const handleViewPackagesClick = (event) => {
        event.preventDefault();
        if (comparisonRef.current) {
            comparisonRef.current.scrollIntoView({ behavior: 'smooth', block: 'start' });
        }
    };

    return (
        <>
            <div className="banner-part">
                <div className="banner-content">
                    <h1>
                        Get Your LLC Up and <span>Running in Minutes</span>
                    </h1>
                    <p>
                        Made by entrepreneurs for entrepreneurs, we've leveled the playing field
                        for side hustlers, innovators, and experienced starters.
                    </p>
                </div>
                <div className="select-part">
                    <div className="select1">
                        <div className="left-entity">
                            <div className="entiity">Entity:</div>
                        </div>
                        <div className="right-select">
                            <select
                                className="form-select"
                                value={selectedEntity}
                                onChange={(e) => {
                                    const entity = e.target.value;
                                    const entityId = entityNames.find(ent => ent.entity_name === entity)?.id || '';
                                    setSelectedEntity(entity);
                                    setSelectedEntityId(entityId);
                                }}
                            >
                                <option value="" disabled>Select Entity Type</option>
                                {entityNames.map(entity => (
                                    <option key={entity.id} value={entity.entity_name}>
                                        {entity.entity_name}
                                    </option>
                                ))}
                            </select>
                        </div>
                    </div>

                    <div className="select1">
                        <div className="left-entity">
                            <div className="entiity">State:</div>
                        </div>
                        <div className="right-select">
                            <select
                                className="form-select"
                                value={selectedState}
                                onChange={(e) => {
                                    const state = e.target.value;
                                    const stateId = stateNames.find(st => st.state_name === state)?.id || '';
                                    setSelectedState(state);
                                    setSelectedStateId(stateId);
                                }}
                            >
                                <option value="" disabled>Choose State of Formation</option>
                                {stateNames.map(state => (
                                    <option key={state.id} value={state.state_name}>
                                        {state.state_name}
                                    </option>
                                ))}
                            </select>
                        </div>
                    </div>

                    <div className="packge-btn">
                        <div className="packge-btn1">
                            <Link to="#" onClick={handleViewPackagesClick}>View Packages</Link>
                        </div>
                        <div className="packge-btn2">
                            <Link
                                to={`/form-order?entity=${selectedEntityId}&state=${selectedStateId}`}
                                onClick={() => localStorage.removeItem('formOrderResult')}
                            >
                                START MY BUSINESS
                            </Link>
                        </div>
                    </div>
                </div>
            </div>

            {/* Target Section to Scroll */}
          

            <div className="banner-bottom-box">
                <div className="box1">
                    <div className="box11">
                        <div className="box-icon">
                            <img src="images/Group 11.png" alt="" />
                        </div>
                        <div className="box-right">
                            <p>Complimentary compliance reminders</p>
                        </div>
                    </div>
                    <div className="box11">
                        <div className="box-icon">
                            <img src="images/Group 11.png" alt="" />
                        </div>
                        <div className="box-right">
                            <p>Transparent business formation with no hidden costs</p>
                        </div>
                    </div>
                    <div className="box11">
                        <div className="box-icon">
                            <img src="images/Group 11.png" alt="" />
                        </div>
                        <div className="box-right">
                            <p>No commitments or subscriptions</p>
                        </div>
                    </div>
                </div>
            </div>
            <div ref={comparisonRef}>
            </div>
        </>
        
    );
};

export default Banner;
