import React, { useState } from 'react';
import config from '../../config';
import { Link, useNavigate } from 'react-router-dom';
import Header from '../Common/Header';
import Footer from '../Common/Footer';
import Swal from 'sweetalert2';

const Login = () => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState('');
  const navigate = useNavigate();

  const handleSubmit = async (event) => {
    event.preventDefault();
    setError('');

    try {
      const response = await fetch(`${config.baseUrl}/login`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ email, password }),
      });

      if (response.ok) {
        const data = await response.json();
        localStorage.setItem('token', data.token);
        localStorage.setItem('firstName', data.data[0].first_name);
        Swal.fire({
          icon: 'success',
          title: 'Login Successfully',
          showConfirmButton: false,
          timer: 2500, // Adjust the timer to control how long the message is displayed
        });
        navigate('/');
      } else {
        Swal.fire({
          icon: 'error',
          title: 'Incorrect Email or Password',
          showConfirmButton: true, // Show the "OK" button for user acknowledgment
          timer: 3000, // Optional: Auto-close after 3
        })
      }
    } catch (error) {
      setError('An error occurred. Please try again.');
    }
  };

  return (
    <>
      <Header />
      <section className="login-part">
        <div className="container">
          <div className="row">
            <div className="col-md-6">
              <div className="signup-form">
                <h2>
                  Sign in to <span>CORPQuick</span>
                </h2>
                <form onSubmit={handleSubmit}>
                  <input
                    type="text"
                    placeholder="Enter Your Email"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                  />
                  <input
                    type="password"
                    placeholder="Enter Your Password"
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                  />
                  <input type="submit" value="Submit" />
                  {error && <p style={{ color: 'red' }}>{error}</p>}
                  <div className='sign-up'>
                    <p>
                      <Link to="/forgot-password">Forgot Password</Link>
                    </p>

                  </div>
                </form>
              </div>
            </div>
          </div>
          <div className="col-md-6"></div>
        </div>
      </section>
      <Footer />
    </>
  );
};

export default Login;
