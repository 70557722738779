import React from 'react';
import { Link } from 'react-router-dom';

const WhatsCorpquick = () => {
  return (
    <div className="corp">
    <div className="container">
      <div className="heading-part">
        <h2>What is CorpQuick?</h2>
        <p>
        <strong>CorpQuick</strong> serves as the crucial link between your innovative business idea and its successful
launch. We expertly guide you through every step of forming a business, ensuring the process is
seamless, swift, and efficient. Our dedicated support throughout your entire journey highlights
our unwavering commitment to your success. At CorpQuick, your business aspirations are our
top priority, and we are here to transform your entrepreneurial dreams into reality.
        </p>
      </div>
      <div className="mask-group">
        <div className="row">
          <div className="col-md-4">
            <div className="mask-box">
              <img src="images/Mask group233.jpg" alt=""/>
            </div>
            <div className="mask-content">
              <h5>Your One-Stop Destination for Business Success</h5>
              <p>
              From the initial formation of your business to navigating challenges and uncertainties along the
              way, <strong>CorpQuick</strong> is here to support you every step of your entrepreneurial journey.
              </p>
            </div>
            <div className="masn-btn">
              <Link to="/form-order">
              <button type="button">Start an LLC</button>
              </Link>
            </div>
          </div>
          <div className="col-md-4">
            <div className="mask-box">
              <img src="images/Mask group.jpg" alt=""/>
            </div>
            <div className="mask-content">
              <h5>Created by Entrepreneurs, for Entrepreneurs</h5>
              <p>
              We understand what it takes to forge your own path—and we&#39;ve empowered over a million
              individuals to achieve their business goals.
              </p>
            </div>
            <div className="masn-btn">
              <Link to="/about-us">
              <button type="button">About CorpQuick</button>
              </Link>
            </div>
          </div>
          <div className="col-md-4">
            <div className="mask-box">
              <img src="images/Mask group (1).jpg" alt=""/>
            </div>
            <div className="mask-content">
              <h5>Transparent and Straightforward</h5>
              <p>
              Whether you&#39;re on day one or celebrating your fifth year, running a business is demanding
              enough. That&#39;s why we offer simple tools and transparent pricing with no hidden fees.
              </p>
            </div>
            <div className="masn-btn">
              <Link to="/privacy">
              <button type="button">Privacy Policy</button>
             </Link>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  
  )
}

export default WhatsCorpquick
