import React, { useState, useEffect } from 'react';
import Header from '../../Common/Header';
import Footer from '../../Common/Footer';
import { Link, useNavigate } from 'react-router-dom';
import config from '../../../config';

const TaxInvite = () => {
    const [formOrderData, setFormOrderData] = useState({ taxStrategyYesOrNo: "Not Interested" });
    const [apiData, setApiData] = useState(null);
    const [registeredAgentInformation, setRegisteredAgentInformation] = useState("");
    const navigate = useNavigate();
    let extractedId = null;
    const resultData = localStorage.getItem('formOrderResult');
    const [isSubmitting, setIsSubmitting] = useState(false);
      const [additionalServices, setAdditionalServices] = useState([]); 
    const [openSection, setOpenSection] = useState(null);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);

    // Parse ID from local storage if it exists
    if (resultData) {
        const parsedResult = JSON.parse(resultData);
        extractedId = parsedResult.data.id;
        console.log('Extracted ID:', extractedId);
    }

    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await fetch(`${config.baseUrl}/registration/get-form-1/${extractedId}`);
                if (response.ok) {
                    const data = await response.json();
                    setApiData(data.data);
                    if (data.data.registeredAgentInformation === "free") {
                        setRegisteredAgentInformation(true);
                    }
                    if (data.data.additional_services) {
                        const parsedServices = JSON.parse(data.data.additional_services);
                        setAdditionalServices(parsedServices);
                    }
                    setFormOrderData({
                        ...formOrderData,
                        taxStrategyYesOrNo: data.data.taxStrategyYesOrNo || "Not Interested"
                    });
                    console.log('Fetched API Data:', data.data);
                } else {
                    setError('Error fetching data');
                    console.error('Error fetching data:', response.statusText);
                }
            } catch (error) {
                setError('Error fetching data');
                console.error('Error:', error);
            } finally {
                setLoading(false);
            }
        };

        fetchData();
    }, [extractedId]);

    const handleChange = (event) => {
        setFormOrderData({
            ...formOrderData,
            taxStrategyYesOrNo: event.target.value
        });
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        setIsSubmitting(true);

        const payload = {
            taxStrategyYesOrNo: formOrderData.taxStrategyYesOrNo,
        };

        try {
            const response = await fetch(`${config.baseUrl}/registration/form-10/${extractedId}`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(payload),
            });

            if (response.ok) {
                const data = await response.json();
                navigate("/order-review");
                console.log('Form submission successful:', data);
            } else {
                console.error('Error in form submission:', response.statusText);
            }
        } catch (error) {
            console.error('Error:', error);
        } finally {
            setIsSubmitting(false);
        }
    };
    const toggleSection = (section) => {
        setOpenSection(openSection === section ? null : section);
    };

    return (
        <>
            <Header />
            <section className="registration-form">
                <div className="container">
                    <div className="registerformstart">
                        <div className="row">
                            <div className="col-md-8">
                                <form onSubmit={handleSubmit}>
                                    <div className="register-left">
                                        <div className="box__heading1 tax-strategy">
                                            Tax Strategy / Free Consultation
                                        </div>
                                        <p>
                                            This consultation can help you identify important tax deductions
                                            and provide insight into the IRS tax classification of your new
                                            business. The offer is completely free of charge and does not
                                            obligate you in any way.
                                        </p>
                                        <div className="divider mb25" />
                                        <h5 className='learn'>What you'll learn:</h5>
                                        <div className='learn-list'>
                                            <div className="row">
                                                <div className="col-md-6">
                                                    <ul className="list-unstyled">
                                                        <li><i className="fa fa-check" aria-hidden="true" /> How your {apiData ? apiData.selected_entity : ''} is taxed</li>
                                                        <li><i className="fa fa-check" aria-hidden="true" /> How to choose the proper IRS tax election</li>
                                                        <li><i className="fa fa-check" aria-hidden="true" /> Commonly missed tax deductions</li>
                                                    </ul>
                                                </div>
                                                <div className="col-md-6">
                                                    <ul className="list-unstyled">
                                                        <li><i className="fa fa-check" aria-hidden="true" /> Business bookkeeping requirements</li>
                                                        <li><i className="fa fa-check" aria-hidden="true" /> How to reduce the chance of an IRS audit</li>
                                                        <li><i className="fa fa-check" aria-hidden="true" /> How to reduce self-employment taxes</li>
                                                    </ul>
                                                </div>
                                            </div>
                                        </div>
                                        <div className='radio22'>
                                            <div className="custom_radio d-block mb25">
                                                <div className="mb-3">
                                                    <input
                                                        type="radio"
                                                        id="noTax"
                                                        name="taxClub"
                                                        value="Not Interested"
                                                        checked={formOrderData.taxStrategyYesOrNo === "Not Interested"}
                                                        onChange={handleChange}
                                                    />
                                                    <label htmlFor="noTax" className="d-block">
                                                        <span>I'm not interested at this time.</span>
                                                    </label>
                                                </div>
                                                <div>
                                                    <input
                                                        type="radio"
                                                        id="businessClub"
                                                        name="taxClub"
                                                        value="Business Tax Consultation Free"
                                                        checked={formOrderData.taxStrategyYesOrNo === "Business Tax Consultation Free"}
                                                        onChange={handleChange}
                                                    />
                                                    <label htmlFor="businessClub" className="d-block">
                                                        <span>Yes, I would like to receive the Business Tax Consultation (FREE).</span>
                                                    </label>
                                                </div>
                                            </div>
                                        </div>
                                        {formOrderData.taxStrategyYesOrNo === "Not Interested" && (
                                            <div className="accordion mb25" id="noTaxBlock">
                                                <div className="accordion-item">
                                                    <h2 className="accordion-header">
                                                        <button
                                                            className={`accordion-button ${openSection === 'sectionOne' ? '' : 'collapsed'}`}
                                                            type="button"
                                                            onClick={() => toggleSection('sectionOne')}
                                                        >
                                                            Is a Tax Strategy / Free Consultation something I should
                                                            consider?
                                                        </button>
                                                    </h2>
                                                    <div
                                                        id="panelsStayOpen-noTax"
                                                        className={`accordion-collapse collapse ${openSection === 'sectionOne' ? 'show' : ''}`}
                                                    >
                                                        <div className="accordion-body">
                                                            <p>
                                                                Incorporating a new business can burden owners with
                                                                complicated tax filing. As a client you are entitled to
                                                                receive a free no obligation consultation with a certified
                                                                tax professional who can answer questions regarding the
                                                                tax obligations of your company.{" "}
                                                            </p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        )}
                                        {formOrderData.taxStrategyYesOrNo === "Business Tax Consultation Free" && (
                                            <div
                                                className="accordion mb25"
                                                id="businessClubBlock"
                                            >
                                                <div className="accordion-item">
                                                    <h2 className="accordion-header">
                                                        <button
                                                            className={`accordion-button ${openSection === 'sectionTwo' ? '' : 'collapsed'}`}
                                                            type="button"
                                                            onClick={() => toggleSection('sectionTwo')}
                                                        >
                                                            How soon can I schedule my Tax Consultation?
                                                        </button>
                                                    </h2>
                                                    <div
                                                        id="panelsStayOpen-businessClub"
                                                        className={`accordion-collapse collapse ${openSection === 'sectionTwo' ? 'show' : ''}`}
                                                    >
                                                        <div className="accordion-body">
                                                            You will be prompted to schedule your appointment after
                                                            completing your order.
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        )}
                                        <div className="button-nest-prev">
                                            <div className="left-next">
                                        
                                                <Link to="/small-banking">
                                                <button type="button">
                                                    <i className="fa fa-arrow-left" aria-hidden="true" /> Back
                                                    </button>
                                                </Link>
                                           
                                            </div>
                                            <div className="right-next nex2">
                                                <button type="submit" disabled={isSubmitting}>
                                                    {isSubmitting ? "Submitting..." : "Next"}
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                </form>
                            </div>
                            <div className="col-md-4">
                                <div className="register-right">
                                    <h4>Order Summary</h4>
                                    <h2>{apiData ? apiData.full_business_name : ''}</h2>
                                    <div className="progress">
                                        <div
                                            className="progress-bar"
                                            role="progressbar"
                                            style={{ width: "75%" }}
                                            aria-valuenow={75}
                                            aria-valuemin={0}
                                            aria-valuemax={100}
                                        >
                                            75%
                                        </div>
                                    </div>
                                    <div className="order-items flex flex-col gap-3 mt-8">
                                    <div className="item">
                                            <div className="name">{apiData ? apiData.package : ''} Package</div>
                                            <div className="value ">${apiData ? apiData.package_fee : ''}</div>
                                        </div>
                                        {additionalServices.map((service, index) => (
                                            <div className="item" key={index}>
                                                <div className="name">{service.name}</div>
                                                <div className="value">${service.price}</div>
                                            </div>
                                        ))}
                                        <div className="item">
                                            <div className="name">{apiData ? apiData.selected_state : ''} State Filing Fee</div>
                                            <div className="value">
                                                <span id="dynamic-filing-fee">${apiData ? apiData.stateFee : ''}</span>
                                            </div>
                                        </div>
                                        {apiData?.companyAddress === "Professional Business Address" && (
                                            <div className="item" id="order-item-legal-consulation">
                                                <div className="name">Business Address</div>
                                                <div className="value">
                                                    $<span id="dynamic-filing-fee">29</span>
                                                </div>
                                            </div>
                                        )}
                                        <div className="item hide" id="order-item-legal-consulation">
                                            <div className="name">Banking Resolution</div>
                                            <div className="value">
                                                <i className="fa fa-check" aria-hidden="true" />
                                            </div>
                                        </div>
                                        <div className="item hide" id="order-item-expedited">
                                            <div className="name">Banking Resolution</div>
                                            <div className="value">
                                                <i className="fa fa-check" aria-hidden="true" />
                                            </div>
                                        </div>
                                        <div className="item hide" id="order-item-legal-consulation">
                                            <div className="name">Minutes Meeting</div>
                                            <div className="value">
                                                <i className="fa fa-check" aria-hidden="true" />
                                            </div>
                                        </div>
                                        <div className="item hide" id="order-item-license-package">
                                            <div className="name">Bylaws</div>
                                            <div className="value">
                                                <i className="fa fa-check" aria-hidden="true" />
                                            </div>
                                        </div>
                                        {registeredAgentInformation && (
                                            <div className="item">
                                                <div className="name">Registered Agent (1st Year)</div>
                                                <div className="value">
                                                    Free
                                                </div>
                                            </div>
                                        )}
                                        <div className="item hide" id="order-item-va">
                                            <div className="name">EIN / Tax ID #</div>
                                            <div className="value">
                                                <i className="fa fa-check" aria-hidden="true" />
                                            </div>
                                        </div>
                                        <div className="item">
                                            <div className="name">Electronic Delivery</div>
                                            <div className="value">
                                                <i className="fa fa-check" aria-hidden="true" />
                                            </div>
                                        </div>
                                        <div className="item">
                                            <div className="name">Form 2553</div>
                                            <div className="value">
                                                <i className="fa fa-check" aria-hidden="true" />
                                            </div>
                                        </div>
                                        {formOrderData.taxStrategyYesOrNo === "Business Tax Consultation Free" && (
                                            <div className="item" id="order-item-legal-consulation">
                                                <div className="name">Tax Consultation</div>
                                                <div className="value">
                                                    Free
                                                </div>
                                            </div>
                                        )}



                                        <div className="item">
                                            <div className="name flex flex-col">
                                                <span>Phone Support</span>
                                                <span className="text-xs text-gray-700 mt-[6px]">
                                                    Available Monday - Friday from 9 a.m. to 6 p.m. CST
                                                </span>
                                            </div>
                                            <div className="value">
                                                <i className="fa fa-check" aria-hidden="true" />
                                            </div>
                                        </div>
                                        <hr className="!mt-1 !mb-1" />
                                        <div className="totla-value1">
                                            <div className="item items-center mb-8">
                                                <div className="name">Total</div>
                                                <div className="value bold text-black">
                                                    <span className="SumPackageTotal text-2xl">
                                                        $<span className="totalPrice">
                                                            {apiData
                                                                ? parseFloat(apiData.form_total_price || 0) +
                                                                (apiData.companyAddress === "Professional Business Address" ? 29 : 0)
                                                                : ''}
                                                        </span>
                                                    </span>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="trustpilot">
                                            <img src="images/Trustpilot-Reviews-Services-1.png" alt="" />
                                            <p>TrustScore 4.7 | 19,076 reviews</p>
                                        </div>
                                 
                                        <div className="join-business">
                                            <div className="font-radwave text-black">
                                                <h2 className="text-base tracking-wide text-left  my-0">
                                                    JOIN THE
                                                </h2>
                                                <p className="text-base text-red-500 tracking-wide ">
                                                    1,000,000+
                                                </p>
                                                <h3 className="text-base tracking-wide uppercase my-0">
                                                    Businesses
                                                </h3>
                                                <h6>
                                                    That have trusted Corpquick to start, manage, and grow their
                                                    business{" "}
                                                </h6>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <Footer />
        </>
    );
};

export default TaxInvite;
