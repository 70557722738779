import React, { useState, useEffect } from 'react';
import $ from 'jquery';
import Header from '../../Common/Header';
import Footer from '../../Common/Footer';
import { Link, useNavigate } from 'react-router-dom';
import '../registerstyle.css';
import CountrySelector from '../../Common/Country';
import config from '../../../config';

const CompanyAddressInformation = () => {
    const [formOrderData, setFormOrderData] = useState({
        companyAddress: 'Professional Business Address',
        companyInformationStreetAddress: '',
        companyInformationAddressCont: '',
        companyInformationCity: '',
        companyInformationState: '',
        companyInformationZipCode: ''
    });
    const [additionalServices, setAdditionalServices] = useState([]);
    const [showBusinessAddress, setShowBusinessAddress] = useState(true);
    const [stateNames, setStateNames] = useState([]);
    const [selectedState, setSelectedState] = useState('');
    const [selectedCountry, setSelectedCountry] = useState('');
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [apiData, setApiData] = useState(null);
    const navigate = useNavigate();
    const resultData = localStorage.getItem('formOrderResult');
    let extractedId = null;

    if (resultData) {
        const parsedResult = JSON.parse(resultData);
        extractedId = parsedResult.data.id;
        console.log('Extracted ID:', extractedId);
    }

    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await fetch(`${config.baseUrl}/registration/get-form-1/${extractedId}`);
                if (response.ok) {
                    const data = await response.json();
                    setApiData(data.data);
                    setShowBusinessAddress(data.data.companyAddress === 'Professional Business Address');
                    if (data.data.additional_services) {
                        const parsedServices = JSON.parse(data.data.additional_services);
                        setAdditionalServices(parsedServices);
                    }
                } else {
                    console.error('Error fetching data:', response.statusText);
                }
            } catch (error) {
                console.error('Error:', error);
            }
        };

        fetchData();
    }, [extractedId]);
    console.log(apiData)

    useEffect(() => {
        const savedFormOrder = localStorage.getItem('formOrder');
        if (savedFormOrder) {
            setFormOrderData(JSON.parse(savedFormOrder));
        }

        $(document).ready(function () {
            $('.large-box input[type="radio"]').on('click', function () {
                $('input[type="radio"]').removeClass('activated');
                $('input[type="radio"]').parent().parent().removeClass('borderactivated');
                $(this).addClass('activated');
                $(this).parent().parent().addClass('borderactivated');
            });

            $('.custom-radio').on('click', function () {
                $('.right-next.nex2 a').css('opacity', '1');
            });
        });
    }, []);
    useEffect(() => {

        fetch(`${config.baseUrl}/state/list`)
            .then(response => response.json())
            .then(data => {
                if (data.status === 'success') {
                    setStateNames(data.data);
                } else {
                    console.error('Failed to fetch state names');
                }
            })
            .catch(error => {
                console.error('Error fetching state names:', error);
            });
    }, []);
    useEffect(() => {
        if (apiData) {
            setFormOrderData({
                ...formOrderData,
                companyInformationState: apiData.companyAddressState || ''
            });
            setSelectedState(apiData.companyAddressState || '');
        }
    }, [apiData]);

    const handleRadioChange = (e) => {
        const selectedValue = e.target.value === 'true'; // Check if the selected value is "true"
        setShowBusinessAddress(selectedValue);
        const addressType = selectedValue ? 'Professional Business Address' : 'Own Address';
        localStorage.setItem('companyInformationBusinessAddress', selectedValue ? '29' : '0');
        setFormOrderData((prevData) => ({
            ...prevData,
            companyAddress: addressType,
        }));
    };

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setFormOrderData(prevData => {
            const updatedData = { ...prevData, [name]: value };
            localStorage.setItem('formOrder', JSON.stringify(updatedData));
            return updatedData;
        });
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        setIsSubmitting(true);

        const companyAddress = showBusinessAddress
            ? 'Professional Business Address'
            : 'Own Address';

        const payload = {
            companyAddress: companyAddress,
            companyAddressCountry: selectedCountry,
            companyAddressStreetAddress: formOrderData.companyInformationStreetAddress,
            companyAddressCont: formOrderData.companyInformationAddressCont,
            companyAddressCity: formOrderData.companyInformationCity,
            companyAddressState: formOrderData.companyInformationState,
            companyAddressZipCode: formOrderData.companyInformationZipCode,
            businessAddress: showBusinessAddress
        };

        try {
            const response = await fetch(`${config.baseUrl}/registration/form-5/${extractedId}`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(payload)
            });

            if (response.ok) {
                const data = await response.json();
                navigate("/process-member");
                console.log('Form submission successful:', data);
            } else {
                console.error('Error in form submission:', response.statusText);
            }
        } catch (error) {
            console.error('Error:', error);
        } finally {
            setIsSubmitting(false);
        }
    };

    return (
        <>
            <Header />
            <section className="registration-from">
                <div className="container">
                    <div className="registerformstart">
                        <div className="row">
                            <div className="col-md-8">
                                <form onSubmit={handleSubmit}>

                                    <div className="register-left">
                                        <div className="box__heading">Company Address Information</div>
                                        <p>
                                            We recommend using our Virtual Address service if maintaining your
                                            personal privacy is important. The State of {apiData ? apiData.selected_state : ''} collects and
                                            makes publicly available both the business address of the entity
                                            as well as the personal addresses of the owners.
                                        </p>
                                        <p>Benefits of Using a Private Virtual Mail Address</p>
                                        <ul className="list">
                                            <li>
                                                <i className="fa fa-check" aria-hidden="true" /> Keeping your
                                                personal address confidential
                                            </li>
                                            <li>
                                                <i className="fa fa-check" aria-hidden="true" /> Real-time text
                                                and email notifications of any incoming mail{" "}
                                            </li>
                                            <li>
                                                <i className="fa fa-check" aria-hidden="true" /> Maintaining a
                                                physical presence, even if you’re not physically there
                                            </li>
                                            <li>
                                                <i className="fa fa-check" aria-hidden="true" /> Permanent
                                                digital access to your mail anywhere in the world{" "}
                                            </li>
                                        </ul>
                                        <div className="radio-part">
                                            <div className="row">
                                                <div className="col-md-6">
                                                    <div className="large-box">
                                                        <div className="spacing">
                                                            <div className="fast1">
                                                                <Link to="#" onClick={(e) => e.preventDefault()}>Privacy</Link>
                                                            </div>
                                                            <h4>
                                                                Professional Business Address &amp; Virtual Mail Service
                                                            </h4>
                                                            <svg
                                                                width={49}
                                                                height={49}
                                                                viewBox="0 0 49 49"
                                                                fill="none"
                                                                xmlns="http://www.w3.org/2000/svg"
                                                            >
                                                                <path
                                                                    d="M40.0365 34.4797C42.8161 35.5343 44.481 36.8772 44.481 38.3395C44.481 41.7306 35.5267 44.4797 24.481 44.4797C13.4353 44.4797 4.48096 41.7306 4.48096 38.3395C4.48096 36.8772 6.14585 35.5343 8.9254 34.4797M28.481 16.4797C28.481 18.6889 26.6901 20.4797 24.481 20.4797C22.2718 20.4797 20.481 18.6889 20.481 16.4797C20.481 14.2706 22.2718 12.4797 24.481 12.4797C26.6901 12.4797 28.481 14.2706 28.481 16.4797Z"
                                                                    stroke="#FF4A00"
                                                                    strokeWidth="2.94001"
                                                                    strokeLinecap="round"
                                                                    strokeLinejoin="round"
                                                                />
                                                                <path
                                                                    d="M10.4805 18.0801C10.4805 10.08 16.7027 4.47998 24.4805 4.47998C32.2582 4.47998 38.4805 10.08 38.4805 18.0801C38.4805 30.6431 27.5916 36.48 24.4805 36.48C21.3694 36.48 10.4805 30.6431 10.4805 18.0801Z"
                                                                    stroke="#1C1B18"
                                                                    strokeWidth="2.94001"
                                                                    strokeLinecap="round"
                                                                    strokeLinejoin="round"
                                                                />
                                                            </svg>
                                                            <p>This will be your principal company address:</p>
                                                            <h5>441 W 5th Ave Suite 405</h5>
                                                            <h5> Anchorage, AK 99501</h5>
                                                            <small>
                                                                (Suite # will be assigned after the order completion)
                                                            </small>
                                                            <ul className="list">
                                                                <li>
                                                                    <i className="fa fa-check" aria-hidden="true" />
                                                                    Maintain privacy by using a commercial address
                                                                </li>
                                                                <li>
                                                                    <i className="fa fa-check" aria-hidden="true" />
                                                                    Unlimited scanned incoming mail
                                                                </li>
                                                                <li>
                                                                    <i className="fa fa-check" aria-hidden="true" />A
                                                                    physical address for your business (not PO Box)
                                                                </li>
                                                                <li>
                                                                    <i className="fa fa-check" aria-hidden="true" />
                                                                    Instant alerts with 24/7 access to your mail online
                                                                </li>
                                                            </ul>
                                                            <div className="amount">$29/month</div>
                                                            <h3> </h3>
                                                        </div>
                                                        <div className={`custom-radio ${showBusinessAddress ? 'activated' : ''}`}>
                                                            <input
                                                                type="radio"
                                                                id="radio-1-1"
                                                                name="addressType"
                                                                value="true"
                                                                checked={showBusinessAddress}
                                                                onChange={handleRadioChange}
                                                            />
                                                            <label htmlFor="radio-1-1" />
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-md-6">
                                                    <div className="large-box">
                                                        <div className="spacing">
                                                            <svg
                                                                width={49}
                                                                height={49}
                                                                viewBox="0 0 49 49"
                                                                fill="none"
                                                                xmlns="http://www.w3.org/2000/svg"
                                                            >
                                                                <path
                                                                    d="M30.4805 44.48V30.48C30.4805 28.5408 30.4805 27.5712 30.145 26.8132C29.7505 25.9222 29.0383 25.2099 28.1472 24.8155C27.3893 24.48 26.4197 24.48 24.4805 24.48V24.48C22.5412 24.48 21.5716 24.48 20.8137 24.8155C19.9226 25.2099 19.2104 25.9222 18.816 26.8132C18.4805 27.5712 18.4805 28.5408 18.4805 30.48V44.48"
                                                                    stroke="#FF4A00"
                                                                    strokeWidth="2.94001"
                                                                    strokeLinecap="round"
                                                                    strokeLinejoin="round"
                                                                />
                                                                <path
                                                                    d="M44.481 28.7999V24.0162C44.481 21.2487 44.481 19.8649 44.129 18.5873C43.8172 17.4555 43.3046 16.3889 42.6155 15.4384C41.8378 14.3654 40.7572 13.501 38.5962 11.7721L34.2762 8.31618C30.7804 5.5195 29.0324 4.12116 27.094 3.58489C25.3841 3.11185 23.5778 3.11185 21.868 3.58489C19.9295 4.12116 18.1816 5.5195 14.6857 8.31618L14.6857 8.31618L10.3658 11.7721C8.20469 13.501 7.12415 14.3654 6.34638 15.4384C5.65735 16.3889 5.14472 17.4555 4.83292 18.5873C4.48096 19.8649 4.48096 21.2487 4.48096 24.0162V28.7999C4.48096 34.2885 4.48096 37.0327 5.5491 39.1291C6.48866 40.9731 7.98787 42.4723 9.83187 43.4118C11.9282 44.48 14.6725 44.48 20.161 44.48H28.8009C34.2894 44.48 37.0337 44.48 39.13 43.4118C40.974 42.4723 42.4733 40.9731 43.4128 39.1291C44.481 37.0327 44.481 34.2885 44.481 28.7999Z"
                                                                    stroke="#1C1B18"
                                                                    strokeWidth="2.94001"
                                                                    strokeLinecap="round"
                                                                    strokeLinejoin="round"
                                                                />
                                                            </svg>
                                                            <h6 className="">
                                                                I will provide my own {apiData ? apiData.selected_state : ''} business address and will
                                                                personally keep up with the incoming mail.
                                                            </h6>
                                                            <h6 className="">
                                                                {apiData ? apiData.selected_state : ''} requires a physical street address (P.O Boxes are
                                                                not accepted).
                                                            </h6>
                                                            <h6 className="">
                                                                Any residential address provided to the state will be
                                                                listed publicly.
                                                            </h6>
                                                        </div>
                                                        <div className={`custom-radio no-bg ${!showBusinessAddress ? 'activated' : ''}`}>
                                                            <input
                                                                type="radio"
                                                                id="radio-1-2"
                                                                name="addressType"
                                                                value="false"
                                                                checked={!showBusinessAddress}
                                                                onChange={handleRadioChange}
                                                            />
                                                            <label htmlFor="radio-1-2" />
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="register-left mt-3">
                                        <div className="box__heading">Contact Address</div>
                                        <div className="conact-form">
                                            {/* {showBusinessAddress && (
                                                <div className="row">
                                                    <div className="col-md-12">
                                                        <label>Country</label>
                                                        <CountrySelector
                                                            selectedCountry={selectedCountry}
                                                            onCountryChange={handleCountryChange}
                                                        />
                                                    </div>
                                                </div>
                                            )} */}
                                            <div className="row">
                                                <div className="col-md-6">
                                                    <label>Street Address</label>
                                                    <input
                                                        type="text"
                                                        id="companyInformationStreetAddress"
                                                        name="companyInformationStreetAddress"
                                                        value={formOrderData.companyInformationStreetAddress}
                                                        onChange={handleInputChange}
                                                        required
                                                    />
                                                </div>
                                                <div className="col-md-6">
                                                    <label>Address (Cont)</label>
                                                    <input
                                                        type="text"
                                                        id="companyInformationAddressCont"
                                                        name="companyInformationAddressCont"
                                                        value={formOrderData.companyInformationAddressCont}
                                                        onChange={handleInputChange}
                                                    />
                                                </div>
                                            </div>
                                            <div className="row">
                                                <div className="col-md-12">
                                                    <label>City</label>
                                                    <input
                                                        type="text"
                                                        id="companyInformationCity"
                                                        name="companyInformationCity"
                                                        value={formOrderData.companyInformationCity}
                                                        onChange={handleInputChange}
                                                        required
                                                    />
                                                </div>
                                            </div>
                                            <div className="row">
                                                <div className="col-md-6">
                                                    <label>State</label>
                                                    <select
                                                        className="form-select"
                                                        value={selectedState}
                                                        onChange={(e) => {
                                                            const state = e.target.value;
                                                            setSelectedState(state);
                                                            setFormOrderData((prevData) => ({
                                                                ...prevData,
                                                                companyInformationState: state,
                                                            }));
                                                        }}
                                                    >
                                                        <option value="" disabled>Choose State of Formation</option>
                                                        {stateNames.map((state) => (
                                                            <option key={state.id} value={state.state_name}>
                                                                {state.state_name}
                                                            </option>
                                                        ))}
                                                    </select>
                                                </div>

                                                <div className="col-md-6">
                                                    <label>Zip Code</label>
                                                    <input
                                                        type="number"
                                                        id="companyInformationZipCode"
                                                        name="companyInformationZipCode"
                                                        value={formOrderData.companyInformationZipCode}
                                                        onChange={handleInputChange}
                                                        minLength={5}
                                                        maxLength={9}
                                                        required
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="button-nest-prev">
                                        <div className="left-next">
                                            <Link to="/process-contact-person">
                                                <button type="button">
                                                    <i className="fa fa-arrow-left" aria-hidden="true" />
                                                    Back
                                                </button>
                                            </Link>
                                        </div>
                                        <div className="right-next">
                                            <button type="submit" disabled={isSubmitting}>
                                                {isSubmitting ? 'Submitting...' : 'Next'}
                                            </button>
                                        </div>
                                    </div>

                                </form>
                            </div>
                            <div className="col-md-4">
                                <div className="register-right">
                                    <h4>Order Summary</h4>
                                    <h2>{apiData ? apiData.full_business_name : ''}</h2>
                                    <div className="progress">
                                        <div
                                            className="progress-bar"
                                            role="progressbar"
                                            style={{ width: "35%" }}
                                            aria-valuenow={35}
                                            aria-valuemin={0}
                                            aria-valuemax={100}
                                        >
                                            35%
                                        </div>
                                    </div>
                                    <div className="order-items flex flex-col gap-3 mt-8">
                                        <div className="item">
                                            <div className="name">{apiData ? apiData.package : ''} Package</div>
                                            <div className="value ">${apiData ? apiData.package_fee : ''}</div>
                                        </div>
                                        {additionalServices.map((service, index) => (
                                            <div className="item" key={index}>
                                                <div className="name">{service.name}</div>
                                                <div className="value">${service.price}</div>
                                            </div>
                                        ))}
                                        <div className="item">
                                            <div className="name">{apiData ? apiData.selected_state : ''} State Filing Fee</div>
                                            <div className="value">
                                                <span id="dynamic-filing-fee">${apiData ? apiData.stateFee : ''}</span>
                                            </div>
                                        </div>
                                        {showBusinessAddress && (
                                            <div className="item" id="order-item-legal-consulation">
                                                <div className="name">Business Address</div>
                                                <div className="value">
                                                    $<span id="dynamic-filing-fee">29</span>
                                                </div>
                                            </div>
                                        )}

                                        <div className="item hide" id="order-item-legal-consulation">
                                            <div className="name">Banking Resolution</div>
                                            <div className="value">
                                                <i className="fa fa-check" aria-hidden="true" />
                                            </div>
                                        </div>
                                        <div className="item hide" id="order-item-expedited">
                                            <div className="name">Banking Resolution</div>
                                            <div className="value">
                                                <i className="fa fa-check" aria-hidden="true" />
                                            </div>
                                        </div>
                                        <div className="item hide" id="order-item-legal-consulation">
                                            <div className="name">Minutes Meeting</div>
                                            <div className="value">
                                                <i className="fa fa-check" aria-hidden="true" />
                                            </div>
                                        </div>
                                        <div className="item hide" id="order-item-license-package">
                                            <div className="name">Bylaws</div>
                                            <div className="value">
                                                <i className="fa fa-check" aria-hidden="true" />
                                            </div>
                                        </div>
                                        <div className="item hide" id="order-item-va">
                                            <div className="name">EIN / Tax ID #</div>
                                            <div className="value">
                                                <i className="fa fa-check" aria-hidden="true" />
                                            </div>
                                        </div>
                                        <div className="item">
                                            <div className="name">Electronic Delivery</div>
                                            <div className="value">
                                                <i className="fa fa-check" aria-hidden="true" />
                                            </div>
                                        </div>
                                        <div className="item">
                                            <div className="name">Form 2553</div>
                                            <div className="value">
                                                <i className="fa fa-check" aria-hidden="true" />
                                            </div>
                                        </div>

                                        <div className="item">
                                            <div className="name flex flex-col">
                                                <span>Phone Support</span>
                                                <span className="text-xs text-gray-700 mt-[6px]">
                                                    Available Monday - Friday from 9 a.m. to 6 p.m. CST
                                                </span>
                                            </div>
                                            <div className="value">
                                                <i className="fa fa-check" aria-hidden="true" />
                                            </div>
                                        </div>
                                        <hr className="!mt-1 !mb-1" />
                                        <div className="totla-value1">
                                            <div className="item items-center mb-8">
                                                <div className="name">Total</div>
                                                <div className="value bold text-black">
                                                    <span className="SumPackageTotal text-2xl">
                                                        $<span className="totalPrice">
                                                            {apiData
                                                                ? parseFloat(apiData.form_total_price) +
                                                                (showBusinessAddress ? 29 : 0)
                                                                : ""}
                                                        </span>
                                                    </span>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="trustpilot">
                                            <img src="images/Trustpilot-Reviews-Services-1.png" alt="" />
                                            <p>TrustScore 4.7 | 19,076 reviews</p>
                                        </div>
                                        <div className="shopper-approved">
                                            <img src="images/shopperapproved.png" alt="" />
                                            <ul>
                                                <li>
                                                    <Link to="#">
                                                        <i className="fa fa-star" aria-hidden="true" />
                                                    </Link>
                                                </li>
                                                <li>
                                                    <Link to="#">
                                                        <i className="fa fa-star" aria-hidden="true" />
                                                    </Link>
                                                </li>
                                                <li>
                                                    <Link to="#">
                                                        <i className="fa fa-star" aria-hidden="true" />
                                                    </Link>
                                                </li>
                                                <li>
                                                    <Link to="#">
                                                        <i className="fa fa-star" aria-hidden="true" />
                                                    </Link>
                                                </li>
                                                <li>
                                                    <Link to="#">
                                                        <i className="fa fa-star-half-o" aria-hidden="true" />
                                                    </Link>
                                                </li>
                                            </ul>

                                        </div>
                                        <div className="join-business">
                                            <div className="font-radwave text-black">
                                                <h2 className="text-base tracking-wide text-left  my-0">
                                                    JOIN THE
                                                </h2>
                                                <p className="text-base text-red-500 tracking-wide ">
                                                    1,000,000+
                                                </p>
                                                <h3 className="text-base tracking-wide uppercase my-0">
                                                    Businesses
                                                </h3>
                                                <h6>
                                                    That have trusted Corpquick to start, manage, and grow their
                                                    business{" "}
                                                </h6>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
            </section>
            <Footer />
        </>
    )
}

export default CompanyAddressInformation;
