import React, { useState, useEffect } from 'react';
import config from '../../config';
import { Link } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';

const Dashboard = () => {
    const [email, setEmail] = useState('');
    const [token, setToken] = useState(localStorage.getItem('token'));
    const [apiData, setApiData] = useState([]); // Initialize as an empty array
    const navigate = useNavigate();
    const [showModal, setShowModal] = useState(false);
    const [selectedItem, setSelectedItem] = useState(null);
    const [loading, setLoading] = useState(true); // Loading state

    useEffect(() => { // Get token from storage
        if (!token) {
            navigate("/login"); // Redirect to login if no token
        }else {
            setLoading(false); // Allow page to load if token exists
        }
    }, [navigate]);

    // Fetch email using token
    useEffect(() => {
        const fetchEmail = async () => {
            try {
                if (token) {
                    const response = await fetch(`${config.baseUrl}/welcome`, {
                        method: 'GET',
                        headers: {
                            'Content-Type': 'application/json',
                            'Authorization': `Bearer ${token}`,
                        },
                    });

                    if (response.ok) {
                        const data = await response.json();
                        setEmail(data.data.email);
                    } else {
                        console.error('Failed to fetch email:', response.statusText);
                    }
                }
            } catch (error) {
                console.error('Error fetching email:', error);
            }
        };

        fetchEmail();
    }, [token]);

    // Fetch data from API
    useEffect(() => {
        const fetchData = async () => {
            try {
                if (email) {
                    const response = await fetch(`${config.baseUrl}/registration/documents-user`, {
                        method: 'POST',
                        headers: {
                            'Content-Type': 'application/json',
                            'Authorization': `Bearer ${token}`,
                        },
                        body: JSON.stringify({
                            contact_person_email: email,
                        }),
                    });

                    if (response.ok) {
                        const data = await response.json();
                        setApiData(data.data); // Expecting data to be an array
                    } else {
                        console.error('Failed to fetch data:', response.statusText);
                    }
                }
            } catch (error) {
                console.error('An error occurred while fetching data:', error);
            }
        };

        if (email) {
            fetchData();
        }
    }, [email, token]);

    // Handle logout
    const handleLogout = async () => {
        try {
            const response = await fetch(`${config.baseUrl}/logout`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`,
                },
            });

            if (response.ok) {
                localStorage.removeItem('token');
                setToken(null);
                setEmail('');
                navigate('/login');
            } else {
                console.error('Logout failed');
            }
        } catch (error) {
            console.error('An error occurred during logout:', error);
        }
    };
    const handleViewDetails = (item) => {
        setSelectedItem(item); // Set the selected item's data
        setShowModal(true); // Show the modal
    };

    if (loading) {
        return <h1>Loading...</h1>; // Show loading until check is complete
    }

    return (
        <>
            <div className="sidebar d-flex flex-column p-3">
                <Link to="/">
                    <div className="dashbord-logo text-center">
                        <img src="images/corpquick-final-Tranparen bg 1.png" alt="CorpQuick Logo" />
                    </div>
                </Link>
                <h4 className="mb-4 dashborad-heading">Dashboard</h4>
                <div className="dashboard-tab">
                    <nav className="nav nav-pills flex-column" id="v-tabs" role="tablist">
                        <a
                            className="nav-link"
                            id="v-home-tab"
                            data-bs-toggle="pill"
                            href="#v-home"
                            role="tab"
                            aria-controls="v-home"
                            aria-selected="true"
                        >
                            <i className="fa fa-home" aria-hidden="true"></i>
                            Bookings
                        </a>

                        <a
                            className="nav-link"
                            onClick={handleLogout}
                            id="v-logout-tab"
                            data-bs-toggle="pill"
                            href="#v-logout"
                            role="tab"
                            aria-controls="v-logout"
                            aria-selected="false"
                        >
                            <i className="fa fa-sign-out" aria-hidden="true"></i>
                            Logout
                        </a>
                    </nav>
                </div>
            </div>

            <div className="content">
                <div className="tab-content" id="v-tabs-content">
                    <div
                        className="tab-pane fade show active"
                        id="v-home"
                        role="tabpanel"
                        aria-labelledby="v-home-tab"
                    >
                        <div className="detail-user">
                            <h4>Company Information</h4>
                        </div>
                        <div className="profile-table">
                            <table className='dashboard-table'>
                                <thead>
                                    <tr>
                                        <th>#ID</th>
                                        <th>Business Name</th>
                                        <th>Entity</th>
                                        <th>State</th>
                                        <th>Email</th>
                                        <th>Phone Number</th>
                                        <th>Details</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {apiData.length > 0 ? (
                                        apiData.map((item, index) => (
                                            <tr key={index}>
                                                <td>{index + 1}</td>
                                                <td>{item.business_name}</td>
                                                <td>{item.selected_entity}</td>
                                                <td>{item.selected_state}</td>
                                                <td>{item.contact_person_email}</td>
                                                <td>{item.contact_person_phone_number}</td>
                                                <td>
                                                    <button
                                                        type="button"
                                                        className="view-detail-pop"
                                                        onClick={() => handleViewDetails(item)}
                                                    >
                                                        View Details
                                                    </button>
                                                </td>
                                            </tr>
                                        ))
                                    ) : (
                                        <tr>
                                            <td colSpan="3">No Booking Found</td>
                                        </tr>
                                    )}
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
            {showModal && selectedItem && (
                <div className='dashborad-modal'>
                    <div className="modal show d-block" tabIndex="-1" role="dialog">
                        <div className="modal-dialog" role="document">
                            <div className="modal-content">
                                <div className="modal-header">
                                    <h5 className="modal-title">View Details</h5>
                                    <button
                                        type="button"
                                        className="btn-close"
                                        onClick={() => setShowModal(false)}
                                    ></button>
                                </div>
                                <div class="modal-body">
                                    <div className='view-detailin'>
                                        <div className="company-info">Company Information</div>
                                        <hr />
                                        <div className='table-container'>
                                            <div className='detail-table'>
                                                <table>
                                                    <tr>
                                                        <th>
                                                            <p>Company name</p>
                                                        </th>
                                                        <th>{selectedItem.business_name}</th>
                                                    </tr>
                                                </table>
                                                <table>
                                                    <tr>
                                                        <th>
                                                            <p>Package</p>
                                                        </th>
                                                        <th>{selectedItem.package}</th>
                                                    </tr>
                                                </table>
                                                <table>
                                                    <tr>
                                                        <th>
                                                            <p>Entity</p>
                                                        </th>
                                                        <th>{selectedItem.selected_entity}</th>
                                                    </tr>
                                                </table>
                                                <table>
                                                    <tr>
                                                        <th>
                                                            <p>State</p>
                                                        </th>
                                                        <th>{selectedItem.selected_state}</th>
                                                    </tr>
                                                </table>
                                                <table>
                                                    <tr>
                                                        <th>
                                                            <p>State Fee</p>
                                                        </th>
                                                        <th>{selectedItem.stateFee}</th>
                                                    </tr>
                                                </table>
                                                <table>
                                                    <tr>
                                                        <th>
                                                            <p>Total Price</p>
                                                        </th>
                                                        <th>{selectedItem.form_total_price}</th>
                                                    </tr>
                                                </table>
                                                <table>
                                                    <tr>
                                                        <th>
                                                            <p>Documents</p>
                                                        </th>
                                                        <th>{selectedItem.documents ? (
                                                            <a
                                                                href={`${config.pdfUrl}/${selectedItem.documents}.pdf`}
                                                                target="_blank"
                                                                rel="noopener noreferrer"
                                                                style={{
                                                                    display: "inline-block",
                                                                    padding: "8px 16px",
                                                                    backgroundColor: "#007bff",
                                                                    color: "#fff",
                                                                    textDecoration: "none",
                                                                    borderRadius: "4px",
                                                                    textAlign: "center",
                                                                }}
                                                            >
                                                                View Document
                                                            </a>
                                                        ) : (
                                                            "No document available"
                                                        )}</th>
                                                    </tr>
                                                </table>
                                            </div>
                                        </div>
                                    </div>
                                    <div className='view-detailin'>
                                        <div className="company-info">Contact Person</div>
                                        <hr />
                                        <div className='table-container'>
                                            <div className='detail-table'>
                                                <table>
                                                    <tr>
                                                        <th>
                                                            <p>Full Name</p>
                                                        </th>
                                                        <th>
                                                            <p>Email</p>
                                                        </th>
                                                        <th>
                                                            <p>Phone Number</p>
                                                        </th>

                                                    </tr>
                                                    <tr>
                                                        <td>{selectedItem.contact_person_first_name} {selectedItem.contact_person_last_name}</td>
                                                        <td>{selectedItem.contact_person_email}</td>
                                                        <td>{selectedItem.contact_person_phone_number}</td>
                                                    </tr>
                                                </table>
                                            </div>
                                        </div>
                                    </div>
                                    <div className='view-detailin'>
                                        <div className="company-info">Company Address Information
                                        </div>
                                        <hr />
                                        <div className='table-container'>
                                            <div className='detail-table'>
                                                <table>
                                                    <tr>
                                                        <th>
                                                            <p>Company Address</p>
                                                        </th>
                                                        <th>
                                                            <p>Street Address</p>
                                                        </th>
                                                        <th>
                                                            <p>
                                                                Address (Cont)</p>
                                                        </th>
                                                        <th>
                                                            <p>
                                                                City</p>
                                                        </th>
                                                        <th>
                                                            <p>
                                                                State</p>
                                                        </th>
                                                        <th>
                                                            <p>
                                                                Zip Code</p>
                                                        </th>

                                                    </tr>
                                                    <tr>
                                                        <td>{selectedItem.companyAddress}</td>
                                                        <td>{selectedItem.companyAddressStreetAddress}</td>
                                                        <td>{selectedItem.companyAddressCount}</td>
                                                        <td>{selectedItem.companyAddressCity}</td>
                                                        <td>{selectedItem.companyAddressState}</td>
                                                        <td>{selectedItem.companyAddressZipCode}</td>
                                                    </tr>
                                                </table>
                                            </div>
                                        </div>
                                    </div>
                                    <div className='view-detailin'>
                                        <div className="company-info">Registered Agent Information</div>
                                        <hr />
                                        <div className='table-container'>
                                            <div className='detail-table'>
                                                <table>
                                                    <tr>
                                                        {/* <th>
                                                        <p>Assign</p>
                                                    </th> */}
                                                        <th><p>Agent Information Individual/Company</p></th>
                                                        <th><p>Full Name</p></th>
                                                        <th><p>Street Address</p></th>
                                                        <th><p>Address (Cont)</p></th>
                                                        <th><p>City</p></th>
                                                        <th><p>State</p></th>
                                                        <th><p>Zip Code</p></th>
                                                    </tr>
                                                    <tr>
                                                        {/* <td>{selectedItem.agentInformationIndividualCompany}</td> */}
                                                        <td>{selectedItem.agentInformationIndividualCompany}</td>
                                                        <td>{selectedItem.agentInformationFirstName} {selectedItem.agentInformationLastName}</td>
                                                        <td>{selectedItem.agentInformationStreet}</td>
                                                        <td>{selectedItem.agentInformationCont}</td>
                                                        <td>{selectedItem.agentInformationCity}</td>
                                                        <td>{selectedItem.agentInformationState}</td>
                                                        <td>{selectedItem.agentInformationZipcode}</td>
                                                    </tr>
                                                </table>
                                            </div>
                                        </div>
                                    </div>
                                    <div className='view-detailin'>
                                        <div className="company-info">EIN / Tax Identification Number Information</div>
                                        <hr />
                                        <div className='table-container'>
                                            <div className='detail-table'>
                                                <table>
                                                    <tr>
                                                        <th>
                                                            <p>Assign</p>
                                                        </th>
                                                        <th><p>SSN/ITIN</p></th>
                                                        <th><p>Full Name</p></th>
                                                        <th><p>Street Address</p></th>
                                                        <th><p>Address (Cont)</p></th>
                                                        <th><p>City</p></th>
                                                        <th><p>Country</p></th>
                                                        <th><p>Zip Code</p></th>
                                                    </tr>
                                                    <tr>
                                                        <td>{selectedItem.registeredAgentInformation}</td>
                                                        <td>{selectedItem.taxIdentificationSecurityNumber}</td>
                                                        <td>{selectedItem.taxIdentificationFirstName} {selectedItem.taxIdentificationLastName}</td>
                                                        <td>{selectedItem.taxIdentificationStreetAddress}</td>
                                                        <td>{selectedItem.taxIdentificationAddressCont}</td>
                                                        <td>{selectedItem.taxIdentificationCity}</td>
                                                        <td>{selectedItem.taxIdentificationCountry}</td>
                                                        <td>{selectedItem.taxIdentificationZipCode}</td>
                                                    </tr>
                                                </table>
                                            </div>
                                        </div>
                                    </div>
                                    <div className='view-detailin'>
                                        <div className="company-info">Setting up Small Business Banking</div>
                                        <hr />
                                        <div className='table-container'>
                                            <div className='detail-table'>
                                                <table>
                                                    <tr>
                                                        <th>
                                                            <p>Interested/Ask Later</p>
                                                        </th>
                                                        <th>{selectedItem.businessBankingInterestedOrNot}</th>
                                                    </tr>
                                                </table>

                                            </div>
                                        </div>
                                    </div>
                                    <div className='view-detailin'>
                                        <div className="company-info">Tax Strategy / Free Consultation</div>
                                        <hr />
                                        <div className='table-container'>
                                            <div className='detail-table'>
                                                <table>
                                                    <tr>
                                                        <th>
                                                            <p>Yes/No</p>
                                                        </th>
                                                        <th>{selectedItem.taxStrategyYesOrNo}</th>
                                                    </tr>
                                                </table>

                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="modal-footer">
                                    <button
                                        type="button"
                                        className="btn btn-secondary"
                                        onClick={() => setShowModal(false)}
                                    >
                                        Close
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            )}
        </>
    );
};

export default Dashboard;
