import React from 'react';
import { Link } from 'react-router-dom';
import Header from '../Components/Common/Header';
import Footer from '../Components/Common/Footer';
const Affiliates = () => {
    return (
        <>
            <Header />

            <div className='common-banenr'>
                <div className='common-content'>
                    <h1>Affiliates</h1>
                </div>
            </div>





            <div className='abt-section1'>
                <div className='container'>
                    <div className='row'>
                        <div className='col-md-12'>
                            <div className="afflicate-part">
                                <p>At <strong>CorpQuick,</strong> we are proud to collaborate with a network of esteemed partners who enhance
                                    the services we offer and provide comprehensive solutions to our customers. Our affiliates are
                                    leaders in their respective industries, committed to excellence and innovation. Explore our
                                    trusted affiliates below:</p>
                            </div>
                        </div>

                    </div>

                    <div class="afflicate-detail">
                        <div className="row">
                            <div className="col-md-4">
                                <div className="Afflicate-box">
                                    <div classname="mask-box">
                                        <img src="images/msm.jpg" alt="" />
                                        <div className="mask-content">
                                            <h5> <Link to="https://medicalstaffingmanuals.com/" className="start" target="_blank">Medical Staffing Manuals</Link></h5>
                                            <p><strong>Medical Staffing Manuals</strong> offers essential resources and comprehensive guidelines to
                                                streamline medical staffing processes. Their expertise ensures that healthcare facilities are
                                                equipped with qualified professionals, enabling efficient and effective patient care.</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-4">
                                <div className="Afflicate-box">
                                    <div classname="mask-box">
                                        <img src="images/MSMConsultingServices.jpg" alt="" />
                                        <div className="mask-content">
                                            <h5> <Link to="https://www.msmconsultingservices.com/" className="start" target="_blank">MSM Consulting Services</Link></h5>
                                            <p><strong>MSM Consulting Services</strong> specializes in management consulting, providing strategic insights and solutions to help businesses optimize operations, improve performance, and achieve their organizational goals. Their tailored approach ensures that each client receives personalized support.</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-4">
                                <div className="Afflicate-box">
                                    <div classname="mask-box">
                                        <img src="images/empivo.jpg" alt="" />
                                        <div className="mask-content">
                                            <h5> <Link to="https://empivo.com/" className="start" target="_blank">Empivo</Link></h5>
                                            <p><strong>Empivo</strong> delivers innovative HR and employee management solutions, empowering businesses to
                                                efficiently manage their workforce, enhance productivity, and foster a positive workplace
                                                environment. Their comprehensive tools streamline HR processes, making workforce
                                                management seamless.</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="afflicate-detail">
                        <div className="row">
                            <div className="col-md-4">
                                <div className="Afflicate-box">
                                    <div classname="mask-box">
                                        <img src="images/shiftleap.jpg" alt="" />
                                        <div className="mask-content">
                                            <h5> <Link to="https://shiftleap.com/" className="start" target="_blank">ShiftLeap</Link></h5>
                                            <p><strong>ShiftLeap</strong> offers advanced shift scheduling and workforce management tools designed to
                                                optimize staffing, reduce scheduling conflicts, and improve overall operational efficiency for
                                                businesses of all sizes. Their user-friendly platform ensures that scheduling is both easy and
                                                effective.</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-4">
                                <div className="Afflicate-box">
                                    <div classname="mask-box">
                                        <img src="images/factors.jpg" alt="" />
                                        <div className="mask-content">
                                            <h5> <Link to="https://www.factorfinders.com/" className="start" target="_blank">Factor Finders</Link></h5>
                                            <p><strong>Factor Finders</strong> is a leader in financial factoring and business funding solutions. They provide
                                                businesses with the necessary capital to grow, manage cash flow, and seize new opportunities
                                                without taking on additional debt, supporting sustainable business growth.</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-4">
                                <div className="Afflicate-box">
                                    <div classname="mask-box">
                                        <img src="images/digest.jpg" alt="" />
                                        <div className="mask-content">
                                            <h5> <Link to="https://caredigest.com/" className="start" target="_blank">CareDigest</Link></h5>
                                            <p><strong>CareDigest</strong> delivers cutting-edge healthcare management solutions, leveraging technology to
                                                improve patient care, streamline administrative tasks, and enhance the overall efficiency of
                                                healthcare providers. Their solutions are designed to meet the dynamic needs of the healthcare
                                                industry.</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="afflicate-detail">
                        <div className="row">
                            <div className="col-md-4">
                                <div className="Afflicate-box">
                                    <div classname="mask-box">
                                        <img src="images/clientlio.jpg" alt="" />
                                        <div className="mask-content">
                                            <h5> <Link to="https://www.clientilo.com/" className="start" target="_blank">Clientilo</Link></h5>
                                            <p><strong>Clientilo</strong> enhances client relationship management with robust CRM tools, enabling businesses
                                                to build stronger client connections, manage interactions effectively, and drive customer
                                                satisfaction and loyalty. Their intuitive platform supports businesses in nurturing lasting client
                                                relationships.</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-4">
                                <div className="Afflicate-box">
                                    <div classname="mask-box">
                                        <img src="images/skillmatics.jpg" alt="" />
                                        <div className="mask-content">
                                            <h5> <Link to="https://skillzmatics.com/" className="start" target="_blank">Skillzmatics</Link></h5>
                                            <p><strong>Skillzmatics</strong> advances skills development through innovative educational platforms. They offer
                                                a wide range of courses and training programs designed to equip individuals and organizations
                                                with the skills needed to thrive in today’s competitive landscape, fostering continuous learning
                                                and growth.</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-4">
                                <div className="Afflicate-box">
                                    <div classname="mask-box">
                                        <img src="images/medstaff.jpg" alt="" />
                                        <div className="mask-content">
                                            <h5> <Link to="https://medstaffrpo.com/" className="start" target="_blank">MedStaffRPO</Link></h5>
                                            <p><strong>MedStaffRPO</strong> specializes in Recruitment Process Outsourcing (RPO) for the medical industry.
                                                They provide tailored staffing solutions to healthcare organizations, ensuring the efficient and
                                                effective attraction and retention of top-tier medical professionals to meet the unique needs of the
                                                healthcare sector.</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

            </div>





            <div className="corp join-afflicated">
                <div className="container">
                    <div className="heading-part">
                        <h2>Join Our Affiliate Network</h2>
                        <p><strong>Interested in becoming an affiliate?</strong></p>
                        <p>Join our growing network of partners and help us deliver exceptional services to entrepreneurs
                            and businesses worldwide. As an affiliate, you&#39;ll benefit from:</p>
                    </div>
                    <div className="whatdo">
                        <div className="row">
                            <div className="col-md-6">
                                <ul>
                                    <li><strong><i class="fa fa-check" aria-hidden="true"></i>
                                        Competitive Commissions:</strong>Earn attractive commissions for every referral that converts
                                        into a customer.</li>
                                    <li><strong><i class="fa fa-check" aria-hidden="true"></i>
                                        Marketing Support:</strong>Access to a range of marketing materials and dedicated support to
                                        help you succeed.</li>
                                    <li><strong><i class="fa fa-check" aria-hidden="true"></i>
                                        Exclusive Offers:</strong>Provide your audience with exclusive deals and promotions from
                                        CorpQuick.</li>
                                    <li><strong><i class="fa fa-check" aria-hidden="true"></i>
                                        Flexible Partnership:</strong>Tailor your affiliate partnership to align with your audience and
                                        business goals.</li>
                                    <p><Link to="/contact-us" >Contact Us </Link>to learn more about partnership opportunities and how you can become a valued
                                        member of our affiliate network.</p>
                                </ul>
                            </div>
                            <div className="col-md-6">
                                <img src="images/afflcated.jpg" alt="" />
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className='abt-section1 abt-value'>
                <div className='container'>
                    <div className='row'>
                        <div className='col-md-6'>
                            <img src="images/solution.jpg" alt="" />
                        </div>
                        <div className='col-md-6'>
                            <h2>Why Partner with CorpQuick?</h2>
                            <ul>
                                <li><strong><i class="fa fa-check" aria-hidden="true"></i> Trusted Brand:</strong> CorpQuick is a recognized leader in business formation and support
                                    services, trusted by entrepreneurs worldwide.</li>
                                <li>
                                    <strong><i class="fa fa-check" aria-hidden="true"></i>Comprehensive Solutions:</strong> Our wide range of services ensures that your referrals receive
                                    the best support to launch and grow their businesses.</li>
                                <li><strong><i class="fa fa-check" aria-hidden="true"></i>Dedicated Support:</strong> Our affiliate managers are here to assist you every step of the way,
                                    ensuring a successful partnership.</li>


                            </ul>

                        </div>

                    </div>
                </div>

            </div>
            <div className='abt-section1 testimonal'>
                <div className='container'>
                    <div className='row'>
                        <div className='col-md-12'>
                            <h2>Testimonials</h2>
                        </div>

                        <div className="testmoinial-box">
                            <div className="row">
                                <div className="col-md-6">
                                    <div className="box-reviews">
                                        <p>"Partnering with CorpQuick has been a game-changer for our business. Their
                                            support and resources have helped us provide unparalleled services to our
                                            clients."</p>
                                        <p><strong>David Morgan,</strong>CEO of Clientilo.com</p>
                                    </div>
                                </div>
                                <div className="col-md-6">
                                    <div className="box-reviews">
                                        <p>"CorpQuick's affiliate program is exceptional. The commissions are competitive,
                                            and their team is always ready to assist us."</p>
                                        <p><strong>Janet Brown,</strong>Program Director at MSM Consulting</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

            </div>

            <div className='abt-section1 stay-cnnected'>
                <div className='container'>
                    <div className='row'>
                        <div className='col-md-12'>
                            <h2>Stay Connected</h2>
                            <p>Follow us on <Link to="#">LinkedIn</Link>, <Link to="#">Twitter</Link>, and <Link to="#">Facebook</Link> to stay updated with the latest insights,
                                opportunities, and news from CorpQuick and our affiliates.</p>
                        </div>
                    </div>
                </div>

            </div>
            <Footer />
        </>

    )
}

export default Affiliates
