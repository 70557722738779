import React,{useState,useEffect} from 'react';
import Header from '../../Common/Header';
import Footer from '../../Common/Footer';
import { Link, useNavigate } from 'react-router-dom';
import config from '../../../config';

const ProcessLicense = () => {
    const [formOrderData, setFormOrderData] = useState({
        companyInformationCountry: '',
        companyInformationStreetAddress: '',
        companyInformationAddressCont: '',
        companyInformationCity: '',
        companyInformationState: '',
        companyInformationZipCode: ''
    });
    const [apiData, setApiData] = useState(null);
    const resultData = localStorage.getItem('formOrderResult');
    const [isSubmitting, setIsSubmitting] = useState(false);
    let extractedId = null;
    const navigate = useNavigate()

    if (resultData) {
        const parsedResult = JSON.parse(resultData);
        extractedId = parsedResult.data.id;
        console.log('Extracted ID:', extractedId);
    }
    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await fetch(`${config.baseUrl}/registration/get-form-1/${extractedId}`);
                if (response.ok) {
                    const data = await response.json();
                    setApiData(data.data); 
                } else {
                    console.error('Error fetching data:', response.statusText);
                }
            } catch (error) {
                console.error('Error:', error);
            }
        };

        fetchData();
    }, [extractedId]);
    const handleSubmit = async (e) => {
        e.preventDefault();
        setIsSubmitting(true);

        const payload = {
            companyAddressStreetAddress: formOrderData.companyInformationStreetAddress,
        };

        try {
            const response = await fetch(`${config.baseUrl}/registration/form-11/${extractedId}`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(payload)
            });

            if (response.ok) {
                const data = await response.json();
                navigate("/process-order-review");
                console.log('Form submission successful:', data);
            } else {
                console.error('Error in form submission:', response.statusText);
            }
        } catch (error) {
            console.error('Error:', error);
        } finally {
            setIsSubmitting(false);
        }
    };
    
    return (
        <>
            <Header />
            <section className="registration-from">
                <div className="container">
                    <div className="registerformstart">
                        <div className="row">
                            <div className="col-md-8">
                                <div className="register-left">
                                    <div className="box__heading1 tax-strategy">Business Licenses and Permits </div>
                                    <div className="divider mb25" />
                                    <div className="common-border-radius-blk mb25">
                                        <div className="p-2 d-flex">
                                            <div className="imageBg">
                                                <img src="images/business-licenses.png" alt="" />
                                            </div>
                                            <div className=" gap-3 py-2 pr-6 licenses">
                                                <div className="font20 fontWeight700 mb25">
                                                    We've identified
                                                    <span className="">2 licenses</span> for your business in
                                                    {apiData ? apiData.selected_state : ''}
                                                </div>
                                                <div className="">
                                                    As a business owner, you are responsible for making sure
                                                    your business has the proper federal, state and local
                                                    licenses and permits to operate legally. To make this
                                                    easier:
                                                </div>
                                            </div>
                                        </div>
                                        <div className="business-ownwes-blk px-4 py-4">
                                            <ul className="list-unstyled">
                                                <li>
                                                    <i className="fa fa-check" aria-hidden="true" /> Team of
                                                    licensing specialists will conduct the research for you
                                                </li>
                                                <li>
                                                    <i className="fa fa-check" aria-hidden="true" /> We’ll
                                                    determine all the licenses and permits you need to stay
                                                    compliant
                                                </li>
                                                <li>
                                                    <i className="fa fa-check" aria-hidden="true" /> Receive the
                                                    applications along with a guide to the next steps via mail
                                                </li>
                                            </ul>
                                            <div
                                                className="d-flex align-items-center flex-column
                              justify-content-center"
                                            >
                                                <div className="d-flex mb25">
                                                    <div className="">
                                                        <img className="" src="images/people-1.png" alt="" />
                                                    </div>
                                                    <div className="">
                                                        <img className="" src="images/people-2.png" alt="" />
                                                    </div>
                                                    <div className="">
                                                        <img className="" src="images/people-3.png" alt="" />
                                                    </div>
                                                </div>
                                               
                                            </div>
                                        </div>
                                    </div>
                                    <div className="join-business">
                                                    Join the 18,000+ business owners <br />
                                                    <span className="">
                                                        who have gained peace of mind with this package.
                                                    </span>
                                                </div>
                                    <div className='custumnew'>
                                    <div className="custom_radio mb25 foreign-individual">
                                        <div>
                                            <input
                                                type="radio"
                                                id="noThanks"
                                                name="license"
                                                defaultValue={0}
                                                onclick="handleLicenseChecked(this)"
                                            />
                                            <label htmlFor="noThanks">
                                                <span className="">No thanks, I'll do the work myself</span>
                                            </label>
                                        </div>
                                        <div>
                                            <input
                                                type="radio"
                                                id="yesPlease"
                                                name="license"
                                                defaultValue={1}
                                                onclick="handleLicenseChecked(this)"
                                            />
                                            <label htmlFor="yesPlease">
                                                <span className="">
                                                    Yes, please mail the applications ($99){" "}
                                                </span>
                                            </label>
                                        </div>
                                    </div>
                                    </div>
                                    <div id="customCompanyAddess">
                                        <div className="mb-4">
                                            <strong className="">
                                                Please provide the address below where you would like us to
                                                research required licenses and permits:
                                            </strong>
                                        </div>
                                        <div className="divider mb25" />
                                        <form id="">
                                            <div className="twoBox">
                                                <div className="form-group">
                                                    <label htmlFor="streetAddress">Street Address</label>
                                                    <input
                                                        type="text"
                                                        className="form-control"
                                                        id="streetAddress"
                                                        name="streetAddress"
                                                        required=""
                                                    />
                                                </div>
                                                <div className="form-group">
                                                    <label htmlFor="addressContOptional">
                                                        {" "}
                                                        Address (Cont) (Optional)
                                                    </label>
                                                    <input
                                                        type="text"
                                                        className="form-control"
                                                        id="addressContOptional"
                                                        name="addressContOptional"
                                                        required=""
                                                    />
                                                </div>
                                            </div>
                                            <div className="threeBox">
                                                <div className="form-group">
                                                    <label htmlFor="city">City:</label>
                                                    <input
                                                        type="text"
                                                        className="form-control"
                                                        id="city"
                                                        name="city"
                                                        required=""
                                                    />
                                                </div>
                                                <div className="form-group">
                                                    <label htmlFor="state">State:</label>
                                                    <input
                                                        type="text"
                                                        className="form-control"
                                                        id="state"
                                                        name="state"
                                                        required=""
                                                    />
                                                </div>
                                                <div className="form-group">
                                                    <label htmlFor="zipCode">Zip Code:</label>
                                                    <input
                                                        type="number"
                                                        className="form-control"
                                                        id="zipCode"
                                                        name="zipCode"
                                                        required=""
                                                    />
                                                </div>
                                            </div>
                                            <div className="form-group">
                                                <label htmlFor="businessPurpose">
                                                    {" "}
                                                    Business Purpose (0/ 100){" "}
                                                </label>
                                                <textarea
                                                    className="form-control"
                                                    id="businessPurpose"
                                                    placeholder="Please provide brief description of Business Purpose"
                                                    maxLength={100}
                                                    name="city"
                                                    required=""
                                                    defaultValue={""}
                                                />
                                                <span className="" style={{}}>
                                                    Business Purpose is required
                                                </span>
                                            </div>
                                        </form>
                                    </div>
                                    <div className="button-nest-prev">
                                        <div className="left-next">
                                    
                                            <Link to="/tax-invite">
                                            <button type="button">
                                                <i className="fa fa-arrow-left" aria-hidden="true" />
                                                Back
                                                </button>
                                            </Link>
                                         
                                        </div>
                                        <div className="right-next nex2">
                                            <button
                                                data-bs-toggle="modal"
                                                data-bs-target="#modalNextButton"
                                            >
                                                Next
                                            </button>
                                        </div>
                                    </div>
                                    <div
                                        id="additionalExplanation"
                                        className="common-border-radius-blk p-3 mt-4"
                                    >
                                        <h5>Additional Explanation</h5>
                                        <div className="accordion mb25">
                                            <div className="accordion-item">
                                                <h2 className="accordion-header">
                                                    <button
                                                        className="accordion-button collapsed"
                                                        type="button"
                                                        data-bs-toggle="collapse"
                                                        data-bs-target="#panelsStayOpen-additionalExplanation"
                                                        aria-expanded="false"
                                                        aria-controls="panelsStayOpen-additionalExplanation"
                                                    >
                                                        Is a Business Licenses and Permits something I should
                                                        consider?
                                                    </button>
                                                </h2>
                                                <div
                                                    id="panelsStayOpen-additionalExplanation"
                                                    className="accordion-collapse collapse"
                                                >
                                                    <div className="accordion-body">
                                                        In order to issue an EIN, the IRS requires a Principal
                                                        (typically one of the members or directors of an entity)
                                                        to provide their Social Security Number. This creates a
                                                        formal affiliation with the company/entity. The Social
                                                        Security Number is strictly used for obtaining the EIN.
                                                        Once the EIN process is complete, your SSN is permanently
                                                        deleted from the Corpquick database. To further protect your
                                                        security, all Social Security Numbers are stored on a
                                                        secure, encrypted server during the EIN process.
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <Footer />
        </>
    )
}

export default ProcessLicense
