
import React, { useState, useEffect } from 'react';
import Header from '../../Common/Header';
import Footer from '../../Common/Footer';
import { Link } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';
import config from '../../../config';
import { loadStripe } from '@stripe/stripe-js'; // Importing loadStripe
import { bottom } from '@popperjs/core';

const Payment = () => {
    const [formOrderData, setFormOrderData] = useState(null);
    const [formTotalPrice, setFormTotalPrice] = useState(""); // Payment amount input
    const [loading, setLoading] = useState(false); // Loading state
    const [message, setMessage] = useState(""); // Success/Failure message
    const [openSection, setOpenSection] = useState(null);
    const resultData = localStorage.getItem('formOrderResult');
    const [additionalServices, setAdditionalServices] = useState([]);
    const [apiData, setApiData] = useState(null);
    const toggleSection = (section) => {
        setOpenSection(openSection === section ? null : section);
    };
    let extractedId = null;
    const navigate = useNavigate();

    if (resultData) {
        const parsedResult = JSON.parse(resultData);
        extractedId = parsedResult.data.id;
        console.log('Extracted ID:', extractedId);
    }

    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await fetch(`${config.baseUrl}/registration/get-form-1/${extractedId}`);
                if (response.ok) {
                    const data = await response.json();
                    setApiData(data.data);
                    setFormTotalPrice(data.data?.form_total_price || "");
                    if (data.data.additional_services) {
                        const parsedServices = JSON.parse(data.data.additional_services);
                        setAdditionalServices(parsedServices);
                    }
                } else {
                    console.error('Error fetching data:', response.statusText);
                }
            } catch (error) {
                console.error('Error:', error);
            }
        };

        if (extractedId) {
            fetchData();
        }
    }, [extractedId]);
    console.log(apiData?.form_total_price || "No price available");

    // Payment integration
    const makePayment = async () => {
        // Calculate the total price dynamically based on apiData
        const calculatedTotalPrice = apiData
            ? parseFloat(apiData.form_total_price || 0) +
            (apiData.companyAddress === "Professional Business Address" ? 29 : 0)
            : formTotalPrice;

        // Validate the dynamically calculated total price
        if (!calculatedTotalPrice || isNaN(calculatedTotalPrice) || Number(calculatedTotalPrice) <= 0) {
            setMessage("Please enter a valid payment amount.");
            return;
        }

        setLoading(true);
        setMessage("");

        try {
            const stripe = await loadStripe(
                "pk_test_51Qn6LNFdcDV9ZEf2uTOeQ0yeiXCgAFx8h3VWY0o6BHptB9hp7PsgWS4WHHZWZaElH4StiYyqOmvK3aRcF9sbEO2Q00l0BmQS8p"
            );

            const body = {
                formTotalPrice: calculatedTotalPrice,
                id: extractedId, // Pass the dynamically extracted ID here
            };

            const headers = {
                "Content-Type": "application/json",
            };

            const response = await fetch(`${config.baseUrl}/create-checkout-session`, {
                method: "POST",
                headers: headers,
                body: JSON.stringify(body),
            });

            if (response.ok) {
                const session = await response.json();

                const result = await stripe.redirectToCheckout({
                    sessionId: session.id,
                });

                if (result.error) {
                    setMessage(result.error.message);
                }
            } else {
                const errorData = await response.json();
                setMessage(errorData.message || "Payment failed.");
            }
        } catch (error) {
            console.error("Payment error:", error);
            setMessage("An error occurred during the payment process.");
        } finally {
            setLoading(false);
        }
    };


    return (
        <>
            <Header />
            <section className="registration-from">
                <div className="container">
                    <div className="registerformstart">
                        <div className="row">
                            <div className="col-md-8">
                                <div className="register-left">
                                    <div className="box__heading1">Billing Information </div>

                                    <div className="divider mb25" />

                                    <div className="mb20" style={{ marginBottom: 5 }} />

                                    <div className='payment-btn'>

                                        <div className="custom_radio mb25 foreign-individual payment-radio">

                                            <div style={{ position: "relative", display: "inline-block", width: "100%" }}>
                                                <input
                                                    id="amount"
                                                    type="text"
                                                    placeholder="Enter payment amount"
                                                    value={
                                                        apiData
                                                            ? `$${(
                                                                parseFloat(apiData.form_total_price || 0) +
                                                                (apiData.companyAddress === "Professional Business Address" ? 29 : 0)
                                                            ).toFixed(2)}`
                                                            : `$${parseFloat(formTotalPrice || 0).toFixed(2)}`
                                                    }
                                                    onChange={(e) => setFormTotalPrice(e.target.value.replace(/[^0-9.]/g, ""))}
                                                    style={{
                                                        display: "block",
                                                        padding: "10px",
                                                        fontSize: "16px",
                                                        width: "100%",
                                                        paddingRight: "50px", // Adjust padding to make space for image
                                                    }}
                                                    readOnly
                                                />
                                                <img
                                                    src="images/Stripe_Logo.png"
                                                    alt="stripe"
                                                    height={20}
                                                    width="50"
                                                    style={{
                                                        position: "absolute",
                                                        right: "52px",
                                                        top: "50%",
                                                        transform: "translateY(-50%)",
                                                    }}
                                                />
                                            </div>


                                            <button className='payment-btn'
                                                onClick={makePayment}
                                                disabled={loading}
                                                style={{
                                                    fontSize: "16px",
                                                    backgroundColor: "#4CAF50",
                                                    color: "white",
                                                    border: "none",
                                                    cursor: "pointer",
                                                }}
                                            >
                                                {loading ? "Processing..." : "Pay Now"}
                                            </button>
                                            {message && (
                                                <div style={{ marginTop: "20px", color: "red", fontWeight: "bold" }} className='payment-error'>
                                                    {message}
                                                </div>
                                            )}
                                        </div>
                                    </div>
                                    <div className="accordion common-border-radius-blk mb25 mt-5" id="">
                                        <h5 className="additional-info">Additional Explanation</h5>
                                        <div className="accordion-item">
                                            <div className="accordion-header">
                                                <button
                                                    className={`accordion-button ${openSection === 'sectionOne' ? '' : 'collapsed'}`}
                                                    type="button"
                                                    onClick={() => toggleSection('sectionOne')}
                                                >
                                                    What is the policy for canceling my order?
                                                </button>
                                            </div>
                                            <div
                                                id="panelsStayOpen-collapseOne"
                                                className={`accordion-collapse collapse ${openSection === 'sectionOne' ? 'show' : ''}`}
                                            >
                                                <div className="accordion-body"></div>
                                            </div>
                                            <div
                                                id="panelsStayOpen-collapseOne"
                                                className={`accordion-collapse collapse ${openSection === 'sectionOne' ? 'show' : ''}`}
                                            >
                                                <div className="accordion-body">
                                                    <p>At Corpquick, we aim to process all orders within 24 hours. To ensure a smooth cancellation process, we recommend canceling your order no later than midnight on the day it was placed.</p>

                                                    <p>Once payment has been forwarded to a government entity or third party, Corpquick <strong>cannot</strong> accommodate cancellations or modifications to your order.</p>

                                                    <p>If you need to cancel, please place your order on hold through your Corpquick Dashboard.</p>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="accordion-item">
                                            <div className="accordion-header">
                                                <button
                                                    className={`accordion-button ${openSection === 'sectionTwo' ? '' : 'collapsed'}`}
                                                    type="button"
                                                    onClick={() => toggleSection('sectionTwo')}
                                                >
                                                    Can I edit my order after it's been placed?
                                                </button>
                                            </div>
                                            <div
                                                id="panelsStayOpen-collapseTwo"
                                                className={`accordion-collapse collapse ${openSection === 'sectionTwo' ? 'show' : ''}`}
                                            >
                                                <div className="accordion-body">
                                                    <p>You can make changes to your order until your payment is forwarded to a government entity or third party, which typically occurs within 24 hours.</p>

                                                    <p>To update your order, visit the "Order Review" section in your Dashboard. A direct link is also included in your order confirmation email.</p>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="accordion-item">
                                            <div className="accordion-header">
                                                <button
                                                    className={`accordion-button ${openSection === 'sectionThree' ? '' : 'collapsed'}`}
                                                    type="button"
                                                    onClick={() => toggleSection('sectionThree')}
                                                >
                                                    What happens after I pay?
                                                </button>
                                            </div>
                                            <div
                                                id="panelsStayOpen-collapseThree"
                                                className={`accordion-collapse collapse ${openSection === 'sectionThree' ? 'show' : ''}`}
                                            >
                                                <div className="accordion-body">
                                                    <p><strong>We Prepare and File Your Documents:</strong> We gather your information, draft your Articles of Organization or Incorporation, and submit them to the appropriate governing agency in your state.</p>

                                                    <p><strong>We Verify Your Business Name Availability:</strong> Your business name is a vital part of your identity. We ensure that the name you select is available and ready for use in your state.</p>

                                                    <p>Once we've confirmed your business name and compiled all necessary information, we create your Articles of Organization or Incorporation and prepare any additional documents required for the formation process. After a final review to ensure everything is accurate (usually within 24 hours), we submit your documents and payment to the governing agency in your state.</p>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="accordion-item">
                                            <div className="accordion-header">
                                                <button
                                                    className={`accordion-button ${openSection === 'sectionFour' ? '' : 'collapsed'}`}
                                                    type="button"
                                                    onClick={() => toggleSection('sectionFour')}
                                                >
                                                    How can I track my order?
                                                </button>
                                            </div>
                                            <div
                                                id="panelsStayOpen-collapseForth"
                                                className={`accordion-collapse collapse ${openSection === 'sectionFour' ? 'show' : ''}`}
                                            >
                                                <div className="accordion-body">
                                                    <p>You can track the status of your order through your Corpquick Dashboard. A tracking link will also be included in your order confirmation email.</p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="button-nest-prev">
                                        <div className="left-next">
                                            <Link to="/order-review">
                                                <button type="button">
                                                    <i className="fa fa-arrow-left" aria-hidden="true" />
                                                    Back
                                                </button>
                                            </Link>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-4">
                                <div className="register-right">
                                    <h4>Order Summary</h4>
                                    <h2>{apiData ? apiData.full_business_name : ''}</h2>
                                    <div className="progress">
                                        <div
                                            className="progress-bar"
                                            role="progressbar"
                                            style={{ width: "95%" }}
                                            aria-valuenow={95}
                                            aria-valuemin={0}
                                            aria-valuemax={100}
                                        >
                                            95%
                                        </div>
                                    </div>
                                    <div className="order-items flex flex-col gap-3 mt-8">
                                        <div className="item">
                                            <div className="name">{apiData ? apiData.package : ''} Package</div>
                                            <div className="value ">${apiData ? apiData.package_fee : ''}</div>
                                        </div>
                                        {additionalServices.map((service, index) => (
                                            <div className="item" key={index}>
                                                <div className="name">{service.name}</div>
                                                <div className="value">${service.price}</div>
                                            </div>
                                        ))}
                                        <div className="item">
                                            <div className="name">{apiData ? apiData.selected_state : ''} State Filing Fee</div>
                                            <div className="value">
                                                <span id="dynamic-filing-fee">${apiData ? apiData.stateFee : ''}</span>
                                            </div>
                                        </div>
                                        {apiData?.companyAddress === "Professional Business Address" && (
                                            <div className="item" id="order-item-legal-consulation">
                                                <div className="name">Business Address</div>
                                                <div className="value">
                                                    $<span id="dynamic-filing-fee">29</span>
                                                </div>
                                            </div>
                                        )}
                                        <div className="item hide" id="order-item-legal-consulation">
                                            <div className="name">Banking Resolution</div>
                                            <div className="value">
                                                <i className="fa fa-check" aria-hidden="true" />
                                            </div>
                                        </div>
                                        <div className="item hide" id="order-item-expedited">
                                            <div className="name">Banking Resolution</div>
                                            <div className="value">
                                                <i className="fa fa-check" aria-hidden="true" />
                                            </div>
                                        </div>
                                        <div className="item hide" id="order-item-legal-consulation">
                                            <div className="name">Minutes Meeting</div>
                                            <div className="value">
                                                <i className="fa fa-check" aria-hidden="true" />
                                            </div>
                                        </div>
                                        <div className="item hide" id="order-item-license-package">
                                            <div className="name">Bylaws</div>
                                            <div className="value">
                                                <i className="fa fa-check" aria-hidden="true" />
                                            </div>
                                        </div>
                                        <div className="item hide" id="order-item-va">
                                            <div className="name">EIN / Tax ID #</div>
                                            <div className="value">
                                                <i className="fa fa-check" aria-hidden="true" />
                                            </div>
                                        </div>
                                        <div className="item">
                                            <div className="name">Electronic Delivery</div>
                                            <div className="value">
                                                <i className="fa fa-check" aria-hidden="true" />
                                            </div>
                                        </div>
                                        <div className="item">
                                            <div className="name">Form 2553</div>
                                            <div className="value">
                                                <i className="fa fa-check" aria-hidden="true" />
                                            </div>
                                        </div>

                                        <div className="item">
                                            <div className="name flex flex-col">
                                                <span>Phone Support</span>
                                                <span className="text-xs text-gray-700 mt-[6px]">
                                                    Available Monday - Friday from 9 a.m. to 6 p.m. CST
                                                </span>
                                            </div>
                                            <div className="value">
                                                <i className="fa fa-check" aria-hidden="true" />
                                            </div>
                                        </div>
                                        <hr className="!mt-1 !mb-1" />
                                        <div className="totla-value1">
                                            <div className="item items-center mb-8">
                                                <div className="name">Total</div>
                                                <div className="value bold text-black">
                                                    <span className="SumPackageTotal text-2xl">
                                                        $<span className="totalPrice">
                                                            {apiData
                                                                ? parseFloat(apiData.form_total_price || 0) +
                                                                (apiData.companyAddress === "Professional Business Address" ? 29 : 0)
                                                                : ''}
                                                        </span>
                                                    </span>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="trustpilot">
                                            <img src="images/Trustpilot-Reviews-Services-1.png" alt="" />
                                            <p>TrustScore 4.7 | 19,076 reviews</p>
                                        </div>

                                        <div className="join-business">
                                            <div className="font-radwave text-black">
                                                <h2 className="text-base tracking-wide text-left  my-0">
                                                    JOIN THE
                                                </h2>
                                                <p className="text-base text-red-500 tracking-wide ">
                                                    1,000,000+
                                                </p>
                                                <h3 className="text-base tracking-wide uppercase my-0">
                                                    Businesses
                                                </h3>
                                                <h6>
                                                    That have trusted Corpquick to start, manage, and grow their
                                                    business{" "}
                                                </h6>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>
            </section>
            <Footer />
        </>
    )

}

export default Payment
