import React from 'react';
import { Link } from 'react-router-dom';
import Header from '../Components/Common/Header';
import Footer from '../Components/Common/Footer';
const About = () => {
  return (
    <>
      <Header />

      <div className='common-banenr'>
        <div className='common-content'>
          <h1>About <span>Us</span></h1>
        </div>
      </div>





      <div className='abt-section1'>
        <div className='container'>
          <div className='row'>
            <div className='col-md-6'>
              <h2>Empowering Entrepreneurs Through Innovation and Expertise</h2>
              <p><strong>CorpQuick</strong> was born out of a shared frustration among seasoned business owners who
                recognized a critical gap in the entrepreneurial landscape. As founders ourselves, we understand
                the challenges of launching and growing a business—from navigating complex legal
                requirements to establishing a strong market presence. We envisioned a platform that not only
                simplifies these processes but also provides the expert guidance necessary for sustained success.
                <strong>CorpQuick is your one-stop destination for all your business needs,</strong> offering comprehensive
                solutions that streamline every aspect of your entrepreneurial journey.</p>

            </div>
            <div className='col-md-6'>
              <img src="images/abt.jpg" alt="" />
            </div>
          </div>
        </div>

      </div>
      <div className='get-touch-ab'>
        <div className='container'>
          <div className='row'>
            <div className='col-md-12'>
              <h2>Our Mission</h2>
              <p>At CorpQuick, our mission is to <strong>empower entrepreneurs</strong> by providing a seamless, all-in-one
                platform that facilitates the quick and expert establishment and expansion of businesses. We
                strive to remove the barriers to entry, enabling visionaries to focus on what they do
                best—innovating and driving their businesses forward. By consolidating essential services into a
                single, user-friendly platform, we ensure that every entrepreneur has the resources and support
                needed to thrive in today’s competitive market.</p>

            </div>

          </div>
        </div>
      </div>




      <div className="corp">
        <div className="container">
          <div className="heading-part">
            <h2>What We Do</h2>
            <p>
              CorpQuick offers a suite of integrated services designed to streamline every aspect of
              business formation and growth, making us your ultimate one-stop solution:
            </p>
          </div>
          <div className="whatdo">
            <div className="row">
              <div className="col-md-6">
                <ul>
                  <li><strong><i class="fa fa-check" aria-hidden="true"></i>
                    Business Formation:</strong>Simplify the registration process and gain immediate access to
                    essential benefits.</li>
                  <li><strong><i class="fa fa-check" aria-hidden="true"></i>
                    EIN and Compliance:</strong>Secure your Employer Identification Number and ensure your
                    business meets all regulatory requirements.</li>
                  <li><strong><i class="fa fa-check" aria-hidden="true"></i>
                    Licenses and Permits:</strong>Identify and obtain the necessary licenses and permits with
                    minimal hassle.</li>
                  <li><strong><i class="fa fa-check" aria-hidden="true"></i>
                    Financial Services:</strong>Optimize your taxes and bookkeeping with expert assistance.</li>
                  <li><strong><i class="fa fa-check" aria-hidden="true"></i>
                    Insurance and Protection:</strong>Safeguard your business with tailored insurance solutions
                    and trademark registration.</li>
                  <li><strong><i class="fa fa-check" aria-hidden="true"></i>
                    Digital Presence:</strong>Build a professional online identity with our website development,
                    domain registration, logo design, and digital marketing services.</li>
                  <li><strong><i class="fa fa-check" aria-hidden="true"></i>
                    Expert Support:</strong>Access personalized guidance from industry professionals dedicated to
                    your success.</li>
                </ul>
              </div>
              <div className="col-md-6">
                <img src="images/whatdo.jpg" alt="" />
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className='abt-section1 abt-value'>
        <div className='container'>
          <div className='row'>
            <div className='col-md-6'>
              <img src="images/excellence.jpg" alt="" />
            </div>
            <div className='col-md-6'>
              <h2>Our Values</h2>
              <ul>
                <li><strong><i class="fa fa-check" aria-hidden="true"></i> Integrity:</strong> We uphold the highest standards of honesty and transparency in all our
                  interactions.</li>
                <li>
                  <strong><i class="fa fa-check" aria-hidden="true"></i> Excellence:</strong> We are committed to delivering top-tier services and continuously improving
                  our platform</li>
                <li><strong><i class="fa fa-check" aria-hidden="true"></i> Innovation:</strong> We embrace cutting-edge technology and innovative solutions to meet the
                  evolving needs of entrepreneurs.</li>
                <li><strong><i class="fa fa-check" aria-hidden="true"></i>  Customer-Centricity:</strong> Our clients are at the heart of everything we do. We prioritize
                  their success and satisfaction above all else.</li>
                <li><strong><i class="fa fa-check" aria-hidden="true"></i> Collaboration:</strong> We foster a collaborative environment where knowledge sharing and
                  mutual support drive collective growth.</li>

              </ul>

            </div>

          </div>
        </div>

      </div>
      <div className='abt-section1 meet-founder'>
        <div className='container'>
          <div className='row'>
            <div className='col-md-6'>
              <h2>Meet the Founder</h2>
              <p><strong>Saad Khan </strong>With <strong>20 years of business start-up and consulting experience,</strong>Saad Khan brings
                a wealth of knowledge in business strategy and operations. His extensive background includes
                successfully launching multiple startups and providing strategic consulting to a diverse range of
                industries. Saad&#39;s passion for entrepreneurship and dedication to helping others succeed are the
                driving forces behind CorpQuick. His vision is to create a platform that not only addresses the
                immediate needs of business owners but also supports their long-term growth and sustainability.
                Under Saad&#39;s leadership, CorpQuick remains at the forefront of business solutions, continually
                evolving to meet the dynamic demands of the entrepreneurial landscape.</p>

            </div>
            <div className='col-md-6'>
              <img src="images/meetfounder.jpg" alt="" />
            </div>
          </div>
        </div>

      </div>
      <div className='get-touch-ab ourvision'>
        <div className='container'>
          <div className='row'>
            <div className='col-md-12'>
              <h2>Our Vision</h2>
              <p>We envision a world where every aspiring entrepreneur has the tools, support, and confidence to
                turn their ideas into thriving businesses. By continually enhancing our platform and expanding
                our services, we aim to be the trusted partner for businesses at every stage of their journey. <strong>As a
                  one-stop place for all your business needs,</strong> CorpQuick is dedicated to simplifying the
                complexities of entrepreneurship, enabling you to focus on building and scaling your business
                with ease.</p>

            </div>

          </div>
        </div>
      </div>
      <div className='abt-section1 Entrepreneurial '>
        <div className='container'>
          <div className='row'>
            <div className='col-md-6'>
              <h2>Join Us on Your Entrepreneurial Journey</h2>
              <p>At CorpQuick, we are more than just a service provider—we are your dedicated partners in
                success. Whether you’re just starting out or looking to scale your business, our expert advice and
                comprehensive support are here to help you achieve your goals. <strong>With CorpQuick as your one-
                  stop solution, let’s build the future of your business together.</strong></p>
              <h5><strong>Contact Us:</strong></h5>
              <ul>
                <li><i class="fa fa-phone" aria-hidden="true"></i> <Link to="tel:8049161974"> (804) 916-1974</Link></li>
                <li><i class="fa fa-envelope-open" aria-hidden="true"></i><Link to="mailto:support@corpquick.com">support@corpquick.com</Link>
                </li>
                <li><i class="fa fa-map-marker" aria-hidden="true"></i>525 Rte 73 N, Suite 104, Marlton, NJ 08053</li>
              </ul>
              <p>Follow us on <Link to="#">LinkedIn</Link>, <Link to="#">Twitter</Link>, and <Link to="#">Facebook</Link> to stay updated with the latest insights and
                opportunities for your business.</p>
            </div>
            <div className='col-md-6'>
              <img src="images/EntrepreneurialJourney.jpg" alt="" />
            </div>
          </div>
        </div>

      </div>
      <Footer />
    </>

  )
}

export default About
