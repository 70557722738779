import React from 'react';
import Header from '../../Components/Common/Header';
import Footer from '../../Components/Common/Footer';
import Banner from '../../Components/Homepage/Banner';
import Testimonial from '../../Components/Homepage/Testimonial';
import Straightforward from '../../Components/Homepage/Straightforward';
import WhatsCorpquick from '../../Components/Homepage/WhatsCorpquick';
import StartMyBusiness from '../../Components/Homepage/StartMyBusiness';
import Package from '../../Components/Homepage/Package'
import './Homepage.css';

const Homepage = () => {
  return (
    <>
      <Header/>
      <Banner/>
      <Package/>
      <StartMyBusiness/>
      <WhatsCorpquick/>
      <Straightforward/>
      <Testimonial/>
      <Footer/>
    </>
  )
}

export default Homepage
