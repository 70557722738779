import React, { useEffect, useState } from 'react';
import config from '../../config';
import { Link, useNavigate } from 'react-router-dom';

const Navbar = () => {
  const [email, setEmail] = useState('');
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const navigate = useNavigate();
  const token = localStorage.getItem('token');

  // Fetch email from the API
  useEffect(() => {
    const fetchEmail = async () => {
      if (!token) return; // Exit if there's no token
      console.log("token",token)

      try {
        const response = await fetch(`${config.baseUrl}/welcome`, {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}`,
          },
        });

        if (response.ok) {
          const data = await response.json();
          const userEmail = data.data.email;
          setEmail(maskEmail(userEmail));
        } else {
          console.error('Failed to fetch email:', response.statusText);
        }
      } catch (error) {
        console.error('Error fetching email:', error);
      }
    };

    fetchEmail();
  }, [token]);

  // Function to mask the email
  const maskEmail = (email) => {
    const [name] = email.split('@');
    if (name) {
      const cleanedName = name.split('.')[0].replace(/[0-9]/g, '');
      return cleanedName;
    }
    return '';
  };

  // Handle logout
  const handleLogout = async () => {
    try {
      const response = await fetch(`${config.baseUrl}/logout`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`,
        },
      });

      if (response.ok) {
        localStorage.removeItem('token');
        setEmail('');
        navigate('/login');
      } else {
        console.error('Logout failed');
      }
    } catch (error) {
      console.error('An error occurred during logout:', error);
    }
  };

  // Toggle dropdown and navigate to dashboard
  const toggleDropdown = () => {
    setDropdownOpen((prev) => !prev);
    navigate('/dashboard');
  };

  return (
    <nav className="navbar navbar-expand-lg bg-body-tertiary top-nav">
      <div className="container-fluid">
        <Link className="navbar-brand" to="/">
          <div className="logo">
            <img src="images/corpquick-final-Tranparen bg 1.png" alt="CorpQuick Logo" />
          </div>
        </Link>
        <button
          className="navbar-toggler"
          type="button"
          data-bs-toggle="collapse"
          data-bs-target="#navbarScroll"
          aria-controls="navbarScroll"
          aria-expanded="false"
          aria-label="Toggle navigation"
        >
          <span className="navbar-toggler-icon" />
        </button>
        <div className="collapse navbar-collapse" id="navbarScroll">
          <ul className="navbar-nav me-auto my-2 my-lg-0 menus">
            <li className="nav-item">
              <Link to="/about-us" className="nav-link">
                About Us
              </Link>
            </li>
            <li className="nav-item">
              <Link className="nav-link" to="/services">
                Services
              </Link>
            </li>
            <li className="nav-item">
              <Link className="nav-link" to="/affiliates">
                Affiliates
              </Link>
            </li>
            <li className="nav-item">
              <Link to="/contact-us" className="nav-link">
                Contact Us
              </Link>
            </li>
          </ul>
        </div>
        <div className="phone-part">
          <div className="phone1-icon">
            <img src="images/phone.png" alt="Phone Icon" />
          </div>
          <div className="phone1-text">
            <Link to="tel:(804) 916-1974">(804) 916-1974</Link>
          </div>
        </div>
        <div className="btn-part">
          {token ? (
            <div className="custom-dropdown">
              <button className="btn btn-secondary" onClick={toggleDropdown}>
                {email || 'User'} {/* Fallback to 'User' if email is empty */}
              </button>
              {dropdownOpen && (
                <div className="dropdown-content">
                  <Link className="dropdown-item" to="#" onClick={handleLogout}>
                    Logout
                  </Link>
                </div>
              )}
            </div>
          ) : (
            <div className="login-btn">
              <Link to="/login">LOGIN</Link>
            </div>
          )}
          <div className="Started">
            <Link to="/form-order">GET STARTED</Link>
          </div>
        </div>
      </div>
    </nav>
  );
};

export default Navbar;