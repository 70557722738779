import React from 'react';
import { Link } from 'react-router-dom';
import Header from '../Components/Common/Header';
import Footer from '../Components/Common/Footer';
const Services = () => {
    return (
        <>
            <Header />

            <div className='common-banenr'>
                <div className='common-content'>
                    <h1>Our <span>Services</span></h1>
                </div>
            </div>





            <div className='abt-section1'>
                <div className='container'>
                    <div className='row'>
                        <div className='col-md-6'>
                            <h2>CorpQuick – Officially Establish Your Business</h2>
                            <p>Welcome to <strong>CorpQuick,</strong> the premier business-building platform designed to formalize and
                                accelerate your entrepreneurial journey. Whether you are launching a startup or expanding an
                                existing enterprise, CorpQuick integrates essential tools and provides expert guidance to ensure
                                your business achieves sustained success.</p>

                        </div>
                        <div className='col-md-6'>
                            <img src="images/businessbuiding.jpg" alt="" />
                        </div>
                    </div>
                </div>

            </div>
            <div className='get-touch-ab'>
                <div className='container'>
                    <div className='row'>
                        <div className='col-md-12'>
                            <h2>Initiate Your Business Today</h2>
                            <p><strong>Streamline your business setup with CorpQuick.</strong> Our platform simplifies the complexities of
                                business formation, compliance, and growth, allowing you to focus on strategic initiatives.
                                Benefit from our efficient processes and professional support to establish a solid foundation for
                                your business.</p>

                        </div>

                    </div>
                </div>
            </div>




            <div className="corp box-services">
                <div className="container">
                    <div className="heading-part">
                        <h2>Our Comprehensive Services</h2>

                    </div>
                    <div class="services-page-box">
                        <div className="row">
                            <div className="col-md-3">
                                <div className="Afflicate-box">
                                    <div classname="mask-box">
                                        <img src="images/businessformation.jpg" alt="" />
                                        <div className="mask-content">
                                            <h5>Business Formation</h5>
                                            <p>Efficiently establish your business entity. Complete your business registration in minutes by
                                                responding to a few targeted questions. Gain immediate access to the advantages of official
                                                business status and lay the groundwork for operational success.</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-3">
                                <div className="Afflicate-box">
                                    <div classname="mask-box">
                                        <img src="images/emplyeerid.jpg" alt="" />
                                        <div className="mask-content">
                                            <h5>Employer Identification Number (EIN)</h5>
                                            <p>Obtain your EIN seamlessly. Secure your Employee Identification Number to facilitate license
                                                applications, open business bank accounts, and streamline your financial management.</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-3">
                                <div className="Afflicate-box">
                                    <div classname="mask-box">
                                        <img src="images/business-liced.jpg" alt="" />
                                        <div className="mask-content">
                                            <h5> Business Licenses and Permits</h5>
                                            <p>Ensure regulatory compliance with ease. Identify the specific licenses and permits required for
                                                your business operations. CorpQuick manages all associated paperwork, allowing you to remain
                                                focused on your core business activities.</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-3">
                                <div className="Afflicate-box">
                                    <div classname="mask-box">
                                        <img src="images/bookikeeping.jpg" alt="" />
                                        <div className="mask-content">
                                            <h5>Taxes and Bookkeeping</h5>
                                            <p>Optimize your financial strategy with our tax and bookkeeping expertise. Our specialists assist in
                                                maximizing deductions, ensuring accurate tax filings, and maintaining comprehensive financial
                                                records to support your business growth.</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="services-page-box">
                        <div className="row">
                            <div className="col-md-3">
                                <div className="Afflicate-box">
                                    <div classname="mask-box">
                                        <img src="images/insurance.jpg" alt="" />
                                        <div className="mask-content mask-content1">
                                            <h5>Business Insurance</h5>
                                            <p>Mitigate risks with tailored insurance solutions. Protect your business against unforeseen events
                                                with insurance plans customized to your unique requirements, ensuring comprehensive coverage
                                                and peace of mind.</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-3">
                                <div className="Afflicate-box">
                                    <div classname="mask-box">
                                        <img src="images/trademark.jpg" alt="" />
                                        <div className="mask-content mask-content1">
                                            <h5>Trademark Registration</h5>
                                            <p>Safeguard your brand identity. Secure your business name and protect your reputation
                                                nationwide with our comprehensive trademark registration services, ensuring your brand remains
                                                distinct and legally protected.</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-3">
                                <div className="Afflicate-box">
                                    <div classname="mask-box">
                                        <img src="images/develop.jpg" alt="" />
                                        <div className="mask-content mask-content1">
                                            <h5> Website Development</h5>
                                            <p>Establish a professional online presence effortlessly. Utilize our DIY templates or our automated
                                                website builder to create a visually appealing and functional website that aligns with your brand
                                                vision.</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-3">
                                <div className="Afflicate-box">
                                    <div classname="mask-box">
                                        <img src="images/doman.jpg" alt="" />
                                        <div className="mask-content mask-content1">
                                            <h5>Domain Registration</h5>
                                            <p>Enhance your online accessibility with the ideal domain name. CorpQuick assists in selecting a
                                                memorable and relevant domain, ensuring your customers can easily locate your business online.</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="services-page-box">
                        <div className="row">
                            <div className="col-md-3">
                                <div className="Afflicate-box">
                                    <div classname="mask-box">
                                        <img src="images/logo.jpg" alt="" />
                                        <div className="mask-content mask-content2">
                                            <h5>Logo Design</h5>
                                            <p>Develop a distinctive logo swiftly. Input your business name and industry, and our intuitive
                                                design tool will generate a professional logo that accurately represents your brand identity.</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-3">
                                <div className="Afflicate-box">
                                    <div classname="mask-box">
                                        <img src="images/businesscard.jpg" alt="" />
                                        <div className="mask-content mask-content2">
                                            <h5>Digital Business Card</h5>
                                            <p>Consolidate your contact information with a digital business card. Share a single link
                                                encompassing all essential contact details, facilitating seamless connections with your customers.</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-3">
                                <div className="Afflicate-box">
                                    <div classname="mask-box">
                                        <img src="images/maibox.jpg" alt="" />
                                        <div className="mask-content mask-content2">
                                            <h5>Business Mailbox</h5>
                                            <p>Elevate your professional image with a dedicated email address. Powered by G Suite from
                                                Google Cloud, our business mailbox services provide a reliable and polished communication
                                                channel for your enterprise.
                                                vision.</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-3">
                                <div className="Afflicate-box">
                                    <div classname="mask-box">
                                        <img src="images/websiteexpert.jpg" alt="" />
                                        <div className="mask-content mask-content2">
                                            <h5>Website Experts</h5>
                                            <p>Realize your website vision with our in-house experts. Our team designs bespoke business
                                                websites tailored to your specific requirements, ensuring your online presence precisely matches
                                                your expectations.</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-12">
                                <div className="Afflicate-box">
                                    <div classname="mask-box">
                                        <div className="row">
                                            <div className="col-md-6">
                                                <img src="images/digitalmarketingservices.jpg" alt="" />
                                            </div>
                                            <div className="col-md-6">
                                                <div className="mask-content digitalmarketing2">
                                                    <h5>Digital Marketing Services</h5>
                                                    <p>Accelerate your business growth with our comprehensive digital marketing solutions. Our
                                                        specialists deliver eCommerce SEO and PPC strategies to drive high-quality traffic, enhance
                                                        online visibility, and increase sales performance.</p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>
            </div>




            <div className='abt-section1 Entrepreneurial '>
                <div className='container'>
                    <div className='row'>
                        <div className='col-md-6'>
                            <h2>Why Choose CorpQuick?</h2>

                            <ul>
                                <li><strong><i class="fa fa-check" aria-hidden="true"></i> Integrated Platform:</strong>Access a full suite of tools necessary for business establishment
                                    and growth in a single, unified platform.</li>
                                <li><strong><i class="fa fa-check" aria-hidden="true"></i> Professional Expertise:</strong>Receive personalized support and insights from industry
                                    professionals at every stage of your business development.</li>
                                <li><strong><i class="fa fa-check" aria-hidden="true"></i> Efficiency and Simplicity:</strong>Navigate complex processes with our user-friendly interface,
                                    saving valuable time and resources.</li>
                                <li><strong><i class="fa fa-check" aria-hidden="true"></i> Comprehensive Solutions:</strong>From initial formation to advanced marketing strategies, our
                                    services encompass every facet of your business needs.</li>

                            </ul>
                        </div>
                        <div className='col-md-6'>
                            <img src="images/proffesional.jpg" alt="" />
                        </div>
                    </div>
                </div>

            </div>
            <div className='get-touch-ab take-first'>
                <div className='container'>
                    <div className='row'>
                        <div className='col-md-12'>
                            <h2>Take the First Step Towards Officializing Your Business</h2>
                            <p>Join a community of successful entrepreneurs who have leveraged CorpQuick to launch and
                                expand their businesses. <strong>Begin your journey today </strong>and establish the business you envision.</p>
                             <div className="link-service">   
                            <Link to="/form-order">Get Started</Link> <Link to="/contact-us">Contact Us</Link>
                            </div>
                        </div>

                    </div>
                </div>
            </div>
            <Footer />
        </>

    )
}

export default Services
