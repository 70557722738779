import React, { useState } from 'react';
import { useLocation } from 'react-router-dom';
import config from '../../config';
import Header from '../Common/Header';
import Footer from '../Common/Footer';
import { Link } from 'react-router-dom';
import Swal from 'sweetalert2';
import { useNavigate } from 'react-router-dom';

const ResetPassword = () => {
  const [oldPassword, setOldPassword] = useState('');
  const [newPassword, setNewPassword] = useState('');
  const [error, setError] = useState('');
  const [success, setSuccess] = useState('');
  const location = useLocation();
  const navigate = useNavigate();

  const queryParams = new URLSearchParams(location.search);
  const token = queryParams.get('token');

  console.log("Extracted Token:", token);

  const handleSubmit = async (event) => {
    event.preventDefault();
    setError('');
    setSuccess('');

    if (!oldPassword || !newPassword) {
      setError('Both fields are required.');
      return;
    }

    if (!token) {
      setError('Token is missing. Please check the reset link.');
      return;
    }

    try {
      const response = await fetch(`${config.baseUrl}/reset-password-update`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          token: token,
          password: newPassword,
          confirmPassword: newPassword
        }),
      });

      const responseData = await response.json();

      if (response.ok) {
        Swal.fire({
          icon: 'success',
          title: 'Password changed successfully.',
          showConfirmButton: false,
          timer: 2500,
        });
        navigate("/login");
      } else {
        Swal.fire({
          icon: 'error',
          title: 'Password reset failed.',
          showConfirmButton: true, // Show the "OK" button for user acknowledgment
          timer: 3000, // Optional: Auto-close after 3
        })
      }
    } catch (error) {
      setError('An error occurred. Please try again.');
    }
  };

  return (
    <>
      <Header />
      <section className="login-part">
        <div className="container">
          <div className="row">
            <div className="col-md-6">
              <div className="signup-form">
                <h2>
                  Reset <span>Password</span>
                </h2>
                {error && <p className="error-message">{error}</p>}
                {success && <p className="success-message">{success}</p>}
                <form onSubmit={handleSubmit}>
                  <input
                    type="hidden"
                    id="token"
                    name="token"
                    value={token || ''}
                  />
                  <div className="inputDiv">
                    <label className="inputLabel" htmlFor="oldPassword">
                      Old Password
                    </label>
                    <input
                      type="password"
                      id="oldPassword"
                      name="old_password"
                      value={oldPassword}
                      onChange={(e) => setOldPassword(e.target.value)}
                      required
                    />
                  </div>

                  <div className="inputDiv">
                    <label className="inputLabel" htmlFor="newPassword">
                      New Password
                    </label>
                    <input
                      type="password"
                      id="newPassword"
                      name="new_password"
                      value={newPassword}
                      onChange={(e) => setNewPassword(e.target.value)}
                      required
                    />
                  </div>

                  <div className="buttonWrapper">
                    <input type="submit" value="Submit" />
                  </div>
                </form>
                <p>
                  <Link to="/login">Sign In</Link>
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>
      <Footer />
    </>
  );
};

export default ResetPassword;
