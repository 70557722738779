import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import Header from '../Components/Common/Header';
import Footer from '../Components/Common/Footer';
import config from '../config';

const Privacy = () => {
  const [privacyName, setPrivacyName] = useState('');
  const [privacyBlocks, setPrivacyBlocks] = useState([]);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchPrivacy = async () => {
      try {
        const response = await fetch(`${config.baseUrl}/list-privacy`);
        if (!response.ok) {
          throw new Error('Failed to fetch privacy policy');
        }
        const result = await response.json();
        if (result.status === 'success' && result.data.length > 0) {
          const data = result.data[0];
          setPrivacyName(data.name);

          // Parse the JSON string in the description
          const description = JSON.parse(data.description);
          setPrivacyBlocks(description.blocks);
        } else {
          setError('No privacy policy data found');
        }
      } catch (err) {
        setError(err.message);
      }
    };

    fetchPrivacy();
  }, []);

  const renderBlock = (block) => {
    const styles = block.inlineStyleRanges.reduce((styleString, range) => {
      switch (range.style) {
        case 'BOLD':
          styleString += 'font-weight: bold; ';
          break;
        case 'fontsize-medium':
        case 'fontsize-16':
          styleString += 'font-size: 16px; ';
          break;
        case 'color-rgb(31,41,55)':
          styleString += 'color: rgb(31,41,55); ';
          break;
        case 'bgcolor-rgb(255,255,255)':
          styleString += 'background-color: rgb(255,255,255); ';
          break;
        case 'fontfamily-Poppins, sans-serif':
          styleString += 'font-family: "Poppins", sans-serif; ';
          break;
        case 'fontfamily-Arial':
          styleString += 'font-family: Arial, sans-serif; ';
          break;
        default:
          break;
      }
      return styleString;
    }, '');

    return (
      <p key={block.key} style={{ ...parseStyleString(styles), marginBottom: '16px' }}>
        {block.text}
      </p>
    );
  };

  const parseStyleString = (styleString) => {
    return styleString.split(';').reduce((styleObj, style) => {
      const [key, value] = style.split(':').map((str) => str.trim());
      if (key && value) styleObj[key] = value;
      return styleObj;
    }, {});
  };

  return (
    <>
      <Header />
      <div className='common-banenr'>
        <div className='common-content'>
          <h1>Privacy <span>Policy</span></h1>
        </div>
      </div>

      <div className='abt-section1 cancellation-policy'>
        <div className='container'>
          <div className='row'>
            <div className='col-md-12'>
              <h2>Effective Date: January 30, 2025</h2>
              <h4>Introduction</h4>
              <p>Welcome to <strong>CorpQuick</strong> ("CorpQuick","we","us","or","our";). At CorpQuick, we are committed
                to safeguarding your privacy. This Privacy Policy outlines how we collect, use, disclose, and
                protect your personal information when you access our website at <Link to="/">www.corpquick.com</Link> (the
                "Website") and use our services ("Services"). By accessing or using our Services, you agree to
                the terms of this Privacy Policy.</p>
              <h4>Information We Collect</h4>
              <p>We may collect the following categories of personal information from you:</p>
              <ul>
                <li><strong>Identifiers:</strong> Name, email address, postal address, phone number.</li>
                <li><strong>Personal Information Categories Listed in the California Customer Records
                  Statute:</strong> Telephone number, credit card number, driver’s license.</li>
                <li><strong>Commercial Information:</strong> Purchase history, products or services obtained.</li>
                <li><strong>Internet or Other Electronic Network Activity Information:</strong> Browsing history,
                  interactions with our Website.</li>
                <li><strong>Characteristics of Protected Classifications:</strong> Nationality, gender.</li>
                <li><strong>Geolocation Data:</strong> GPS data based on your IP address.</li>
                <li><strong>Audio, Electronic, or Visual Information:</strong> Recordings of calls to or from our customer
                  service centers.</li>
                <li><strong>Professional or Employment-Related Information:</strong> Job title, office location,
                  description of role.</li>
              </ul>

              <h4>How We Use Your Information</h4>
              <p>We use your personal information for the following purposes:</p>
              <ul>
                <li><strong>Providing Services:</strong> Delivering the products and services you request.</li>
                <li><strong>Processing Transactions:</strong> Managing payments and fulfilling orders.</li>
                <li><strong>Personalizing Your Experience:</strong>Customizing your interaction with our Website.</li>
                <li><strong>Marketing Communications:</strong> Sending you promotional materials (you can opt out at
                  any time).</li>
                <li><strong>Customer Support:</strong> Responding to your inquiries and providing assistance.</li>
                <li><strong>Improving Our Services:</strong> Enhancing our Website and Services based on your usage and
                  feedback.</li>
                <li><strong>Legal Compliance:</strong> Adhering to applicable laws and regulations.</li>
              </ul>

              <h4>Sharing Your Information</h4>
              <p>We may share your personal information with the following categories of third parties:</p>
              <ul>
                <li><strong>Service Providers:</strong> Companies that assist us in operating our business and providing our
                  Services (e.g., payment processors, email service providers).</li>
                <li><strong>Affiliates and Partners:</strong> Organizations within our corporate family for internal purposes
                  and to provide services you request.</li>
                <li><strong>Legal Authorities:</strong>When required by law, regulation, or legal process.</li>
                <li><strong>Lenders and Brokers:</strong> Within our network to facilitate services.</li>
                <li><strong>Professional Advisors:</strong> Legal, financial, and other advisors assisting us.</li>
                <li><strong>Third Parties with Your Consent:</strong> Any other third parties with whom you have
                  provided explicit consent.</li>

              </ul>
              <p>Please note that any personal data shared with third parties is subject to their own privacy
                policies, and we do not control their practices.</p>


              <h4>Your CCPA Rights</h4>
              <p>If you are a California resident, you have the following rights under the California Consumer
                Privacy Act (CCPA):</p>
              <ul>
                <li><strong>Right to Know:</strong> What personal information we collect about you.</li>
                <li><strong>Right to Delete:</strong> Request the deletion of your personal information.</li>
                <li><strong>Right to Opt-Out:</strong>Prevent the sale or sharing of your personal information.</li>
                <li><strong>Right to Non-Discrimination:</strong> Receive equal service and pricing, even if you exercise
                  your privacy rights.</li>
              </ul>
              <h4>Exercising Your Rights</h4>
              <p>To exercise your CCPA rights, please contact us at:</p>
              <ul>
                <li><strong>Email:</strong><Link to="mailto:privacy@corpquick.com"> privacy@corpquick.com</Link></li>
                <li><strong>Phone:</strong><Link to="tel:8049161974"> (804) 916-1974</Link></li>
              </ul>
              <p>We may require verification of your identity before processing your request. You may also
                designate an authorized agent to make requests on your behalf.</p>

              <h4>Data Retention</h4>
              <p>We retain your personal information only for as long as necessary to fulfill the purposes outlined
                in this Privacy Policy, unless a longer retention period is required or permitted by law. Our
                retention periods are based on:</p>
              <ul>
                <li>The length of our ongoing relationship with you.</li>
                <li>Legal obligations we must comply with.</li>
                <li>Whether retention is advisable in light of our legal position.</li>

              </ul>
              <h4>Changes to This Privacy Policy</h4>
              <p>We may update this Privacy Policy from time to time to reflect changes in our practices or for
                other operational, legal, or regulatory reasons. The latest version will always be posted on our
                Website with the effective date. By continuing to use our Services after any changes, you agree
                to the updated Privacy Policy.</p>
              <h4>Third-Party Sites</h4>
              <p>Our Website may contain links to third-party websites and services that are not operated by
                CorpQuick. We are not responsible for the privacy practices or the content of these third-party
                sites. We encourage you to review the privacy policies of any third-party sites you visit.</p>
              <h4>Security</h4>
              <p>We implement reasonable safeguards to protect your personal information from unauthorized
                access, use, or disclosure. However, no method of transmission over the internet or electronic
                storage is completely secure, and we cannot guarantee absolute security.</p>
              <h4>Contact Us</h4>
              <p>If you have any questions or concerns about this Privacy Policy or our data practices, please
                contact us at:</p>
              <ul>
                <li><strong>Email:</strong><Link to="mailto:privacy@corpquick.com"> privacy@corpquick.com</Link></li>
                <li><strong>Phone:</strong><Link to="tel:8049161974"> (804) 916-1974</Link></li>
                <li><strong>Address:</strong>CorpQuick, Marlton, NJ 08053</li>

              </ul>
              <h4>CCPA Pre-Collection Notice</h4>
              <p>CorpQuick</p>

              <ul>
                <li>Marlton, NJ 08053
                  (804) 916-1974</li>
                <li><Link to="/">www.corpquick.com</Link></li>
              </ul>
              <p>Dear CorpQuick Customer,</p>
              <p>We value your privacy and want to inform you about how we collect, use, and share your
                personal information. This notice is provided to you in accordance with the California Consumer
                Privacy Act (CCPA). Effective January 1, 2020, California residents have certain rights in
                relation to their personal information, subject to limited exceptions. Any terms defined in the
                CCPA have the same meaning when used in this California Privacy Notice. If you have any
                questions, please see our <Link to="/privacy">Privacy Policy</Link> or contact us as detailed herein.</p>

              <h4>The Information We Collect:</h4>
              <p>We collect various categories of personal information, including but not limited to:</p>
              <ul>
                <li><strong>Identifiers:</strong> Name, email address, phone number.</li>
                <li><strong>Personal Information Categories Listed in the California Customer Records
                  Statute:</strong> Telephone number, credit card number.</li>
                <li><strong>Commercial Information:</strong> Purchase history.</li>
                <li><strong>Internet Activity:</strong> Browsing history.</li>
                <li><strong>Geolocation Data:</strong> GPS data based on your IP address.</li>
                <li><strong>Audio, Electronic, or Visual Information:</strong> Recordings of calls to or from our customer
                  service centers.</li>
                <li><strong>Inferences Drawn from Your Information.</strong></li>
              </ul>
              <h4>Purposes of Collection:</h4>
              <p>We collect your personal information for the following purposes:</p>
              <ul>
                <li>Providing products or services.</li>
                <li>Processing transactions.</li>
                <li>Improving our products and services.</li>
                <li>Marketing and promotions.</li>
                <li>Analyzing website usage.</li>

              </ul>
              <h4>Disclosure of Information:</h4>
              <p>We may share your personal information with:</p>
              <ul>
                <li>Service providers and contractors for business purposes.</li>
                <li>Affiliates and partners for marketing.</li>
                <li>Legal and regulatory authorities when required.</li>
                <li>Lenders and brokers within our network for services.</li>
                <li>Professional advisors as deemed appropriate.</li>
                <li>Third parties for any purpose with which you consent.</li>
              </ul>
              <h4>Contact Us</h4>
              <p>For any questions, concerns, or to exercise your rights under the CCPA, please contact us at:</p>
              <ul>

                <li><strong>Phone</strong><Link to="tel:8049161974"> (804) 916-1974</Link></li>
                <li><strong>Email:</strong><Link to="mailto:privacy@corpquick.com"> privacy@corpquick.com</Link></li>
                <li><strong>Website:</strong><Link to="/contact-us"> www.corpquick.com/contact</Link></li>
              </ul>
              <p>For more details on our privacy practices, please review our <Link to="/privacy">Privacy Policy.</Link></p>

              <p><strong>Sincerely,</strong></p>
              <p><i>CorpQuick Customer Service.</i></p>






            </div>
          </div>
        </div>

      </div>

      <Footer />
    </>
  );
};

export default Privacy;
