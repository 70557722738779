import React, { useState, useEffect } from 'react';
import Header from '../../Common/Header';
import Footer from '../../Common/Footer';
import { Link, useNavigate } from 'react-router-dom';
import config from '../../../config';

const ProcessSS = () => {
    const [taxIdentificationSecurityNumber, setTaxIdentificationSecurityNumber] = useState('');
    const [taxIdentificationFirstName, setTaxIdentificationFirstName] = useState('');
    const [taxIdentificationLastName, setTaxIdentificationLastName] = useState('');
    const [selectTaxOption, setSelectTaxOption] = useState('');
    const [taxIdentificationCountry, setTaxIdentificationCountry] = useState('');
    const [taxIdentificationStreetAddress, setTaxIdentificationStreetAddress] = useState('');
    const [taxIdentificationAddressCont, setTaxIdentificationAddressCont] = useState('');
    const [taxIdentificationCity, setTaxIdentificationCity] = useState('');
    const [stateNames, setStateNames] = useState([]);
    const [selectedState, setSelectedState] = useState('');
    const [taxIdentificationState, setTaxIdentificationState] = useState('');
    const [taxIdentificationZipCode, setTaxIdentificationZipCode] = useState('');
    const [errors, setErrors] = useState({});
    const [apiData, setApiData] = useState(null);
    const [openSection, setOpenSection] = useState(null);
    const resultData = localStorage.getItem('formOrderResult');
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [additionalServices, setAdditionalServices] = useState([]);
    const [registeredAgentInformation, setRegisteredAgentInformation] = useState(false);
    let extractedId = null;
    const navigate = useNavigate();

    if (resultData) {
        const parsedResult = JSON.parse(resultData);
        extractedId = parsedResult.data.id;
        console.log('Extracted ID:', extractedId);
    }

    // Load the selected tax option from localStorage when the component mounts
    useEffect(() => {
        const savedTaxOption = localStorage.getItem('selectTaxOption');
        if (savedTaxOption) {
            setSelectTaxOption(savedTaxOption);
        }

        const fetchData = async () => {
            try {
                const response = await fetch(`${config.baseUrl}/registration/get-form-1/${extractedId}`);
                if (response.ok) {
                    const data = await response.json();
                    setApiData(data.data);
                    if (data.data.registeredAgentInformation === "free") {
                        setRegisteredAgentInformation(true);
                    }
                    if (data.data.additional_services) {
                        const parsedServices = JSON.parse(data.data.additional_services);
                        setAdditionalServices(parsedServices);
                    }

                    const fields = {
                        selectTaxOption: setSelectTaxOption,
                        taxIdentificationSecurityNumber: setTaxIdentificationSecurityNumber,
                        taxIdentificationFirstName: setTaxIdentificationFirstName,
                        taxIdentificationLastName: setTaxIdentificationLastName,
                        taxIdentificationCountry: setTaxIdentificationCountry,
                        taxIdentificationStreetAddress: setTaxIdentificationStreetAddress,
                        taxIdentificationAddressCont: setTaxIdentificationAddressCont,
                        taxIdentificationCity: setTaxIdentificationCity,
                        taxIdentificationState: setTaxIdentificationState,
                        taxIdentificationZipCode: setTaxIdentificationZipCode
                    };

                    Object.entries(fields).forEach(([key, setter]) => {
                        if (data.data[key]) {
                            setter(data.data[key]);
                        }
                    });

                } else {
                    console.error('Error fetching data:', response.statusText);
                }
            } catch (error) {
                console.error('Error:', error);
            }
        };

        fetchData();
    }, [extractedId]);

    const handleSelectTaxOptionChange = (e) => {
        const selectedOption = e.target.value;
        setSelectTaxOption(selectedOption);
        // Save the selected option to localStorage
        localStorage.setItem('selectTaxOption', selectedOption);
    };
    useEffect(() => {

        fetch(`${config.baseUrl}/state/list`)
            .then(response => response.json())
            .then(data => {
                if (data.status === 'success') {
                    setStateNames(data.data);
                } else {
                    console.error('Failed to fetch state names');
                }
            })
            .catch(error => {
                console.error('Error fetching state names:', error);
            });
    }, []);
    console.log(apiData)
    useEffect(() => {
        if (apiData) {
            setTaxIdentificationState(apiData.taxIdentificationState || '');
            setSelectedState(apiData.taxIdentificationState || '');
        }
    }, [apiData]);

    const handleSubmit = async (e) => {
        e.preventDefault();
        setIsSubmitting(true);
        setErrors({});

        // Validation checks
        const validationErrors = {};

        if (!taxIdentificationFirstName) {
            validationErrors.taxIdentificationFirstName = "First Name is required";
        }
        if (!taxIdentificationLastName) {
            validationErrors.taxIdentificationLastName = "Last Name is required";
        }
        if (!taxIdentificationCountry) {
            validationErrors.taxIdentificationCountry = "Country is required";
        }
        if (!taxIdentificationStreetAddress) {
            validationErrors.taxIdentificationStreetAddress = "Street Address is required";
        }
        if (!taxIdentificationCity) {
            validationErrors.taxIdentificationCity = "City is required";
        }
        if (!taxIdentificationState) {
            validationErrors.taxIdentificationState = "State is required";
        }
        if (!taxIdentificationZipCode) {
            validationErrors.taxIdentificationZipCode = "Zip Code is required";
        }

        // If there are validation errors, set them and prevent form submission
        if (Object.keys(validationErrors).length > 0) {
            setErrors(validationErrors);
            setIsSubmitting(false);
            return;
        }

        const payload = {
            selectTaxOption: selectTaxOption,
            taxIdentificationSecurityNumber: taxIdentificationSecurityNumber,
            taxIdentificationFirstName: taxIdentificationFirstName,
            taxIdentificationLastName: taxIdentificationLastName,
            taxIdentificationCountry: taxIdentificationCountry,
            taxIdentificationStreetAddress: taxIdentificationStreetAddress,
            taxIdentificationAddressCont: taxIdentificationAddressCont,
            taxIdentificationCity: taxIdentificationCity,
            taxIdentificationState: taxIdentificationState,
            taxIdentificationZipCode: taxIdentificationZipCode,
        };

        try {
            const response = await fetch(`${config.baseUrl}/registration/form-8/${extractedId}`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(payload)
            });

            if (response.ok) {
                const data = await response.json();
                navigate("/small-banking");
                console.log('Form submission successful:', data);
            } else {
                console.error('Error in form submission:', response.statusText);
            }
        } catch (error) {
            console.error('Error:', error);
        } finally {
            setIsSubmitting(false);
        }
    };

    const toggleSection = (section) => {
        setOpenSection(openSection === section ? null : section);
    };
    const handleTaxIdChange = (e) => {
        let value = e.target.value.replace(/\D/g, ""); // Remove all non-numeric characters

        if (value.length > 9) value = value.slice(0, 9); // Limit input to 9 digits

        // Auto-format as NNN-NN-NNNN
        let formattedValue = value;
        if (value.length > 5) {
            formattedValue = `${value.slice(0, 3)}-${value.slice(3, 5)}-${value.slice(5)}`;
        } else if (value.length > 3) {
            formattedValue = `${value.slice(0, 3)}-${value.slice(3)}`;
        }

        setTaxIdentificationSecurityNumber(formattedValue);
    };
    return (
        <>
            <Header />
            <section className="registration-from">
                <div className="container">
                    <div className="registerformstart">
                        <div className="row">
                            <div className="col-md-8">
                                <form onSubmit={handleSubmit}>
                                    <div className="register-left">
                                        <div className="box__heading">
                                            EIN / Tax Identification Number Information{" "}
                                        </div>
                                        <p>
                                            An Employer Identification Number (EIN) is a nine-digit number
                                            that is assigned by the IRS and used to identify taxpayers. We
                                            will apply and obtain your EIN from the IRS electronically. This
                                            option is the fastest way to obtain the EIN.
                                        </p>
                                        <div className="divider mb25" />
                                        <h6>
                                            <strong>I am a foreign individual and do not have a social security number</strong>
                                        </h6>

                                        <div className="twoBox form-standard-process mb25">
                                            <div className="form-group" id="firstNameBlock">
                                                <label htmlFor="firstName">First Name:</label>
                                                <input
                                                    type="text"
                                                    class="form-control" id="firstName"
                                                    value={taxIdentificationFirstName}
                                                    onChange={(e) => setTaxIdentificationFirstName(e.target.value)}
                                                    required
                                                />
                                                {errors.taxIdentificationFirstName && (
                                                    <div className="error-text">{errors.taxIdentificationFirstName}</div>
                                                )}
                                            </div>
                                            <div className="form-group" id="lastNameBlock">
                                                <label htmlFor="lastName">Last Name:</label>
                                                <input
                                                    type="text"
                                                    className="form-control"
                                                    id="lastName"
                                                    name="lastName"
                                                    value={taxIdentificationLastName}
                                                    onChange={(e) => setTaxIdentificationLastName(e.target.value)}
                                                    required=""
                                                />
                                                {errors.taxIdentificationLastName && (
                                                    <div className="error-text">{errors.taxIdentificationLastName}</div>
                                                )}
                                            </div>
                                        </div>
                                        <div id="identificationNumberSection">
                                            <div className='identification1'>Identification number by which I will obtain the EIN</div>
                                            <div className="custom_radio mb25 identification-type">
                                                <div>
                                                    <input
                                                        type="radio"
                                                        id="SSN"
                                                        name="identificationNumberType"
                                                        value="SSN"
                                                        checked={selectTaxOption === 'SSN'}
                                                        onChange={handleSelectTaxOptionChange}
                                                    />
                                                    <label htmlFor="SSN">SSN</label>
                                                </div>
                                                <div>
                                                    <input
                                                        type="radio"
                                                        id="ITIN"
                                                        name="identificationNumberType"
                                                        value="ITIN"
                                                        checked={selectTaxOption === 'ITIN'}
                                                        onChange={handleSelectTaxOptionChange}
                                                    />
                                                    <label htmlFor="ITIN">
                                                        <span className="">ITIN</span>
                                                    </label>
                                                </div>
                                            </div>
                                            {selectTaxOption === 'SSN' && (
                                                <div className="form-group col-md-6 mb25" id="ssnBlock">
                                                    <label htmlFor="ssn">SSN</label>
                                                    <input
                                                        type="text"
                                                        className="form-control"
                                                        id="ssn"
                                                        value={taxIdentificationSecurityNumber}
                                                        onChange={(e) => handleTaxIdChange(e)}

                                                        maxLength="11"
                                                        required
                                                    />
                                                </div>
                                            )}
                                            {selectTaxOption === 'ITIN' && (
                                                <div className="form-group col-md-6 mb25" id="ITIN">
                                                    <label htmlFor="itin">ITIN</label>
                                                    <input
                                                        type="text"
                                                        className="form-control"
                                                        id="itin"
                                                        value={taxIdentificationSecurityNumber}
                                                        onChange={(e) => handleTaxIdChange(e)}

                                                        maxLength="11"
                                                        required
                                                    />
                                                </div>
                                            )}
                                            <div className="accordion mb25" id="selectedFreeYear">
                                                <div className="accordion-item">
                                                    <div className="accordion-header">
                                                        <button
                                                            type="button"
                                                            className={`accordion-button ${openSection === 'sectionOne' ? '' : 'collapsed'}`}
                                                            onClick={() => toggleSection('sectionOne')}                                                        >
                                                            The IRS needs your Social Security Number to verify your
                                                            identity and prevent fraud.
                                                        </button>
                                                    </div>
                                                    <div
                                                        id="panelsStayOpen-collapseTwo"
                                                        className={`accordion-collapse collapse ${openSection === 'sectionOne' ? 'show' : ''}`}
                                                    >
                                                        <div className="accordion-body">
                                                            In order to issue an EIN, the IRS requires a Principal
                                                            (typically one of the members or directors of an entity)
                                                            to provide their Social Security Number. This creates a
                                                            formal affiliation with the company/entity. The Social
                                                            Security Number is strictly used for obtaining the EIN.
                                                            Once the EIN process is complete, your SSN is permanently
                                                            deleted from the Bizee database. To further protect your
                                                            security, all Social Security Numbers are stored on a
                                                            secure, encrypted server during the EIN process.
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className='box__heading'>Physical Street Address</div>
                                        <div className="mb-4">
                                            The IRS requires a physical address in order to issue an Employer
                                            Identification Number (EIN / Tax ID Number) to your company.
                                            <strong className="">
                                                Please note the IRS will not allow for the use of a PO Box;
                                                however, this address will not be public under any circumstance.
                                            </strong>
                                        </div>

                                        <form id="ss4Country">
                                            <div className="form-group" id="">
                                                <label htmlFor="companyName">Country </label>
                                                <input
                                                    type="text"
                                                    className="form-control"
                                                    id="companyName"
                                                    name="companyName"
                                                    value={taxIdentificationCountry}
                                                    onChange={(e) => setTaxIdentificationCountry(e.target.value)}
                                                    required=""
                                                />
                                                {errors.taxIdentificationCountry && (
                                                    <div className="error-text">{errors.taxIdentificationCountry}</div>
                                                )}
                                            </div>
                                            <div className="twoBox">
                                                <div className="form-group">
                                                    <label htmlFor="streetAddress">Street Address</label>
                                                    <input
                                                        type="text"
                                                        className="form-control"
                                                        id="streetAddress"
                                                        name="streetAddress"
                                                        value={taxIdentificationStreetAddress}
                                                        onChange={(e) => setTaxIdentificationStreetAddress(e.target.value)}
                                                        required
                                                    />
                                                    {errors.taxIdentificationStreetAddress && (
                                                        <div className="error-text">{errors.taxIdentificationStreetAddress}</div>
                                                    )}

                                                </div>
                                                <div className="form-group">
                                                    <label htmlFor="addressContOptional">
                                                        {" "}
                                                        Address (Cont) (Optional)
                                                    </label>
                                                    <input
                                                        type="text"
                                                        className="form-control"
                                                        id="addressContOptional"
                                                        name="addressContOptional"
                                                        value={taxIdentificationAddressCont}
                                                        onChange={(e) => setTaxIdentificationAddressCont(e.target.value)}
                                                        required
                                                    />
                                                </div>
                                            </div>
                                            <div className="form-group">
                                                <label htmlFor="city">City:</label>
                                                <input
                                                    type="text"
                                                    className="form-control"
                                                    id="city"
                                                    name="city"
                                                    value={taxIdentificationCity}
                                                    onChange={(e) => setTaxIdentificationCity(e.target.value)}
                                                    required
                                                />
                                                {errors.taxIdentificationCity && (
                                                    <div className="error-text">{errors.taxIdentificationCity}</div>
                                                )}
                                            </div>
                                            <div className="twoBox">

                                                <div className="form-group">
                                                    <label htmlFor="state">State:</label>
                                                    <select
                                                        className="form-select"
                                                        value={selectedState}
                                                        onChange={(e) => {
                                                            const state = e.target.value;
                                                            setSelectedState(state);
                                                            setTaxIdentificationState(state); // Update the taxIdentificationState directly
                                                        }}
                                                    >
                                                        <option value="" disabled>Choose State of Formation</option>
                                                        {stateNames.map((state) => (
                                                            <option key={state.id} value={state.state_name}>
                                                                {state.state_name}
                                                            </option>
                                                        ))}
                                                    </select>

                                                    {errors.taxIdentificationState && (
                                                        <div className="error-text">{errors.taxIdentificationState}</div>
                                                    )}
                                                </div>
                                                <div className="form-group">
                                                    <label htmlFor="zipCode">Zip Code:</label>
                                                    <input
                                                        type="number"
                                                        className="form-control"
                                                        id="zipCode"
                                                        name="zipCode"
                                                        value={taxIdentificationZipCode}
                                                        onChange={(e) => {
                                                            // Restrict to numbers only
                                                            const value = e.target.value;
                                                            if (/^[0-9]*$/.test(value)) {
                                                                setTaxIdentificationZipCode(value); // Set the value only if it is a number
                                                            }
                                                        }}
                                                        required=""
                                                    />
                                                    {errors.taxIdentificationZipCode && (
                                                        <div className="error-text">{errors.taxIdentificationZipCode}</div>
                                                    )}
                                                </div>
                                            </div>
                                        </form>
                                        <div className="info-blk-company-address" id="infCompanyAddress">
                                            <div className="imgBlk">
                                                <img src="images/info.svg" />
                                            </div>
                                            <div>
                                                <h6>
                                                    You have selected for Bizee to provide you with a company
                                                    address
                                                </h6>
                                                <p>
                                                    We will use the company address for the member information in
                                                    order to shield your personal information from being publicly
                                                    available.
                                                </p>
                                            </div>
                                        </div>
                                        <div className="button-nest-prev">
                                            <div className="left-next">

                                                <Link to="/process-agent">
                                                    <button type="button">
                                                        <i className="fa fa-arrow-left" aria-hidden="true" />
                                                        Back
                                                    </button>
                                                </Link>

                                            </div>
                                            <div className="right-next nex2">
                                                <button type="submit" disabled={isSubmitting}>
                                                    Next
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                </form>
                            </div>
                            <div className="col-md-4">
                                <div className="register-right">
                                    <h4>Order Summary</h4>
                                    <h2>{apiData ? apiData.full_business_name : ''}</h2>
                                    <div className="progress">
                                        <div
                                            className="progress-bar"
                                            role="progressbar"
                                            style={{ width: "55%" }}
                                            aria-valuenow={55}
                                            aria-valuemin={0}
                                            aria-valuemax={100}
                                        >
                                            55%
                                        </div>
                                    </div>
                                    <div className="order-items flex flex-col gap-3 mt-8">
                                        <div className="item">
                                            <div className="name">{apiData ? apiData.package : ''} Package</div>
                                            <div className="value ">${apiData ? apiData.package_fee : ''}</div>
                                        </div>
                                        {additionalServices.map((service, index) => (
                                            <div className="item" key={index}>
                                                <div className="name">{service.name}</div>
                                                <div className="value">${service.price}</div>
                                            </div>
                                        ))}
                                        <div className="item">
                                            <div className="name">{apiData ? apiData.selected_state : ''} State Filing Fee</div>
                                            <div className="value">
                                                <span id="dynamic-filing-fee">${apiData ? apiData.stateFee : ''}</span>
                                            </div>
                                        </div>
                                        {apiData?.companyAddress === "Professional Business Address" && (
                                            <div className="item" id="order-item-legal-consulation">
                                                <div className="name">Business Address</div>
                                                <div className="value">
                                                    $<span id="dynamic-filing-fee">29</span>
                                                </div>
                                            </div>
                                        )}
                                        <div className="item hide" id="order-item-legal-consulation">
                                            <div className="name">Banking Resolution</div>
                                            <div className="value">
                                                <i className="fa fa-check" aria-hidden="true" />
                                            </div>
                                        </div>
                                        <div className="item hide" id="order-item-expedited">
                                            <div className="name">Banking Resolution</div>
                                            <div className="value">
                                                <i className="fa fa-check" aria-hidden="true" />
                                            </div>
                                        </div>
                                        <div className="item hide" id="order-item-legal-consulation">
                                            <div className="name">Minutes Meeting</div>
                                            <div className="value">
                                                <i className="fa fa-check" aria-hidden="true" />
                                            </div>
                                        </div>
                                        <div className="item hide" id="order-item-license-package">
                                            <div className="name">Bylaws</div>
                                            <div className="value">
                                                <i className="fa fa-check" aria-hidden="true" />
                                            </div>
                                        </div>
                                        {registeredAgentInformation && (
                                            <div className="item">
                                                <div className="name">Registered Agent (1st Year)</div>
                                                <div className="value">
                                                    Free
                                                </div>
                                            </div>
                                        )}
                                        <div className="item hide" id="order-item-va">
                                            <div className="name">EIN / Tax ID #</div>
                                            <div className="value">
                                                <i className="fa fa-check" aria-hidden="true" />
                                            </div>
                                        </div>
                                        <div className="item">
                                            <div className="name">Electronic Delivery</div>
                                            <div className="value">
                                                <i className="fa fa-check" aria-hidden="true" />
                                            </div>
                                        </div>
                                        <div className="item">
                                            <div className="name">Form 2553</div>
                                            <div className="value">
                                                <i className="fa fa-check" aria-hidden="true" />
                                            </div>
                                        </div>

                                        <div className="item">
                                            <div className="name flex flex-col">
                                                <span>Phone Support</span>
                                                <span className="text-xs text-gray-700 mt-[6px]">
                                                    Available Monday - Friday from 9 a.m. to 6 p.m. CST
                                                </span>
                                            </div>
                                            <div className="value">
                                                <i className="fa fa-check" aria-hidden="true" />
                                            </div>
                                        </div>
                                        <hr className="!mt-1 !mb-1" />
                                        <div className="totla-value1">
                                            <div className="item items-center mb-8">
                                                <div className="name">Total</div>
                                                <div className="value bold text-black">
                                                    <span className="SumPackageTotal text-2xl">
                                                        $<span className="totalPrice">
                                                            {apiData
                                                                ? parseFloat(apiData.form_total_price || 0) +
                                                                (apiData.companyAddress === "Professional Business Address" ? 29 : 0)
                                                                : ''}
                                                        </span>
                                                    </span>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="trustpilot">
                                            <img src="images/Trustpilot-Reviews-Services-1.png" alt="" />
                                            <p>TrustScore 4.7 | 19,076 reviews</p>
                                        </div>

                                        <div className="join-business">
                                            <div className="font-radwave text-black">
                                                <h2 className="text-base tracking-wide text-left  my-0">
                                                    JOIN THE
                                                </h2>
                                                <p className="text-base text-red-500 tracking-wide ">
                                                    1,000,000+
                                                </p>
                                                <h3 className="text-base tracking-wide uppercase my-0">
                                                    Businesses
                                                </h3>
                                                <h6>
                                                    That have trusted Bizee to start, manage, and grow their
                                                    business{" "}
                                                </h6>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <Footer />
        </>
    )
}

export default ProcessSS
