import React from 'react';
import { Link } from 'react-router-dom';
import Header from '../Components/Common/Header';
import Footer from '../Components/Common/Footer';
const Cancellation = () => {
    return (
        <>
            <Header />

            <div className='common-banenr'>
                <div className='common-content'>
                    <h1>Cancellation <span>Policy</span></h1>
                </div>
            </div>





            <div className='abt-section1 cancellation-policy'>
                <div className='container'>
                    <div className='row'>
                        <div className='col-md-12'>
                            <h2>PLEASE READ THIS CANCELLATION POLICY CAREFULLY BEFORE ACCESSING
                                OUR WEBSITE OR USING OUR SERVICES</h2>
                            <h4>Overview</h4>
                            <p>This Cancellation Policy outlines the terms and conditions under which you may cancel your
                                services with <strong>CorpQuick LLC</strong> (&quot;CorpQuick,&quot; &quot;we,&quot; &quot;us,&quot; or &quot;our&quot;). By accessing or using our
                                Services, you agree to be bound by this policy.</p>
                            <h4>Termination of Services</h4>
                            <ul>
                                <li><strong>Termination by CorpQuick:</strong> We reserve the right to refuse or cancel services to any
                                    individual or entity at our sole discretion, for any reason, including misuse of promotions
                                    or other policies. For example, if a promotion is intended for single use, attempting to use
                                    it multiple times constitutes misuse, and we may cancel your order or refuse service
                                    without liability.</li>
                            </ul>
                            <h4>Refunds</h4>
                            <ul>
                                <li><strong>Before Payment to Government Entities:</strong>
                                    <ul>
                                        <li>You may request a refund before any payment is made to government entities
                                            (typically within 24 hours of placing the order).</li>
                                        <li>A $30 cancellation fee will apply, along with any expenses incurred, such as
                                            payments to state agencies or third-party vendors.</li>
                                    </ul>
                                </li>
                                <li><strong>After Payment to Government Entities:</strong>
                                    <ul>
                                        <li>Once payments have been forwarded to government entities or third parties, we
                                            cannot accept cancellations or changes to your order.</li>
                                        <li>For trademark filings, cancellations are not accepted once a search has been
                                            conducted or payment made to the U.S. Patent and Trademark Office.</li>
                                    </ul>
                                </li>
                            </ul>


                            <h4>How to Cancel</h4>
                            <ul>
                                <li><strong>Cancellation Process:</strong>
                                    <ul>
                                        <li>To cancel an order prior to payments to government entities or before conducting
                                            a trademark search, your order must be in &quot;Review&quot; status.</li>
                                        <li>Place your order on hold by clicking the &quot;Make Changes&quot; button in your order
                                            confirmation email.</li>
                                        <li>If your cancellation request meets our requirements, we will honor it at our sole
                                            discretion.</li>
                                    </ul>
                                </li>
                                <li><strong>Restrictions:</strong>
                                    <ul>
                                        <li>Cancellation requests cannot be made via telephone or email.</li>
                                        <li>Instructions to cancel an order or make changes must be followed through the
                                            provided online methods.</li>
                                    </ul>
                                </li>
                            </ul>


                            <h4>Chargebacks</h4>
                            <ul>
                                <li><strong>Legitimate Chargebacks:</strong>We do not dispute legitimate chargebacks.
                                </li>
                                <li><strong>Illegitimate or Improper Chargebacks:</strong>
                                    <ul>
                                        <li>For chargebacks requested after payment to government entities or third parties,
                                            or after a trademark search has been conducted, we reserve the right to take
                                            appropriate actions.</li>
                                        <li>Actions may include canceling subscriptions, dissolving any entities formed
                                            through disputed charges, and charging liquidated damages of $500 plus any
                                            associated costs.</li>
                                    </ul>
                                </li>
                                <li><strong>Fraudulent Activities:</strong>
                                    <ul>
                                        <li>Any legal entity fraudulently formed using unauthorized third-party names will be
                                            dissolved by CorpQuick.</li>
                                        <li>The responsible party will be liable for $10,000 in liquidated damages and any
                                            associated dissolution costs.</li>
                                    </ul>
                                </li>
                            </ul>


                            <h4>Auto-Renewal and Price Changes</h4>
                            <ul>
                                <li><strong>Auto-Renewal:</strong>
                                    <ul>
                                        <li>Services may automatically renew to maintain compliance with state or
                                            government requirements.</li>
                                        <li>Your credit card will be charged by our registered agent partner for annual
                                            renewal fees.</li>
                                    </ul>
                                </li>
                                <li><strong>Cancellation of Auto-Renewal:</strong>
                                    <ul>
                                        <li>You can cancel the Registered Agent service by assigning a new agent and
                                            notifying us before the expiration date.</li>
                                        <li>Failure to notify may result in automatic renewal.</li>
                                    </ul>
                                </li>
                                <li><strong>Price Changes:</strong>
                                    <ul>
                                        <li>All advertised prices are subject to change without notice.</li>
                                        <li>Credit card information held for auto-renewal will not be used for other purposes
                                            without your consent.</li>
                                    </ul>
                                </li>
                            </ul>


                            <h4>General Conditions</h4>
                            <ul>
                                <li><strong>Misuse of Services:</strong>
                                    <ul>
                                        <li>We may restrict, suspend, or block access to customers who misuse services,
                                            infringe intellectual property rights, engage in spamming, or violate our policies.</li>
                                    </ul>
                                </li>
                                <li><strong>Liability:</strong>
                                    <ul>
                                        <li>We are not responsible for any damage or loss resulting from the cancellation or
                                            termination of services.</li>
                                    </ul>
                                </li>
                                <li><strong>Termination Consequences:</strong>
                                    <ul>
                                        <li>Upon termination, you may lose access to some or all Services.</li>
                                        <li>We may block access from your IP address.</li>
                                        <li>All licenses and rights granted to you will cease, and any unpaid fees prior to
                                            termination remain due without refunds.</li>
                                    </ul>
                                </li>
                            </ul>
                            <h4>Indemnification</h4>
                            <p>You agree to indemnify, defend, and hold harmless CorpQuick and its affiliates from any claims,
                                losses, damages, liabilities, costs, and expenses arising out of your use or misuse of our Services,
                                including any violation of these Terms.</p>
                            <h4>Governing Law</h4>
                            <p>These Terms are governed by the laws of the State of New Jersey, USA. Any disputes arising out
                                of these Terms or your use of our Services will be resolved through binding arbitration in
                                Camden County, New Jersey.</p>
                            <h4>Contact Information</h4>
                            <p>For any cancellation requests or further assistance, please follow the specified procedures
                                outlined above through your account dashboard.</p>
                            <h4>Agreement</h4>
                            <p>By using our Services, you acknowledge that you have read and agree to this Cancellation Policy
                                in its entirety.</p>
                        </div>
                    </div>
                </div>

            </div>

            <Footer />
        </>

    )
}

export default Cancellation
