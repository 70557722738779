import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import tippy from 'tippy.js';
import { Link } from 'react-router-dom';
import 'tippy.js/dist/tippy.css';
import Header from '../../Common/Header';
import Footer from '../../Common/Footer';
import ConfirmOrder from '../ConfirmOrder'; // Assuming you have the ConfirmOrder component
import '../registerstyle.css';
import config from '../../../config';

const CompanyInformation = () => {
    const [formOrderData, setFormOrderData] = useState(null);
    const [companyName, setCompanyName] = useState('');
    const [designator, setDesignator] = useState('');
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [additionalServices, setAdditionalServices] = useState([]); 
    const [apiData, setApiData] = useState(null);
    const navigate = useNavigate();
    const [designators, setDesignators] = useState([]);
    const resultData = localStorage.getItem('formOrderResult');
    console.log('Result Data:', resultData);
    let extractedId = null;
    if (resultData) {
        const parsedResult = JSON.parse(resultData);
        extractedId = parsedResult.data.id; // Accessing the id
        console.log('Extracted ID:', extractedId); // Log the extracted ID
    }
    useEffect(() => {
        // Fetch data from the GET API when the component mounts
        const fetchData = async () => {
            try {
                const response = await fetch(`${config.baseUrl}/registration/get-form-1/${extractedId}`);
                if (response.ok) {
                    const data = await response.json();
                    setApiData(data.data); // Store the data in state
                    console.log('Fetched API Data:', data.data); // Log the fetched data
                    if (data.data.business_name) {
                        setCompanyName(data.data.business_name);
                    }
                    if (data.data.additional_services) {
                        const parsedServices = JSON.parse(data.data.additional_services);
                        setAdditionalServices(parsedServices);
                    }

                    // Also set designator if it is available
                    if (data.data.designator) {
                        setDesignator(data.data.designator);
                    }
                } else {
                    console.error('Error fetching data:', response.statusText);
                }
            } catch (error) {
                console.error('Error:', error);
            }
        };

        fetchData(); // Call the function to fetch data
    }, []);
    console.log(apiData)
    useEffect(() => {
        const fetchDesignators = async () => {
            // Ensure that apiData is loaded before using selectedEntityId
            if (apiData && apiData.selectedEntityId) {
                const entity_id = apiData.selectedEntityId; // Use selectedEntityId from apiData

                try {
                    const response = await fetch(`${config.baseUrl}/designator/list`, {
                        method: 'POST',
                        headers: {
                            'Content-Type': 'application/json',
                        },
                        body: JSON.stringify({ entity_id }), // Use entity_id from apiData
                    });

                    if (response.ok) {
                        const data = await response.json();
                        setDesignators(data.data);
                    } else {
                        console.error('Error fetching designators:', response.statusText);
                    }
                } catch (error) {
                    console.error('Error:', error);
                }
            }
        };

        fetchDesignators();
    }, [apiData]); // Re-run when apiData changes
    // Re-run when apiData changes






    useEffect(() => {
        const savedFormOrder = localStorage.getItem('formOrder');
        if (savedFormOrder) {
            setFormOrderData(JSON.parse(savedFormOrder));
        }
        tippy('.tooltip', {
            placement: 'top',
            arrow: true,
            animation: 'fade',
        });
    }, []);

    useEffect(() => {
        if (formOrderData) {
            const updatedFormOrderData = {
                ...formOrderData,
                companyName,
                designator
            };
            localStorage.setItem('formOrder', JSON.stringify(updatedFormOrderData));
        }
    }, [formOrderData, companyName, designator]);

    const handleCompanyNameChange = (e) => {
        setCompanyName(e.target.value);
    };

    const handleDesignatorChange = (e) => {
        setDesignator(e.target.value);
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        setIsSubmitting(true);

        const fullBusinessName = `${companyName} ${designator}`;
        const payload = {
            businessName: companyName,
            designator: designator,
            fullBusinessName: fullBusinessName
        };

        try {
            const response = await fetch(`${config.baseUrl}/registration/form-2/${extractedId}`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(payload)
            });

            if (response.ok) {
                const data = await response.json();
                navigate("/process-contact-person")
                console.log('Form submission successful:', data);
            } else {
                console.error('Error in form submission:', response.statusText);
            }
        } catch (error) {
            console.error('Error:', error);
        } finally {
            setIsSubmitting(false);
        }
    };

    const [showConfirmOrder, setShowConfirmOrder] = useState(false); // Add state to control the modal display

    const handleBackClick = (e) => {
        e.preventDefault();
        setShowConfirmOrder(true); // Show the confirm order modal
    };

    const handleCloseConfirmOrder = () => {
        setShowConfirmOrder(false); // Close the modal
    };

    return (
        <>
            <Header />
            <section className="registration-from">
                <div className="container">
                    <div className="registerformstart">
                        <div className="row">
                           
                        <div className="col-md-8">
                                <div className="register-left">
                                    <div className="box__heading">Company Information</div>
                                    <div className="box-content">
                                        <form onSubmit={handleSubmit}>
                                            <div className="row">
                                                <div className="col-md-6">
                                                    <div className="form-group">
                                                        <div className="label-part">
                                                            <div>{formOrderData ? formOrderData.selectedEntity : ''} Name</div>
                                                            <i className="fa fa-question-circle" aria-hidden="true" />
                                                            <div
                                                                className="tooltip"
                                                                data-tippy-content="The use of most special characters in the company name is not permitted, however the state does make an exception for characters such as commas, apostrophes, dashes, periods, and ampersands ( , ' - . &)."
                                                            ></div>
                                                        </div>
                                                        <div className="wrap-field">
                                                            <input
                                                                type="text"
                                                                id="compName1"
                                                                name="compName1"
                                                                value={companyName}
                                                                onChange={(e) => setCompanyName(e.target.value.replace(/^\s/g, ''))} // Remove leading and trailing spaces
                                                                className="form-control js-convert-to-uppercase no-injection-of-code-required"
                                                                autoComplete="off"
                                                                data-help-text="Company Name is required"
                                                                required
                                                            />


                                                            <span
                                                                className="help-block-error hide"
                                                                style={{ display: "none" }}
                                                            >
                                                                Company Name is required
                                                            </span>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-md-6">
                                                    <div className="form-group">
                                                        <div className="label-part">
                                                            <div>Designator</div>
                                                            <i className="fa fa-question-circle" aria-hidden="true" />
                                                            <div
                                                                className="tooltip"
                                                                data-tippy-content="The state requires that a designator is included such as 'Inc.' in the company name, which will signify that the company is incorporated as a corporation."
                                                            ></div>
                                                        </div>
                                                        <div className="wrap-field">
                                                            <select value={designator} onChange={handleDesignatorChange} required>
                                                                <option value="" disabled>
                                                                    Select Designator
                                                                </option>
                                                                {designators.map((item) => (
                                                                    <option key={item.id} value={item.designator_name}>
                                                                        {item.designator_name}
                                                                    </option>
                                                                ))}
                                                            </select>

                                                            <span
                                                                className="help-block-error hide"
                                                                style={{ display: "none" }}
                                                            >
                                                                Company Name is required
                                                            </span>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            {companyName && designator && (
                                                <div className="company-namedisplay">
                                                    <label>Your official company name will display as</label>
                                                    <div className="final-name" id="final-name">
                                                        {`${companyName} ${designator}`}
                                                    </div>
                                                </div>
                                            )}
                                            <div className="button-nest-prev">
                                                <div className="left-next">
                                                    {/* Trigger the popup */}
                                                    <button onClick={handleBackClick}>
                                                        <i className="fa fa-arrow-left" aria-hidden="true" />
                                                        Back
                                                    </button>
                                                </div>
                                                <div className="right-next">
                                                    <button type="submit" disabled={isSubmitting}>
                                                        {isSubmitting ? 'Submitting...' : 'Next'}
                                                    </button>
                                                </div>
                                            </div>
                                        </form>
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-4">
                                <div className="register-right">
                                    <h4>Order Summary</h4>
                                    <div className="progress">
                                        <div
                                            className="progress-bar"
                                            role="progressbar"
                                            style={{ width: "25%" }}
                                            aria-valuenow={25}
                                            aria-valuemin={0}
                                            aria-valuemax={100}
                                        >
                                            25%
                                        </div>
                                    </div>
                                    <div className="order-items flex flex-col gap-3 mt-8">
                                        <div className="item">
                                            <div className="name">Entity Type</div>
                                            <div className="value">{apiData ? apiData.selected_entity : ''}</div>
                                        </div>
                                        <div className="item">
                                            <div className="name">{apiData ? apiData.package : ''} Package</div>
                                            <div className="value ">${apiData ? apiData.package_fee : ''}</div>
                                        </div>
                                        {additionalServices.map((service, index) => (
                                            <div className="item" key={index}>
                                                <div className="name">{service.name}</div>
                                                <div className="value">${service.price}</div>
                                            </div>
                                        ))}
                                        <div className="item">
                                            <div className="name">{apiData ? apiData.selected_state : ''} State Filing Fee</div>
                                            <div className="value">
                                                <span id="dynamic-filing-fee">${apiData ? apiData.stateFee : ''}</span>
                                            </div>
                                        </div>
                                        <div className="item hide" id="order-item-legal-consulation">
                                            <div className="name">Banking Resolution</div>
                                            <div className="value">
                                                <i className="fa fa-check" aria-hidden="true" />
                                            </div>
                                        </div>
                                        <div className="item hide" id="order-item-expedited">
                                            <div className="name">Banking Resolution</div>
                                            <div className="value">
                                                <i className="fa fa-check" aria-hidden="true" />
                                            </div>
                                        </div>
                                        <div className="item hide" id="order-item-legal-consulation">
                                            <div className="name">Minutes Meeting</div>
                                            <div className="value">
                                                <i className="fa fa-check" aria-hidden="true" />
                                            </div>
                                        </div>
                                        <div className="item hide" id="order-item-license-package">
                                            <div className="name">Bylaws</div>
                                            <div className="value">
                                                <i className="fa fa-check" aria-hidden="true" />
                                            </div>
                                        </div>
                                        <div className="item hide" id="order-item-va">
                                            <div className="name">EIN / Tax ID #</div>
                                            <div className="value">
                                                <i className="fa fa-check" aria-hidden="true" />
                                            </div>
                                        </div>
                                        <div className="item">
                                            <div className="name">Electronic Delivery</div>
                                            <div className="value">
                                                <i className="fa fa-check" aria-hidden="true" />
                                            </div>
                                        </div>
                                        <div className="item">
                                            <div className="name">Form 2553</div>
                                            <div className="value">
                                                <i className="fa fa-check" aria-hidden="true" />
                                            </div>
                                        </div>

                                        <div className="item">
                                            <div className="name flex flex-col">
                                                <span>Phone Support</span>
                                                <span className="text-xs text-gray-700 mt-[6px]">
                                                    Available Monday - Friday from 9 a.m. to 6 p.m. CST
                                                </span>
                                            </div>
                                            <div className="value">
                                                <i className="fa fa-check" aria-hidden="true" />
                                            </div>
                                        </div>
                                        <hr className="!mt-1 !mb-1" />
                                        <div className="totla-value1">
                                            <div className="item items-center mb-8">
                                                <div className="name">Total</div>
                                                <div className="value bold text-black">
                                                    <span className="SumPackageTotal text-2xl">
                                                        $<span className="totalPrice">{apiData ? apiData.form_total_price : ''}</span>
                                                    </span>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="trustpilot">
                                            <img src="images/Trustpilot-Reviews-Services-1.png" alt="" />
                                            <p>TrustScore 4.7 | 19,076 reviews</p>
                                        </div>
                                    
                                        <div className="join-business">
                                            <div className="font-radwave text-black">
                                                <h2 className="text-base tracking-wide text-left  my-0">
                                                    JOIN THE
                                                </h2>
                                                <p className="text-base text-red-500 tracking-wide ">
                                                    1,000,000+
                                                </p>
                                                <h3 className="text-base tracking-wide uppercase my-0">
                                                    Businesses
                                                </h3>
                                                <h6>
                                                    That have trusted Corpquick to start, manage, and grow their
                                                    business{" "}
                                                </h6>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>



            <Footer />
            {showConfirmOrder && <ConfirmOrder onClose={handleCloseConfirmOrder} />}
        </>
    )
}

export default CompanyInformation;
