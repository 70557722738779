import React from 'react';

const StarRating = ({ rating }) => {
  const fullStars = Math.floor(rating);
  const halfStar = rating % 1 !== 0;
  const emptyStars = 5 - fullStars - (halfStar ? 1 : 0);

  return (
    <div className="star-rating">
      {[...Array(fullStars)].map((_, index) => (
        <span key={index} className="star full">★</span>
      ))}
      {halfStar && <span className="star half">★</span>}
      {[...Array(emptyStars)].map((_, index) => (
        <span key={index} className="star empty">☆</span>
      ))}
    </div>
  );
};
const Testimonial = () => {
  const reviews = [
    {
      rating: 5,
      text: "CorpQuick.com has revolutionized our business processes. The platform is intuitive, and we’ve been able to streamline our workflow, saving time and increasing productivity. Excellent customer service, too!",
      author: "Jasmine Williams",
      company: "Digital Solutions Agency",
      state: "California"
    },
    {
      rating: 4,
      text: "CorpQuick.com is the ultimate tool for business operations. We have noticed major improvements in our project management and team collaboration. It’s user-friendly and efficient—worth every penny.",
      author: "Rajesh Kumar",
      company: "NextGen Consulting",
      state: "Texas"
    },
    {
      rating: 5,
      text: "The CorpQuick.com platform has been a game changer for our agency. It’s incredibly easy to use, and the features help us manage marketing campaigns seamlessly. We’ve seen a boost in efficiency and client satisfaction.",
      author: "Maria Lopez",
      company: "Bright Marketing Solutions",
      state: "Florida"
    },
    {
      rating: 4,
      text: "Overall, a solid tool for managing business operations. I’ve been impressed with how it has improved our project tracking. A couple more customization options for reporting would make this perfect.",
      author: "David Clark",
      company: "TechForce IT Solutions",
      state: "New York"
    },
    {
      rating: 5,
      text: "We’ve been using CorpQuick.com for a few months now, and it’s been a fantastic asset. The platform is straightforward, and customer support is always available when we need assistance. Highly recommend!",
      author: "Ayesha Khan",
      company: "Innovative Strategies Group",
      state: "Illinois"
    },
    {
      rating: 5,
      text: "CorpQuick.com has made managing our logistics operations so much easier. From scheduling to invoicing, everything is integrated seamlessly. It has saved us time and money!",
      author: "Carlos Rivera",
      company: "QuickShift Logistics",
      state: "Texas"
    },
    {
      rating: 5,
      text: "This platform has really helped us scale our marketing efforts. It’s simple, intuitive, and helps us track progress on all our campaigns in one place. CorpQuick.com is a must-have for any marketing team.",
      author: "Natasha Ivanova",
      company: "Horizon Marketing",
      state: "Georgia"
    },
    {
      rating: 4,
      text: "CorpQuick.com is an excellent tool for financial operations. It helped streamline our processes, though I would love to see more options for financial reporting and custom analysis.",
      author: "Brian O’Connor",
      company: "Summit Financial Services",
      state: "Pennsylvania"
    },
    {
      rating: 5,
      text: "I’ve been using CorpQuick.com for a few months, and it has made a huge difference. The ability to manage projects, track finances, and communicate with my team in one platform is amazing. Highly recommend!",
      author: "Priya Patel",
      company: "Consulting Experts",
      state: "New Jersey"
    },
    {
      rating: 5,
      text: "CorpQuick.com has drastically improved the way we operate as a business. We’re able to manage everything from HR to project management with ease. A very powerful and efficient tool.",
      author: "Marcus Thompson",
      company: "Enterprise Solutions Co.",
      state: "Ohio"
    },
    {
      rating: 4,
      text: "We’ve seen great results with CorpQuick.com in terms of project management. The tool is easy to use, but I wish there were more design-specific tools available. Overall, very happy with the service.",
      author: "Zoe Patel",
      company: "Creative Designs Studio",
      state: " Arizona"
    },
    {
      rating: 5,
      text: "CorpQuick.com has been an essential part of our tech startup. It helps us organize tasks, track time, and manage multiple projects simultaneously. Highly efficient and easy to navigate.",
      author: "Ahmed Al-Mansoori",
      company: "Tech Innovations LLC",
      state: "Michigan"
    },
    {
      rating: 4,
      text: "CorpQuick.com is great for team collaboration and project tracking. I’d love to see a more detailed task prioritization feature, but other than that, it’s a fantastic tool that has really boosted our team’s productivity.",
      author: "Tasha Green",
      company: "Green Business Consulting",
      state: "California"
    },
    {
      rating: 5,
      text: "CorpQuick.com has been a wonderful addition to our business. It’s easy to integrate with existing systems and helps us track everything in one place, making our day-to-day operations much more efficient.",
      author: "Omar Hassan",
      company: "Horizon Enterprises",
      state: "Texas"
    },
    {
      rating: 5,
      text: "CorpQuick.com has completely transformed how we manage our marketing efforts. We’re able to organize campaigns, track progress, and communicate with our team all from one platform. Couldn’t be happier!",
      author: "Laura Jensen",
      company: "Peak Marketing Solutions",
      state: "Oregon"
    },
    {
      rating: 5,
      text: "CorpQuick.com is the perfect solution for small businesses. It’s powerful yet easy to use, and it has really improved the way we manage projects. Customer support has been very responsive too.",
      author: "Kwame Boateng",
      company: "Boateng IT Solutions",
      state: "Georgia"
    },
    {
      rating: 5,
      text: "We’ve been using CorpQuick.com for months now, and it has made managing our design projects so much easier. The ability to collaborate with my team in real-time has been amazing. It would be great if they offered a mobile app for even easier access on the go!",
      author: "Camila Torres",
      company: "Torres Creative Designs",
      state: "Florida"
    },
    {
      rating: 5,
      text: "CorpQuick.com is hands-down one of the best tools we’ve used. It has helped us manage everything from finance to HR tasks efficiently. Highly recommend to any growing business.",
      author: "Ethan Brooks",
      company: "Brooks Consulting Group",
      state: "New York"
    },
    {
      rating: 4,
      text: "Great tool for managing a small business! It’s easy to use, and we’ve saved a lot of time automating tasks. A few minor bugs here and there, but overall, it’s a great experience.",
      author: "Sarah Kim",
      company: "Kim Solutions Group",
      state: "Virginia"
    },
    {
      rating: 5,
      text: "CorpQuick.com is a fantastic platform for business owners looking to optimize their workflow. The interface is intuitive, and everything we need is in one place. We’ve been able to scale efficiently thanks to their tools.",
      author: "Jamar Jackson",
      company: "Jackson Consulting Solutions",
      state: "Colorado"
    }
  ];

  return (
    <div>
      <div className="testimoial">
        <div class="heading-part">
          <h2>Client's Reviews</h2>
        </div>
        <div id="demo" className="carousel slide" data-bs-ride="carousel">

          <div className="carousel-inner">
          {reviews.map((review, index) => (
            <div className={`carousel-item ${index === 0 ? 'active' : ''}`} key={index}>
              <div className="review-part">
                <div className="client-content">
                <StarRating rating={review.rating} />
                  <p>
                  {review.text}
                  </p>
                  <h5>
                  <strong>{review.author}</strong>
                  </h5>
                  <h6>Company Name: {review.company}</h6>
                  <h6>State: {review.state}</h6>
                  <div className="testmonial-icon">
                    <img src="images/Group.png" alt="" />
                  </div>
                </div>
              </div>
            </div>
          ))}
          </div>
          <button
            className="carousel-control-prev"
            type="button"
            data-bs-target="#demo"
            data-bs-slide="prev"
          >
            <span className="carousel-control-prev-icon" />
          </button>
          <button
            className="carousel-control-next"
            type="button"
            data-bs-target="#demo"
            data-bs-slide="next"
          >
            <span className="carousel-control-next-icon" />
          </button>
        </div>
      </div>
    </div>

  )
}

export default Testimonial
