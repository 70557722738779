import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import NotFound from './404';
import Homepage from './Pages/Homepage/Homepage';
import Login from './Components/User/Login';
import SignUp from './Components/User/Register';
import ForgetPassword from './Components/User/ForgetPassword';
import Dashboard from './Components/User/Dashboard';
import FormOrder from './Components/FormOrder/FormOrder';
import StandardOrder from './Components/Order/FormPackage/CompanyInformation';
import StandardContactPerson  from './Components/Order/FormPackage/ContactPerson';
import StandardCompanyAddress from './Components/Order/FormPackage/CompanyAddressInformation';
import StandardMember from './Components/Order/FormPackage/Member'
import ProcessAgent from './Components/Order/FormPackage/ProcessAgent';
import StandardProcessSS4 from './Components/Order/FormPackage/ProcessSS';
import StandardSmallBanking from './Components/Order/FormPackage/SmallBanking';
import StandardTaxInvite from './Components/Order/FormPackage/TaxInvite';
import StandardBusinessLicense from './Components/Order/FormPackage/ProcessLicense';
import StandardOrderReview from './Components/Order/FormPackage/OrderReview';
import Payment from './Components/Order/FormPackage/Payment';
import ThankYou from './Components/Order/Thankyou'
import ScrollToTop from './Pages/ScrollToTop';
import RelayDisclaimer from './Components/Order/Disclamer';
import Privacy from './Pages/Privacy';
import Disclaimer from './Pages/Disclamer';
import About from './Pages/About';
import Services from './Pages/Services';
import Affiliates from './Pages/Affiliates';
import Cancellation from './Pages/Cancellation';
import Contact from './Pages/Conatct'
import ResetPassword from './Components/User/ResetPassword';

function App() {
  return (
    <Router>
      <ScrollToTop/>
          <Routes>
            <Route exact path='/' element={<Homepage />} />
            <Route exact path='/login' element={<Login />} />
            <Route exact path='/sign-up' element={<SignUp />} />
            <Route exact path='/forgot-password' element={<ForgetPassword />} />
            <Route exact path='/dashboard' element={<Dashboard />} />
            <Route exact path='/form-order' element={<FormOrder/>} />
            <Route exact path='/process-coinfo' element={<StandardOrder/>} />
            <Route exact path='/process-contact-person' element={<StandardContactPerson />} />
            <Route exact path='/process-company-address' element={<StandardCompanyAddress/>} />
            <Route exact path='/process-agent' element={<ProcessAgent/>} />
            <Route exact path='/process-member' element={<StandardMember/>} />
            <Route exact path='/process-ss4' element={<StandardProcessSS4/>} />
            <Route exact path='/small-banking' element={<StandardSmallBanking/>} />
            <Route exact path='/tax-invite' element={<StandardTaxInvite/>} />
            <Route exact path='/process-licenses' element={<StandardBusinessLicense/>} />
            <Route exact path='/relay-disclosure' element={<RelayDisclaimer/>} />
            <Route exact path='/order-review' element={<StandardOrderReview/>} />
            <Route exact path='/payment' element={<Payment/>} />
            <Route exact path='/thank-you' element={<ThankYou/>} />
            <Route exact path='/about-us' element={<About/>} />
            <Route exact path='/services' element={<Services/>} />
            <Route exact path='/cancellation-policy' element={<Cancellation/>} />
            <Route exact path='/affiliates' element={<Affiliates/>} />
            <Route exact path='/contact-us' element={<Contact/>} />
            <Route exact path='/disclamer' element={<Disclaimer/>} />
            <Route exact path='/privacy' element={<Privacy/>} />
            <Route exact path='/reset-password' element={<ResetPassword/>} />
          
            <Route path="*" element={<NotFound />} />
          </Routes>
    </Router>
  );
}

export default App;
