import React from 'react';
import Header from '../Common/Header';
import Footer from '../Common/Footer';

const Disclaimer = () => {
  return (
    <>
    <Header />
    <div style={{ padding: '12%', textAlign: 'left', fontFamily: 'Arial, sans-serif', color: '#444' }}>
      <h2 style={{ marginBottom: '10px', fontSize: '1.5rem', fontWeight: 'bold' }}>Disclaimer</h2>
      <p style={{ lineHeight: '1.6', fontSize: '1rem' }}>
        Relay is a financial technology company, not an FDIC-insured bank. Banking services are provided by 
        <strong> Thread Bank </strong> and <strong>Evolve Bank & Trust</strong>; Members of FDIC. The Relay Visa® Debit Card is 
        issued by Thread Bank pursuant to a license from Visa U.S.A. Inc. and may be used everywhere Visa debit cards 
        are accepted.
      </p>
    </div>
    <Footer/>
    </>
  );
};

export default Disclaimer;
