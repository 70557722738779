import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import Header from '../../Common/Header';
import Footer from '../../Common/Footer';
import { useNavigate } from 'react-router-dom';
import config from '../../../config';
import tippy from 'tippy.js';
import 'tippy.js/dist/tippy.css';

const Processmember = () => {
  const [formOrderData, setFormOrderData] = useState(null);
  const [numMembers, setNumMembers] = useState(0);
  const [errors, setErrors] = useState({});
  const [apiData, setApiData] = useState(null);
  const [processMembers, setProcessMembers] = useState([]);
  const [stateNames, setStateNames] = useState([]);
  const [selectedState, setSelectedState] = useState('');
  const [isEditing, setIsEditing] = useState([]);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const navigate = useNavigate();
  const resultData = localStorage.getItem('formOrderResult');
  const [additionalServices, setAdditionalServices] = useState([]);

  let extractedId = null;
  if (resultData) {
    const parsedResult = JSON.parse(resultData);
    extractedId = parsedResult.data.id;
  }

  useEffect(() => {
    if (extractedId) {
      fetch(`${config.baseUrl}/registration/get-form-1/${extractedId}`)
        .then(response => response.json())
        .then(data => {
          const members = data.data.process_members || [];
          setApiData(data.data);
          setNumMembers(members.length);
          if (data.data.additional_services) {
            const parsedServices = JSON.parse(data.data.additional_services);
            setAdditionalServices(parsedServices);
          }
          setProcessMembers(
            members.map(member => ({
              id: member.id || '',  // Ensure id is included for each member
              processMemberIndiviualCompany: member.processMemberIndiviualCompany || 'individual', // Default to 'individual'
              type: member.processMemberIndiviualCompany || 'individual', // Default type for display
              processMemberCompanyName: member.processMemberCompanyName || '',
              processMemberFirstName: member.processMemberFirstName || '',
              processMemberLastName: member.processMemberLastName || '',
              companyName: member.companyName || '',
              useCompanyAddress: member.useCompanyAddress || false,
              processMemberPercentageOwnership: member.processMemberPercentageOwnership || '',
              processMemberStreetAddress: member.processMemberStreetAddress || '',
              processMemberCity: member.processMemberCity || '',
              processMemberState: member.processMemberState || '',
              processMemberZipCode: member.processMemberZipCode || ''
            }))

          );
          setIsEditing(new Array(members.length).fill(false));
        })
        .catch(error => console.error('Error fetching data:', error));
    }
  }, [extractedId]);


  const handleNumMembersChange = (e) => {
    const newNumMembers = parseInt(e.target.value, 10);
    setNumMembers(newNumMembers);

    if (newNumMembers > 0) {
      // Create a new array with the existing members and fill the rest with default values
      const updatedMembers = [...processMembers];
      while (updatedMembers.length < newNumMembers) {
        updatedMembers.push({
          processMemberIndiviualCompany: 'individual',
          processMemberCompanyName: '',
          processMemberFirstName: '',
          processMemberLastName: '',
          companyName: '',
          useCompanyAddress: false,
          processMemberPercentageOwnership: '',
          processMemberStreetAddress: '',
          processMemberCity: '',
          processMemberState: '',
          processMemberZipCode: ''
        });
      }
      // Trim the array if the new number of members is less than the current number of members
      if (updatedMembers.length > newNumMembers) {
        updatedMembers.length = newNumMembers;
      }
      setProcessMembers(updatedMembers);
      setIsEditing(new Array(newNumMembers).fill(false));
      setErrors(prevErrors => ({ ...prevErrors, general: '' })); // Clear the "Number of Members is required" error
    } else {
      setProcessMembers([]);
      setIsEditing([]);
      setErrors(prevErrors => ({ ...prevErrors, general: 'Number of Members is required' }));
    }
  };


  const handleMemberChange = (index, field, value) => {
    const updatedMembers = [...processMembers];
    updatedMembers[index] = { ...updatedMembers[index], [field]: value };
    setProcessMembers(updatedMembers);
  };
  useEffect(() => {

    fetch(`${config.baseUrl}/state/list`)
      .then(response => response.json())
      .then(data => {
        if (data.status === 'success') {
          setStateNames(data.data);
        } else {
          console.error('Failed to fetch state names');
        }
      })
      .catch(error => {
        console.error('Error fetching state names:', error);
      });
  }, []);
  useEffect(() => {
    if (apiData) {
      setFormOrderData({
        ...formOrderData,
        companyInformationState: apiData.companyAddressState || ''
      });
      setSelectedState(apiData.companyAddressState || '');
    }
  }, [apiData]);

  const handleMemberTypeChange = (index, type) => {
    const updatedMembers = [...processMembers];
    updatedMembers[index] = {
      ...updatedMembers[index],
      processMemberIndiviualCompany: type, // Set the field based on the selected type
      type, // Update the display type field as well
    };
    setProcessMembers(updatedMembers);
  };
  const validateFields = () => {
    const newErrors = {};

    processMembers.forEach((member, index) => {
      const memberErrors = {};
      if (member.processMemberIndiviualCompany !== 'company') {
        if (!member.processMemberFirstName?.trim()) {
          memberErrors.processMemberFirstName = 'First Name is required';
        }
        if (!member.processMemberLastName?.trim()) {
          memberErrors.processMemberLastName = 'Last Name is required';
        }
      }
      if (member.processMemberIndiviualCompany === 'company' && !member.processMemberCompanyName?.trim()) {
        memberErrors.processMemberCompanyName = 'Company Name is required';
      }
      if (!member.processMemberStreetAddress?.trim()) {
        memberErrors.processMemberStreetAddress = 'Street Address is required';
      }
      if (!member.processMemberCity?.trim()) {
        memberErrors.processMemberCity = 'City is required';
      }
      if (!member.processMemberState?.trim()) {
        memberErrors.processMemberState = 'State is required';
      }
      if (!member.processMemberZipCode?.trim()) {
        memberErrors.processMemberZipCode = 'Zip Code is required';
      }

      if (Object.keys(memberErrors).length > 0) {
        newErrors[index] = memberErrors;
      }
    });

    setErrors(newErrors);

    return Object.keys(newErrors).length === 0;
  };

  const handleClick = (e, index) => {
    const updatedMembers = [...processMembers];
    updatedMembers[index] = { ...updatedMembers[index], useCompanyAddress: e.target.checked };
    setProcessMembers(updatedMembers);
  };

  const toggleEdit = (index) => {
    const updatedEditingState = [...isEditing];
    updatedEditingState[index] = !updatedEditingState[index];
    setIsEditing(updatedEditingState);
  };

  const handleSaveAndNext = async (e) => {
    e.preventDefault();
    setIsSubmitting(true);

    if (numMembers === 0) {
      setErrors({ general: 'Number of Members is required' });
      setIsSubmitting(false);
      return;
    }

    if (validateFields()) {
      const payload = {
        processMembers: processMembers.map((member) => ({
          processMemberPersonCompany: member.processMemberPersonCompany || '',
          processMemberIndiviualCompany: member.processMemberIndiviualCompany || '',
          processMemberCompanyName: member.processMemberCompanyName || '',
          processMemberFirstName: member.processMemberFirstName || '',
          processMemberLastName: member.processMemberLastName || '',
          processMemberStreetAddress: member.processMemberStreetAddress || '',
          processMemberContAddress: member.processMemberContAddress || '',
          processMemberCity: member.processMemberCity || '',
          processMemberState: member.processMemberState || '',
          processMemberZipCode: member.processMemberZipCode || '',
          processMemberPercentageOwnership: member.processMemberPercentageOwnership || 0,
        })),
      };

      try {
        const response = await fetch(`${config.baseUrl}/registration/form-6/${extractedId}`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify(payload),
        });

        if (response.ok) {
          const data = await response.json();
          console.log('Form submission successful:', data);
          navigate("/process-agent");
        } else {
          console.error('Error in form submission:', response.statusText);
        }
      } catch (error) {
        console.error('Error:', error);
      } finally {
        setIsSubmitting(false);
      }
    }
  };
  useEffect(() => {
    tippy('.tooltip', {
      allowHTML: true, // Enable HTML content
      content: `
      
      `,
      placement: 'top', // Adjust tooltip placement as needed
    });
  }, []);


  return (
    <>
      <Header />
      <section className="registration-from">
        <div className="container">
          <div className="registerformstart">
            <div className="row">
              <div className="col-md-8">
                <div className="register-left">
                  <div className="box__heading1 please-provide">
                    Please provide Directors Information for <strong>{apiData ? apiData.full_business_name : ''}</strong>
                  </div>
                  <div className="form-group">
                    <div className="label-part custon-label-part">
                      <div>Number of Members/Owners</div>
                      <i className="fa fa-exclamation-circle" aria-hidden="true"></i>
                      <div
                        className="tooltip"
                        data-tippy-content="  <h4>What is a Member/Owner?</h4> 
                           The owners of an LLC are referred to as members, and only one member is required to form an LLC.
                           Whereas a corporation has shareholders, directors, and officers, an LLC has only members,
                           although the members may adopt any titles or positions they see fit, and they may also hire
                           nonmembers to fill any such titles or positions. The members are the owners of the LLC and have
                           the ultimate authority to make any and all decisions (legal, financial, operational, contractual, etc.)
                           regarding the LLC."
                      ></div>
                    </div>
                    <div className="wrap-field">
                      <select id="numDivs" value={numMembers} onChange={handleNumMembersChange}>
                        <option value={0}>Select Number of Members</option>
                        {[...Array(6).keys()].map(i => (
                          <option key={i + 1} value={i + 1}>{i + 1}</option>
                        ))}
                      </select>
                      {errors.general && (
                        <div className="errorMsg">
                          <img src={`${process.env.PUBLIC_URL}/images/warning-circle.svg`} alt="Warning Circle" />
                          {errors.general}
                        </div>
                      )}
                    </div>
                  </div>
                  <hr />
                  {/* Render Member Sections */}
                  {Array.from({ length: numMembers }).map((_, index) => (
                    <div key={index} className="form-group member-info">
                      <div className="member-label number-member">
                        <div className='number-div'>
                          Member {index + 1}
                        </div>
                        {index !== 0 && numMembers >= 2 && (
                          <button type="button" onClick={() => toggleEdit(index)} className="edit-button">
                            Edit
                          </button>
                        )}
                      </div>

                      {/* Always display Member 1 */}
                      {index === 0 || isEditing[index] ? (
                        <div>
                          <div className="custum-radio1">
                            <div className="custom_radio">
                              <div>
                                <input
                                  type="radio"
                                  id={`selectmember1${index}`}
                                  name={`selectmember${index}`}
                                  className="chngRadio"
                                  value="individual"
                                  checked={processMembers[index]?.processMemberIndiviualCompany === 'individual'}
                                  onChange={() => handleMemberTypeChange(index, 'individual')}
                                />
                                <label htmlFor={`selectmember1${index}`}>
                                  <span>Individual</span>
                                  <span>Select if Member is a person.</span>
                                </label>
                              </div>
                              <div>
                                <input
                                  type="radio"
                                  id={`selectmember2${index}`}
                                  name={`selectmember${index}`}
                                  className="chngRadio"
                                  value="company"
                                  checked={processMembers[index]?.processMemberIndiviualCompany === 'company'}
                                  onChange={() => handleMemberTypeChange(index, 'company')}
                                />
                                <label htmlFor={`selectmember2${index}`}>
                                  <span>Company</span>
                                  <span>Select if Member is a company.</span>
                                </label>
                              </div>
                            </div>
                          </div>

                          {/* Member Form Fields for 'individual' or 'company' */}
                          {processMembers[index]?.processMemberIndiviualCompany === 'individual' ? (
                            <>
                              <div className="twoBox">
                                <div className="form-group address-group">
                                  <label htmlFor={`processMemberFirstName${index}`}>First Name:</label>
                                  <input
                                    type="text"
                                    className="form-control"
                                    id={`processMemberFirstName${index}`}
                                    placeholder="First Name"
                                    value={processMembers[index]?.processMemberFirstName || ''}
                                    onChange={(e) => handleMemberChange(index, 'processMemberFirstName', e.target.value)}
                                  />
                                  {errors[index]?.processMemberFirstName && (
                                    <div className="errorMsg">{errors[index]?.processMemberFirstName}</div>
                                  )}
                                </div>
                                <div className="form-group address-group">
                                  <label htmlFor={`processMemberLastName${index}`}>Last Name:</label>
                                  <input
                                    type="text"
                                    className="form-control"
                                    id={`processMemberLastName${index}`}
                                    placeholder="Last Name"
                                    value={processMembers[index]?.processMemberLastName || ''}
                                    onChange={(e) => handleMemberChange(index, 'processMemberLastName', e.target.value)}
                                  />
                                  {errors[index]?.processMemberLastName && (
                                    <div className="errorMsg">{errors[index]?.processMemberLastName}</div>
                                  )}
                                </div>
                              </div>
                            </>
                          ) : (
                            <>
                              <div className='company1'>
                                <label htmlFor={`companyName${index}`}>Company Name:</label>
                                <input
                                  type="text"
                                  className="form-control"
                                  id={`processMemberCompanyName${index}`}
                                  value={processMembers[index]?.processMemberCompanyName || ''}
                                  onChange={(e) => handleMemberChange(index, 'processMemberCompanyName', e.target.value)}
                                />
                                {errors[index]?.processMemberCompanyName && (
                                  <div className="errorMsg">{errors[index]?.processMemberCompanyName}</div>
                                )}
                              </div>
                            </>
                          )}
                          {!processMembers[index]?.useCompanyAddress && (
                            <div className="twoBox">
                              <div className="form-group address-group">
                                <label htmlFor={`streetAddress${index}`}>Street Address:</label>
                                <input
                                  type="text"
                                  className="form-control"
                                  id={`processMemberStreetAddress${index}`}
                                  placeholder="Street Address"
                                  value={processMembers[index]?.processMemberStreetAddress || ''}
                                  onChange={(e) => handleMemberChange(index, 'processMemberStreetAddress', e.target.value)}
                                />
                                {errors[index]?.processMemberStreetAddress && (
                                  <div className="errorMsg">{errors[index]?.processMemberStreetAddress}</div>
                                )}
                              </div>

                              <div className="">
                                <label htmlFor={`processMemberCity${index}`}>City:</label>
                                <input
                                  type="text"
                                  className="form-control"
                                  id={`processMemberCity${index}`}
                                  placeholder="City"
                                  value={processMembers[index]?.processMemberCity || ''}
                                  onChange={(e) => handleMemberChange(index, 'processMemberCity', e.target.value)}
                                />
                                {errors[index]?.processMemberCity && (
                                  <div className="errorMsg">{errors[index]?.processMemberCity}</div>
                                )}
                              </div>
                              <div className="form-group state-group">

                                <label htmlFor={`processMemberState${index}`}>State:</label>
                                <select
                                  className="form-select"
                                  id={`processMemberState${index}`}
                                  value={processMembers[index]?.processMemberState || ''}
                                  onChange={(e) => handleMemberChange(index, 'processMemberState', e.target.value)}
                                >
                                  <option value="" disabled>Choose State of Formation</option>
                                  {stateNames.map((state) => (
                                    <option key={state.id} value={state.state_name}>
                                      {state.state_name}
                                    </option>
                                  ))}
                                </select>

                                {errors[index]?.processMemberState && (
                                  <div className="errorMsg">{errors[index]?.processMemberState}</div>
                                )}
                              </div>
                              <div className="form-group zip-group">
                                <label htmlFor={`zip${index}`}>Zip Code:</label>
                                <input
                                  type="number"
                                  className="form-control"
                                  id={`processMemberZipCode${index}`}
                                  placeholder="Zip Code"
                                  value={processMembers[index]?.processMemberZipCode || ''}
                                  onChange={(e) => handleMemberChange(index, 'processMemberZipCode', e.target.value)}
                                  onInput={(e) => e.target.value = e.target.value.replace(/[^0-9]/g, '')}
                                  minLength={5}
                                  maxLength={9} // Only allow numbers
                                />

                                {errors[index]?.processMemberZipCode && (
                                  <div className="errorMsg">{errors[index]?.processMemberZipCode}</div>
                                )}
                              </div>
                            </div>
                          )}
                          {processMembers[index]?.useCompanyAddress && (
                            <div className="moreInfo">
                              <p><strong>You have selected for Corpquick to provide you with a company address</strong></p>
                              <p><small>We will use the company address for the member information in order to shield your personal information from being publicly available.</small></p>
                            </div>
                          )}
                        </div>
                      ) : null}
                      {index !== 0 && isEditing[index] && (
                        <div className='ownership'>
                          <div className={`form-group ownershipPercentage${index}`}>
                            <label>% of Ownership:</label>
                            <select
                              className="form-control"
                              value={processMembers[index]?.processMemberPercentageOwnership || ''}
                              onChange={(e) => handleMemberChange(index, 'processMemberPercentageOwnership', e.target.value)}
                            >
                              <option value="" disabled>% of Ownership</option>
                              <option value="5">5%</option>
                              <option value="10">10%</option>
                              <option value="15">15%</option>
                              <option value="20">20%</option>
                              <option value="25">25%</option>
                              <option value="30">30%</option>
                              <option value="35">35%</option>
                              <option value="40">40%</option>
                              <option value="45">45%</option>
                              <option value="50">50%</option>
                              <option value="55">55%</option>
                              <option value="60">60%</option>
                              <option value="65">65%</option>
                              <option value="70">70%</option>
                              <option value="75">75%</option>
                              <option value="80">80%</option>
                              <option value="85">85%</option>
                              <option value="90">90%</option>
                              <option value="95">95%</option>

                            </select>
                          </div>
                        </div>
                      )}
                    </div>

                  ))}
                </div>
                <div className="button-nest-prev">
                  <div className="left-next">
                    <Link to="/process-company-address">
                      <button type="button">
                        <i className="fa fa-arrow-left" aria-hidden="true"></i> Back
                      </button>
                    </Link>
                  </div>
                  <div className="right-next">
                    <button
                      type="button"
                      className="btn btn-primary"
                      onClick={handleSaveAndNext}
                    >
                      Next
                    </button>

                  </div>
                </div>
              </div>
              <div className="col-md-4">
                <div className="register-right">
                  <h4>Order Summary</h4>
                  <h2>{apiData ? apiData.full_business_name : ''}</h2>
                  <div className="progress">
                    <div
                      className="progress-bar"
                      role="progressbar"
                      style={{ width: "40%" }}
                      aria-valuenow={40}
                      aria-valuemin={0}
                      aria-valuemax={100}
                    >
                      40%
                    </div>
                  </div>
                  <div className="order-items flex flex-col gap-3 mt-8">
                    <div className="item">
                      <div className="name">{apiData ? apiData.package : ''} Package</div>
                      <div className="value ">${apiData ? apiData.package_fee : ''}</div>
                    </div>
                    {additionalServices.map((service, index) => (
                      <div className="item" key={index}>
                        <div className="name">{service.name}</div>
                        <div className="value">${service.price}</div>
                      </div>
                    ))}
                    <div className="item">
                      <div className="name">{apiData ? apiData.selected_state : ''} State Filing Fee</div>
                      <div className="value">
                        <span id="dynamic-filing-fee">${apiData ? apiData.stateFee : ''}</span>
                      </div>
                    </div>
                    {apiData?.companyAddress === "Professional Business Address" && (
                      <div className="item" id="order-item-legal-consulation">
                        <div className="name">Business Address</div>
                        <div className="value">
                          $<span id="dynamic-filing-fee">29</span>
                        </div>
                      </div>
                    )}
                    <div className="item hide" id="order-item-legal-consulation">
                      <div className="name">Banking Resolution</div>
                      <div className="value">
                        <i className="fa fa-check" aria-hidden="true" />
                      </div>
                    </div>
                    <div className="item hide" id="order-item-expedited">
                      <div className="name">Banking Resolution</div>
                      <div className="value">
                        <i className="fa fa-check" aria-hidden="true" />
                      </div>
                    </div>
                    <div className="item hide" id="order-item-legal-consulation">
                      <div className="name">Minutes Meeting</div>
                      <div className="value">
                        <i className="fa fa-check" aria-hidden="true" />
                      </div>
                    </div>
                    <div className="item hide" id="order-item-license-package">
                      <div className="name">Bylaws</div>
                      <div className="value">
                        <i className="fa fa-check" aria-hidden="true" />
                      </div>
                    </div>
                    <div className="item hide" id="order-item-va">
                      <div className="name">EIN / Tax ID #</div>
                      <div className="value">
                        <i className="fa fa-check" aria-hidden="true" />
                      </div>
                    </div>
                    <div className="item">
                      <div className="name">Electronic Delivery</div>
                      <div className="value">
                        <i className="fa fa-check" aria-hidden="true" />
                      </div>
                    </div>
                    <div className="item">
                      <div className="name">Form 2553</div>
                      <div className="value">
                        <i className="fa fa-check" aria-hidden="true" />
                      </div>
                    </div>

                    <div className="item">
                      <div className="name flex flex-col">
                        <span>Phone Support</span>
                        <span className="text-xs text-gray-700 mt-[6px]">
                          Available Monday - Friday from 9 a.m. to 6 p.m. CST
                        </span>
                      </div>
                      <div className="value">
                        <i className="fa fa-check" aria-hidden="true" />
                      </div>
                    </div>
                    <hr className="!mt-1 !mb-1" />
                    <div className="totla-value1">
                      <div className="item items-center mb-8">
                        <div className="name">Total</div>
                        <div className="value bold text-black">
                          <span className="SumPackageTotal text-2xl">
                            $<span className="totalPrice">
                              {apiData
                                ? parseFloat(apiData.form_total_price || 0) +
                                (apiData.companyAddress === "Professional Business Address" ? 29 : 0)
                                : ''}
                            </span>
                          </span>
                        </div>
                      </div>
                    </div>
                    <div className="trustpilot">
                      <img src="images/Trustpilot-Reviews-Services-1.png" alt="" />
                      <p>TrustScore 4.7 | 19,076 reviews</p>
                    </div>

                    <div className="join-business">
                      <div className="font-radwave text-black">
                        <h2 className="text-base tracking-wide text-left  my-0">
                          JOIN THE
                        </h2>
                        <p className="text-base text-red-500 tracking-wide ">
                          1,000,000+
                        </p>
                        <h3 className="text-base tracking-wide uppercase my-0">
                          Businesses
                        </h3>
                        <h6>
                          That have trusted Corpquick to start, manage, and grow their
                          business{" "}
                        </h6>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <Footer />
    </>
  );
};
export default Processmember;
