import React, { useState } from 'react';
import InnerHeader from '../Common/Header';
import Footer from '../Common/Footer';
import {Link} from 'react-router-dom'
import { useNavigate } from 'react-router-dom';
import config from '../../config';


const Register = () => {
  // Define state variables to hold user input
  const [first_name, setFirstName] = useState('');
  const [last_name, setLastName] = useState('');
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [confirm_password, setConfirmPassword] = useState('');
  const navigate =useNavigate()
  const [error, setError] = useState('');

  const handleFormSubmit = async (e) => {
    e.preventDefault();
    if (password.length < 8) {
      setError('Password must be at least 8 characters long');
      return;
    }
    if (password !== confirm_password) {
      setError('Password and Confirm Password does not match');
      return;
    }
  
    // Create a data object to send in the request body
    const data = {
      first_name,
      last_name,
      email,
      password,
      confirm_password: confirm_password,
    };
  
    try {
      // Send a POST request to the backend API
      const response = await fetch(`${config.baseUrl}/register`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(data),
      });
  
      if (response.ok) {
        navigate('/login');
      } else {
        // Registration failed - handle the error scenario here
        const errorData = await response.json(); // Parse error response JSON
        if (errorData && errorData.msg === 'This email already exists') {
          setError('This email is already in use');
        } else {
          setError('This email is already in use');
        }
        
      }
    } catch (error) {
      setError('This email is already in use', error);
    }
  };
  

  return (
    <>
      <InnerHeader />
      <section className="login-part">
        <div className="container">
          <div className="row">
            <div className="col-md-6">
              <div className="signup-form">
                <h2>
                  Sign in to <span>CORPQuick</span>
                </h2>
                <form onSubmit={handleFormSubmit}>
                  <input
                  type="text"
                  placeholder="First Name"
                  maxLength={15}
                  required
                  value={first_name}
                  onChange={(e) => setFirstName(e.target.value)}
                  onInvalid={(e) => e.target.setCustomValidity('Please enter your first name')}
                  onInput={(e) => e.target.setCustomValidity('')} // Reset custom validity on input
                />
                    <input
                  type="text"
                  placeholder="Last Name"
                  required
                  maxLength={15}
                  value={last_name}
                  onChange={(e) => setLastName(e.target.value)}
                  onInvalid={(e) => e.target.setCustomValidity('Please enter your last name')}
                  onInput={(e) => e.target.setCustomValidity('')} // Reset custom validity on input
                />
                <input
                  type="email"
                  className="form-control"
                  placeholder="Enter Mail"
                  required
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  onInvalid={(e) => e.target.setCustomValidity('Please enter a valid email address')}
                  onInput={(e) => e.target.setCustomValidity('')} // Reset custom validity on input
                />
                 <input
                  type="password"
                  className="form-control"
                  placeholder="Password"
                  required
                  value={password}
                  maxLength={20}
                  onChange={(e) => setPassword(e.target.value)}
                  onInvalid={(e) => e.target.setCustomValidity('Please enter your password')}
                  onInput={(e) => e.target.setCustomValidity('')} // Reset custom validity on input
                />
                <input
                  type="password"
                  className="form-control"
                  placeholder="Confirm Password"
                  required
                  maxLength={20}
                  value={confirm_password}
                  onChange={(e) => setConfirmPassword(e.target.value)}
                  onInvalid={(e) => e.target.setCustomValidity('Please confirm your password')}
                  onInput={(e) => e.target.setCustomValidity('')} // Reset custom validity on input
                />
                  <input type="submit" value="Submit" />
                  {error && <p style={{ color: 'red' }}>{error}</p>}
                  <div className='sign-up'>
                  <p>
                    <Link to="/login">Sign In</Link>
                  </p>
                  <p>
                    <Link to="/forgot-password">Forget Password</Link>
                  </p>
                  
                  </div>
                </form>
              </div>
            </div>
          </div>
          <div className="col-md-6"></div>
        </div>
      </section>
      <Footer />
    </>
  );
};

export default Register;
