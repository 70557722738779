import React from 'react';
import Header from '../Components/Common/Header';
import Footer from '../Components/Common/Footer';

const Disclaimer = () => {
  return (
    <>
      <Header />
      <div className='common-banenr'>
        <div className='common-content'>
          <h1>Relay<span> Disclaimer</span></h1>
        </div>
      </div>
      <div style={{ padding: '50px 0px ', textAlign: 'left', fontFamily: 'Arial, sans-serif', color: '#444' }}>
        <div className='container'>
          <h2 style={{ marginBottom: '10px', fontSize: '1.5rem', fontWeight: 'bold' }}>Disclaimer</h2>
          <p style={{ lineHeight: '1.6', fontSize: '1rem' }}>
            Relay is a financial technology company, not an FDIC-insured bank. Banking services are provided by
            <strong> Thread Bank </strong> and <strong>Evolve Bank & Trust</strong>; Members of FDIC. The Relay Visa® Debit Card is
            issued by Thread Bank pursuant to a license from Visa U.S.A. Inc. and may be used everywhere Visa debit cards
            are accepted.
          </p>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default Disclaimer;
