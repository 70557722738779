import React, { useEffect, useState } from 'react';
import Header from '../../Common/Header';
import { useNavigate } from 'react-router-dom';
import Footer from '../../Common/Footer';
import { Link } from 'react-router-dom';
import config from '../../../config';
import '../registerstyle.css'


const OrderReview = () => {
    const [formOrderData, setFormOrderData] = useState(null);
    const [apiData, setApiData] = useState(null);
    const [registeredAgentInformation, setRegisteredAgentInformation] = useState("");
    const resultData = localStorage.getItem('formOrderResult');
    const [taxConsultation, setTaxConsultation] = useState(false);
      const [additionalServices, setAdditionalServices] = useState([]); 
    let extractedId = null;
    const navigate = useNavigate()

    if (resultData) {
        const parsedResult = JSON.parse(resultData);
        extractedId = parsedResult.data.id;
        console.log('Extracted ID:', extractedId);
    }

    // Load the selected tax option from localStorage when the component mounts
    useEffect(() => {

        const fetchData = async () => {
            try {
                const response = await fetch(`${config.baseUrl}/registration/get-form-1/${extractedId}`);
                if (response.ok) {
                    const data = await response.json();
                    setApiData(data.data);
                    if (data.data.taxStrategyYesOrNo === "Business Tax Consultation Free") {
                        setTaxConsultation(true);
                    }
                    if (data.data.additional_services) {
                        const parsedServices = JSON.parse(data.data.additional_services);
                        setAdditionalServices(parsedServices);
                    }
                    if (data.data.registeredAgentInformation === "free") {
                        setRegisteredAgentInformation(true);
                    }


                } else {
                    console.error('Error fetching data:', response.statusText);
                }
            } catch (error) {
                console.error('Error:', error);
            }
        };

        fetchData();
    }, [extractedId]);
    const nextPage = () => {
        navigate("/payment")
    }
    console.log(apiData)

    return (
        <>
            <Header />
            <section className="registration-from">
                <div className="container">
                    <div className="registerformstart">
                        <div className="row">
                            <div className="col-md-8">
                                <div className="register-left">
                                    <div className="process-review">
                                        <div className="box__heading1">Order Summary for:</div>
                                        <div className="bg-accent-aqua-0">{apiData ? apiData.full_business_name : ''}</div>

                                        <div className="review-box">
                                            <div className="review-info">
                                                <div className='formation1'>Formation Info</div>
                                                <Link to={`/form-order?entity=${apiData ? apiData.selectedEntityId : ''}&state=${apiData ? apiData.selectedStateId : ''}`}>Edit</Link>
                                            </div>
                                            <div className="formation-info">
                                                <div>
                                                    <div className="label-part">State of Formation</div>
                                                    <div className="text-part">{apiData ? apiData.selected_state : ''}</div>
                                                </div>
                                                <div>
                                                    <div className="label-part">Entity Type</div>
                                                    <div className="text-part">{apiData ? apiData.selected_entity : ''}</div>
                                                </div>
                                            </div>
                                            <div className="formation-info">
                                                <div>
                                                    <div className="label-part">Phone Number</div>
                                                    <div className="text-part">+{apiData ? apiData.contact_person_phone_number : ''}</div>
                                                </div>
                                                <div>
                                                    <div className="label-part">Email Address</div>
                                                    <div className="text-part">{apiData ? apiData.contact_person_email : ''}</div>
                                                </div>
                                            </div>
                                            <div className="formation-info">

                                                <div>
                                                    <div className="label-part">Filing Days</div>
                                                    <div className="text-part">{apiData ? apiData.select_filling_time : ''}</div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="review-box">
                                            <div className="review-info">
                                                <div className='formation1'>Contact Info</div>
                                                <Link to={`/process-contact-person`}>Edit</Link>

                                            </div>
                                            <div className="formation-info">
                                                <div>
                                                    <div className="label-part">Full Name</div>
                                                    <div className="text-part">{apiData ? apiData.contact_person_first_name : ''} {apiData ? apiData.contact_person_last_name : ''}</div>
                                                </div>
                                                <div>
                                                    <div className="label-part">Address</div>
                                                    <div className="text-part">{apiData ? apiData.companyAddressStreetAddress : ''},{apiData ? apiData.companyAddressCity : ''},{apiData ? apiData.companyAddressState : ''},{apiData ? apiData.companyAddressZipCode : ''}</div>
                                                </div>
                                            </div>
                                            <div className="formation-info">
                                                <div>
                                                    <div className="label-part">Phone Number</div>
                                                    <div className="text-part">+{apiData ? apiData.contact_person_phone_number : ''}</div>
                                                </div>
                                                <div>
                                                    <div className="label-part">Email Address</div>
                                                    <div className="text-part">{apiData ? apiData.contact_person_email : ''}</div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="review-box">
                                            <div className="review-info">
                                                <div className='formation1'>Agent Info</div>
                                                <Link to={`/process-agent`}>Edit</Link>
                                            </div>
                                            <div className="formation-info1">
                                                <p>
                                                    You have selected Corpquick to provide you with a <strong>{apiData ? apiData.registeredAgentInformation : ''}.</strong>
                                                </p>
                                            </div>
                                        </div>
                                        <div className="review-box">
                                            <div className="review-info">
                                                <div className='formation1'>Company Info</div>
                                                <Link to={`/process-coinfo`}>Edit</Link>
                                            </div>
                                            <div className="formation-info">
                                                <div>
                                                    <div className="label-part">Name</div>
                                                    <div className="text-part">{apiData ? apiData.business_name : ''}</div>
                                                </div>
                                                <div>
                                                    <div className="label-part">Designator</div>
                                                    <div className="text-part">{apiData ? apiData.designator : ''}</div>
                                                </div>
                                            </div>
                                            <div className="formation-info">
                                                <div>
                                                    <div className="label-part">Company Name</div>
                                                    <div className="text-part">{apiData ? apiData.business_name : ''} {apiData ? apiData.designator : ''}</div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="review-box">
                                            <div className="review-info">
                                                <div className='formation1'>Company Address &amp; Contact Address</div>
                                                <Link to={`/process-company-address`}>Edit</Link>
                                            </div>
                                            <div className="formation-info">
                                                <div>
                                                    <div className="label-part">Company Address</div>
                                                    <div className="text-part">
                                                        You have selected for Corpquick to provide you with a {apiData ? apiData.full_business_name : ''}
                                                    </div>
                                                </div>
                                                <div>
                                                    <div className="label-part">Contact Address</div>
                                                    <div className="text-part">
                                                       {apiData ? apiData.companyAddressStreetAddress : ''} , {apiData ? apiData.companyAddressCity : ''} , {apiData ? apiData.companyAddressZipCode : ''}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        <div className="review-box">
                                            <div className="review-info">
                                                <div className="formation1">
                                                    Member Info
                                                </div>

                                                <Link to={`/process-member`}>Edit</Link>
                                            </div>
                                            {apiData && apiData.process_members && (
                                                apiData.process_members.map((member, index) => (
                                                    <div key={member.id}>
                                                        <div className="bg-accent-aqua-0">Member {index + 1}</div>
                                                        <div className="formation-info">

                                                            <div>
                                                                <div className="label-part">Name</div>
                                                                <div className="text-part">
                                                                    {member.processMemberIndiviualCompany === "company"
                                                                        ? member.processMemberCompanyName
                                                                        : `${member.processMemberFirstName} ${member.processMemberLastName}`}

                                                                </div>
                                                            </div>
                                                            <div>
                                                                <div className="label-part">Address</div>
                                                                <div className="text-part">
                                                                    {`You have selected for Corpquick to provide you with a ${member.processMemberIndiviualCompany} address`}
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                ))
                                            )}
                                        </div>

                                        <div className="review-box">
                                            <div className="review-info">
                                                <div className='formation1'>Business Tax Consultation</div>
                                                <Link to={`/tax-invite`}>Edit</Link>
                                            </div>
                                            <div className="formation-info1">
                                                <p>
                                                    You will receive a {apiData ? apiData.taxStrategyYesOrNo : ''}
                                                </p>
                                            </div>
                                        </div>
                                        <div className="review-box">
                                            <div className="review-info">
                                                <div className='formation1'>EIN / Tax Info</div>
                                                <Link to={`/process-ss4`}>Edit</Link>
                                            </div>
                                            <div className="formation-info formation12">
                                                <div>
                                                    <div className="label-part">Name</div>
                                                    <div className="text-part">{apiData ? apiData.taxIdentificationFirstName : ''} {apiData ? apiData.taxIdentificationLastName : ''}</div>
                                                </div>
                                                <div>
                                                    <div className="label-part">Address</div>
                                                    <div className="text-part">
                                                       {apiData ? apiData.taxIdentificationStreetAddress : ''} , {apiData ? apiData.taxIdentificationState : ''} , {apiData ? apiData.taxIdentificationZipCode : ''} , {apiData ? apiData.taxIdentificationCountry : ''}
                                                    </div>
                                                </div>
                                                <div>
                                                    <div className="label-part">SSN</div>
                                                    <div className="text-part">{apiData ? apiData.taxIdentificationSecurityNumber : ''}</div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="button-nest-prev">
                                            <div className="left-next">
                                                <Link to="/tax-invite">
                                                <button type='button'>
                                                    <i className="fa fa-arrow-left" aria-hidden="true" />
                                                    Back
                                                    </button>
                                                </Link>
                                            </div>
                                            <div className="right-next nex2">
                                                <button
                                                    onClick={nextPage}
                                                >
                                                    Next
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-4">
                                <div className="register-right">
                                    <h4>Order Summary</h4>
                                    <h2>{apiData ? apiData.full_business_name : ''}</h2>
                                    <div className="progress">
                                        <div
                                            className="progress-bar"
                                            role="progressbar"
                                            style={{ width: "85%" }}
                                            aria-valuenow={85}
                                            aria-valuemin={0}
                                            aria-valuemax={100}
                                        >
                                            85%
                                        </div>
                                    </div>
                                    <div className="order-items flex flex-col gap-3 mt-8">
                                    <div className="item">
                                            <div className="name">{apiData ? apiData.package : ''} Package</div>
                                            <div className="value ">${apiData ? apiData.package_fee : ''}</div>
                                        </div>
                                        {additionalServices.map((service, index) => (
                                            <div className="item" key={index}>
                                                <div className="name">{service.name}</div>
                                                <div className="value">${service.price}</div>
                                            </div>
                                        ))}
                                        <div className="item">
                                            <div className="name">{apiData ? apiData.selected_state : ''} State Filing Fee</div>
                                            <div className="value">
                                                <span id="dynamic-filing-fee">${apiData ? apiData.stateFee : ''}</span>
                                            </div>
                                        </div>
                                        {apiData?.companyAddress === "Professional Business Address" && (
                                            <div className="item" id="order-item-legal-consulation">
                                                <div className="name">Business Address</div>
                                                <div className="value">
                                                    $<span id="dynamic-filing-fee">29</span>
                                                </div>
                                            </div>
                                        )}
                                        <div className="item hide" id="order-item-legal-consulation">
                                            <div className="name">Banking Resolution</div>
                                            <div className="value">
                                                <i className="fa fa-check" aria-hidden="true" />
                                            </div>
                                        </div>
                                        <div className="item hide" id="order-item-expedited">
                                            <div className="name">Banking Resolution</div>
                                            <div className="value">
                                                <i className="fa fa-check" aria-hidden="true" />
                                            </div>
                                        </div>
                                        <div className="item hide" id="order-item-legal-consulation">
                                            <div className="name">Minutes Meeting</div>
                                            <div className="value">
                                                <i className="fa fa-check" aria-hidden="true" />
                                            </div>
                                        </div>
                                        <div className="item hide" id="order-item-license-package">
                                            <div className="name">Bylaws</div>
                                            <div className="value">
                                                <i className="fa fa-check" aria-hidden="true" />
                                            </div>
                                        </div>
                                        {registeredAgentInformation && (
                                            <div className="item">
                                                <div className="name">Registered Agent (1st Year)</div>
                                                <div className="value">
                                                    Free
                                                </div>
                                            </div>
                                        )}
                                        <div className="item hide" id="order-item-va">
                                            <div className="name">EIN / Tax ID #</div>
                                            <div className="value">
                                                <i className="fa fa-check" aria-hidden="true" />
                                            </div>
                                        </div>
                                        <div className="item">
                                            <div className="name">Electronic Delivery</div>
                                            <div className="value">
                                                <i className="fa fa-check" aria-hidden="true" />
                                            </div>
                                        </div>
                                        <div className="item">
                                            <div className="name">Form 2553</div>
                                            <div className="value">
                                                <i className="fa fa-check" aria-hidden="true" />
                                            </div>
                                        </div>
                                        {taxConsultation && (
                                            <div className="item">
                                                <div className="name">Tax Consultation</div>
                                                <div className="value">
                                                    Free
                                                </div>
                                            </div>
                                        )}

                                        <div className="item">
                                            <div className="name flex flex-col">
                                                <span>Phone Support</span>
                                                <span className="text-xs text-gray-700 mt-[6px]">
                                                    Available Monday - Friday from 9 a.m. to 6 p.m. CST
                                                </span>
                                            </div>
                                            <div className="value">
                                                <i className="fa fa-check" aria-hidden="true" />
                                            </div>
                                        </div>
                                        <hr className="!mt-1 !mb-1" />
                                        <div className="totla-value1">
                                            <div className="item items-center mb-8">
                                                <div className="name">Total</div>
                                                <div className="value bold text-black">
                                                    <span className="SumPackageTotal text-2xl">
                                                        $<span className="totalPrice">
                                                            {apiData
                                                                ? parseFloat(apiData.form_total_price || 0) +
                                                                (apiData.companyAddress === "Professional Business Address" ? 29 : 0)
                                                                : ''}
                                                        </span>
                                                    </span>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="trustpilot">
                                            <img src="images/Trustpilot-Reviews-Services-1.png" alt="" />
                                            <p>TrustScore 4.7 | 19,076 reviews</p>
                                        </div>
                                    
                                        <div className="join-business">
                                            <div className="font-radwave text-black">
                                                <h2 className="text-base tracking-wide text-left  my-0">
                                                    JOIN THE
                                                </h2>
                                                <p className="text-base text-red-500 tracking-wide ">
                                                    1,000,000+
                                                </p>
                                                <h3 className="text-base tracking-wide uppercase my-0">
                                                    Businesses
                                                </h3>
                                                <h6>
                                                    That have trusted Corpquick to start, manage, and grow their
                                                    business{" "}
                                                </h6>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <Footer />
        </>
    )
}

export default OrderReview
