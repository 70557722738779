import React, { useState } from 'react';
import Header from '../Components/Common/Header';
import Footer from '../Components/Common/Footer';
import config from '../config';
const Contact = () => {
    const [formData, setFormData] = useState({
        first_name: '',
        last_name: '',
        email: '',
        phoneno: '',
        message: ''
    });

    const [errorMessage, setErrorMessage] = useState('');
    const [successMessage, setSuccessMessage] = useState('');

    const handleChange = (e) => {
        const { name, value } = e.target;

        // Check for spaces and invalid characters (numeric and special) for first_name and last_name
        const isNameField = name === 'first_name' || name === 'last_name';
        const invalidCharacters = /[^a-zA-Z]/; // Allow only letters

        if (name === 'email' && value.includes(' ')) {
            return;
        }
        if (isNameField) {
            if (value.includes(' ')) {
                return;
            } else if (invalidCharacters.test(value)) {
                return;
            }
        }
        if (name === 'message' && value.startsWith(' ')) {
            return; // Prevent the update if the first character is a space
        }

        // Update the form data for valid inputs
        setFormData((prevData) => ({
            ...prevData,
            [name]: value
        }));

        setErrorMessage(''); // Clear error if the value is valid
    };


    const handleSubmit = async (e) => {
        e.preventDefault();

        // Email validation regex
        const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;

        // Check if the email is invalid
        if (!emailRegex.test(formData.email)) {
            setErrorMessage("Invalid email format.");
            return; // Stop form submission
        }

        console.log("Submitting Form Data:", formData);

        try {
            const response = await fetch(`${config.baseUrl}/add-contact`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(formData),
            });

            const responseData = await response.json();
            console.log("API Response:", responseData);

            if (response.ok) {
                setSuccessMessage("Your message has been successfully submitted!");
                setFormData({
                    first_name: '',
                    last_name: '',
                    email: '',
                    phoneno: '',
                    message: ''
                });
                setErrorMessage('');
            } else {
                setErrorMessage(responseData.message || "Something went wrong. Please try again.");
            }
        } catch (error) {
            console.error("Fetch Error:", error);
            setErrorMessage("An error occurred. Please try again.");
        }
    };

    return (
        <>
            <Header />

            <div className='common-banenr'>
                <div className='common-content'>
                    <h1>Contact<span> Us</span></h1>
                </div>
            </div>
            <div className='banner-bottom-box'>
                <div className='box1'>
                </div>
            </div>

            <section className="contact-us">
                <div className='container'>
                    <div className='row'>
                        <div className='col-md-4'>
                            <div className='contact-box'>
                                <div className="icon-contact">
                                    <img src="images/location.png" alt="" />
                                </div>
                                <p>901 N Pitt Str., Suite 170 Alexandria, USA</p>
                            </div>
                        </div>
                        <div className='col-md-4'>
                            <div className='contact-box'>
                                <div className="icon-contact">
                                    <img src="images/contactphone.png" alt="" />
                                </div>
                                <p><a href="tel:(804) 916-1974">(804) 916-1974</a></p>
                            </div>
                        </div>
                        <div className='col-md-4'>
                            <div className='contact-box'>
                                <div className="icon-contact">
                                    <img src="images/clock.png" alt="" />
                                </div>
                                <p>Monday – Saturday: 8AM – 6PM<br />Sunday: OFF DAY</p>
                            </div>
                        </div>
                    </div>
                    <div className='row'>
                        <div className='col-md-12'>
                            <div className='contact-form' >
                                <div className='form-contact'>
                                    <h4>Get In Touch With Us</h4>
                                    <form onSubmit={handleSubmit}>
                                        <input
                                            type="text"
                                            name="first_name"
                                            placeholder="First Name"
                                            value={formData.first_name}
                                            onChange={handleChange}
                                            required
                                        />
                                        <input
                                            type="text"
                                            name="last_name"
                                            placeholder="Last Name"
                                            value={formData.last_name}
                                            onChange={handleChange}
                                            required
                                        />
                                        <input
                                            type="text"
                                            name="email"
                                            placeholder="Email"
                                            value={formData.email}
                                            onChange={handleChange}
                                            required
                                            onBlur={(e) => {
                                                const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
                                                if (!emailRegex.test(e.target.value)) {
                                                    setErrorMessage("Invalid email format.");
                                                } else {
                                                    setErrorMessage("");
                                                }
                                            }}
                                        />


                                        <input
                                            type="text" // Change to text to allow length validation
                                            name="phoneno"
                                            placeholder="Phone"
                                            value={formData.phoneno}
                                            onChange={(e) => {
                                                const value = e.target.value.replace(/\D/g, ""); // Remove non-numeric characters
                                                if (value.length <= 15) {
                                                    setFormData({ ...formData, phoneno: value });
                                                }
                                            }}
                                            required
                                            pattern="^\d{10,15}$" // Ensures 10-15 digits
                                        />
                                        <textarea
                                            name="message"
                                            placeholder='Message'
                                            value={formData.message}
                                            onChange={handleChange}
                                            required
                                        />
                                        <input type="submit" value="Submit" />
                                    </form>
                                    {errorMessage && <p className="error-message">{errorMessage}</p>}
                                    {successMessage && <p className="success-message">{successMessage}</p>}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <Footer />
        </>

    )
}

export default Contact
