import React, { useState, useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/style.css';
import '../registerstyle.css';
import Header from '../../Common/Header';
import Footer from '../../Common/Footer';
import config from '../../../config';

const ContactPerson = () => {
    const [phone, setPhone] = useState('');
    const [firstName, setFirstName] = useState('');
    const [lastName, setLastName] = useState('');
    const [email, setEmail] = useState('');
      const [additionalServices, setAdditionalServices] = useState([]); 
    const [apiData, setApiData] = useState(null);
    const [errors, setErrors] = useState({});
    const [isCheckboxChecked, setIsCheckboxChecked] = useState(false);
    const [isSubmitting, setIsSubmitting] = useState(false);
    const navigate = useNavigate();
    const resultData = localStorage.getItem('formOrderResult');
    let extractedId = null;

    if (resultData) {
        const parsedResult = JSON.parse(resultData);
        extractedId = parsedResult.data.id;
    }

    // Fetch data when the component mounts
    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await fetch(`${config.baseUrl}/registration/get-form-1/${extractedId}`);
                if (response.ok) {
                    const data = await response.json();
                    setApiData(data.data); 
                    setFirstName(data.data.contact_person_first_name || '');
                    setLastName(data.data.contact_person_last_name || '');
                    setEmail(data.data.contact_person_email || '');
                    setPhone(data.data.contact_person_phone_number || '');
                    setIsCheckboxChecked(data.data.contactPersonConsent === "1");
                    if (data.data.additional_services) {
                        const parsedServices = JSON.parse(data.data.additional_services);
                        setAdditionalServices(parsedServices);
                    }
                } else {
                    console.error('Error fetching data:', response.statusText);
                }
            } catch (error) {
                console.error('Error:', error);
            }
        };

        fetchData();
    }, [extractedId]);

    const validateFields = () => {
        const newErrors = {};
        if (!firstName.trim()) newErrors.firstName = 'First Name is required.';
        if (!lastName.trim()) newErrors.lastName = 'Last Name is required.';
        if (!email.trim() || !/\S+@\S+\.\S+/.test(email)) newErrors.email = 'A valid email is required.';
        if (!phone.trim()) newErrors.phone = 'Phone is required.';
        if (!isCheckboxChecked) newErrors.checkbox = 'You must consent to receiving SMS and phone calls.';  // Add checkbox validation
        setErrors(newErrors);
        return Object.keys(newErrors).length === 0;
    };

    
    const handleSaveAndNext = async (e) => {
        e.preventDefault();
        if (!validateFields()) return;
    
        setIsSubmitting(true);
        setErrors({}); // Clear previous errors
    
        const payload = {
            contactPersonFirstName: firstName,
            contactPersonLastName: lastName,
            contactPersonEmail: email,
            contactPersonCountryCode: phone.slice(0, phone.indexOf(phone.match(/\d/))), // Extract country code
            contactPersonPhoneNumber: phone.slice(phone.indexOf(phone.match(/\d/))), // Extract phone number
            contactPersonConsent: isCheckboxChecked,
        };
    
        try {
            const response = await fetch(`${config.baseUrl}/registration/form-3/${extractedId}`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(payload),
            });
    
            const data = await response.json();
    
            if (response.ok) {
                navigate('/process-company-address');
            } else {
                // if (data.status === "error" && data.msg === "This user already exists") {
                //     setErrors((prevErrors) => ({
                //         ...prevErrors,
                //         email: "This user already exists", // Set error message for email field
                //     }));
                // } else {
                    console.error('Error in form submission:', data.msg);
                // }
            }
        } catch (error) {
            console.error('Error:', error);
        } finally {
            setIsSubmitting(false);
        }
    };
    

    return (
        <>
            <Header />
            <section className="registration-from">
                <div className="container">
                    <div className="registerformstart">
                        <div className="row">
                            <div className="col-md-8">
                                <div className="register-left">
                                    <div className="box__heading">Contact Person</div>
                                    <p>Please provide the name of the person responsible for this order whom we may contact if additional information is needed.</p>
                                    <div className="conact-form">
                                        <div className="row">
                                            <div className="col-md-6">
                                                <label>First Name</label>
                                                <input
                                                    type="text"
                                                    value={firstName}
                                                    onChange={(e) => setFirstName(e.target.value.trimStart())}
                                                />
                                                {errors.firstName && <span className="error-text">{errors.firstName}</span>}
                                            </div>
                                            <div className="col-md-6">
                                                <label>Last Name</label>
                                                <input
                                                    type="text"
                                                    value={lastName}
                                                    onChange={(e) => setLastName(e.target.value.trimStart())}
                                                />
                                                {errors.lastName && <span className="error-text">{errors.lastName}</span>}
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="col-md-6">
                                                <label>Email</label>
                                                <input
                                                    type="email"
                                                    value={email}
                                                    onChange={(e) => setEmail(e.target.value.trimStart())}
                                                />
                                                {errors.email && <span className="error-text">{errors.email}</span>}
                                            </div>
                                            <div className="col-md-6">
                                                <label>Phone</label>
                                                <div className="select-box11">
                                                    <PhoneInput
                                                        country="us"
                                                        value={phone}
                                                        onChange={setPhone}
                                                    />
                                                </div>
                                                {errors.phone && <span className="error-text">{errors.phone}</span>}
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="col-md-12">
                                                <div className="checl-box12">
                                                    <input
                                                        type="checkbox"
                                                        checked={isCheckboxChecked}
                                                        onChange={(e) => setIsCheckboxChecked(e.target.checked)}
                                                    />
                                                    <p>I consent to receiving SMS text messages and phone calls from CORPQuick.</p>
                                                    {errors.checkbox && <span className="error-text">{errors.checkbox}</span>} 
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="button-nest-prev">
                                    <div className="left-next">
                                        <Link to="/process-coinfo">
                                        <button type="button">
                                            <i className="fa fa-arrow-left" aria-hidden="true"></i> Back
                                            </button></Link>
                                    </div>
                                    <div className="right-next">
                                        <button onClick={handleSaveAndNext} disabled={isSubmitting}>
                                            {isSubmitting ? 'Submitting...' : 'Next'}
                                        </button>
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-4">
                                <div className="register-right">
                                    <h4>Order Summary</h4>
                                    <h2>{apiData ? apiData.full_business_name : ''}</h2>
                                    <div className="progress">
                                        <div
                                            className="progress-bar"
                                            role="progressbar"
                                            style={{ width: "30%" }}
                                            aria-valuenow={30}
                                            aria-valuemin={0}
                                            aria-valuemax={100}
                                        >
                                            30%
                                        </div>
                                    </div>
                                    <div className="order-items flex flex-col gap-3 mt-8">
                                    <div className="item">
                                            <div className="name">{apiData ? apiData.package : ''} Package</div>
                                            <div className="value ">${apiData ? apiData.package_fee : ''}</div>
                                        </div>
                                        {additionalServices.map((service, index) => (
                                            <div className="item" key={index}>
                                                <div className="name">{service.name}</div>
                                                <div className="value">${service.price}</div>
                                            </div>
                                        ))}
                                        <div className="item">
                                            <div className="name">{apiData ? apiData.selected_state : ''} State Filing Fee</div>
                                            <div className="value">
                                                <span id="dynamic-filing-fee">${apiData ? apiData.stateFee : ''}</span>
                                            </div>
                                        </div>
                                        <div className="item hide" id="order-item-legal-consulation">
                                            <div className="name">Banking Resolution</div>
                                            <div className="value">
                                                <i className="fa fa-check" aria-hidden="true" />
                                            </div>
                                        </div>
                                        <div className="item hide" id="order-item-expedited">
                                            <div className="name">Banking Resolution</div>
                                            <div className="value">
                                                <i className="fa fa-check" aria-hidden="true" />
                                            </div>
                                        </div>
                                        <div className="item hide" id="order-item-legal-consulation">
                                            <div className="name">Minutes Meeting</div>
                                            <div className="value">
                                                <i className="fa fa-check" aria-hidden="true" />
                                            </div>
                                        </div>
                                        <div className="item hide" id="order-item-license-package">
                                            <div className="name">Bylaws</div>
                                            <div className="value">
                                                <i className="fa fa-check" aria-hidden="true" />
                                            </div>
                                        </div>
                                        <div className="item hide" id="order-item-va">
                                            <div className="name">EIN / Tax ID #</div>
                                            <div className="value">
                                                <i className="fa fa-check" aria-hidden="true" />
                                            </div>
                                        </div>
                                        <div className="item">
                                            <div className="name">Electronic Delivery</div>
                                            <div className="value">
                                                <i className="fa fa-check" aria-hidden="true" />
                                            </div>
                                        </div>
                                        <div className="item">
                                            <div className="name">Form 2553</div>
                                            <div className="value">
                                                <i className="fa fa-check" aria-hidden="true" />
                                            </div>
                                        </div>
                                       
                                        <div className="item">
                                            <div className="name flex flex-col">
                                                <span>Phone Support</span>
                                                <span className="text-xs text-gray-700 mt-[6px]">
                                                    Available Monday - Friday from 9 a.m. to 6 p.m. CST
                                                </span>
                                            </div>
                                            <div className="value">
                                                <i className="fa fa-check" aria-hidden="true" />
                                            </div>
                                        </div>
                                        <hr className="!mt-1 !mb-1" />
                                        <div className="totla-value1">
                                            <div className="item items-center mb-8">
                                                <div className="name">Total</div>
                                                <div className="value bold text-black">
                                                    <span className="SumPackageTotal text-2xl">
                                                        $<span className="totalPrice">{apiData ? apiData.form_total_price : ''}</span>
                                                    </span>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="trustpilot">
                                            <img src="images/Trustpilot-Reviews-Services-1.png" alt="" />
                                            <p>TrustScore 4.7 | 19,076 reviews</p>
                                        </div>
                                     
                                        <div className="join-business">
                                            <div className="font-radwave text-black">
                                                <h2 className="text-base tracking-wide text-left  my-0">
                                                    JOIN THE
                                                </h2>
                                                <p className="text-base text-red-500 tracking-wide ">
                                                    1,000,000+
                                                </p>
                                                <h3 className="text-base tracking-wide uppercase my-0">
                                                    Businesses
                                                </h3>
                                                <h6>
                                                    That have trusted Corpquick to start, manage, and grow their
                                                    business{" "}
                                                </h6>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <Footer />
        </>
    );
};

export default ContactPerson;
