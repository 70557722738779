import React, { useState, useEffect } from 'react';
import Header from '../../Common/Header';
import Footer from '../../Common/Footer';
import { Link, useNavigate } from 'react-router-dom';
import config from '../../../config';

const SmallBanking = () => {
    const [formOrderData, setFormOrderData] = useState(null);
    const [apiData, setApiData] = useState(null);
    const [registeredAgentInformation, setRegisteredAgentInformation] = useState("");
    const resultData = localStorage.getItem('formOrderResult');
    const [isSubmitting, setIsSubmitting] = useState(false);
      const [additionalServices, setAdditionalServices] = useState([]); 
    const [businessBankingInterestedOrNot, setBusinessBankingInterestedOrNot] = useState('');
    let extractedId = null;
    const navigate = useNavigate();

    if (resultData) {
        const parsedResult = JSON.parse(resultData);
        extractedId = parsedResult.data.id;
        console.log('Extracted ID:', extractedId);
    }

    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await fetch(`${config.baseUrl}/registration/get-form-1/${extractedId}`);
                if (response.ok) {
                    const data = await response.json();
                    setApiData(data.data);
                    if (data.data.registeredAgentInformation === "free") {
                        setRegisteredAgentInformation(true);
                    }
                    if (data.data.businessBankingInterestedOrNot) {
                        setBusinessBankingInterestedOrNot(data.data.businessBankingInterestedOrNot);
                    }
                    if (data.data.additional_services) {
                        const parsedServices = JSON.parse(data.data.additional_services);
                        setAdditionalServices(parsedServices);
                    }
                } else {
                    console.error('Error fetching data:', response.statusText);
                }
            } catch (error) {
                console.error('Error:', error);
            }
        };

        fetchData();
    }, [extractedId]);

    const handleSubmit = async (interestStatus) => {
        setIsSubmitting(true);
        setBusinessBankingInterestedOrNot(interestStatus); // Update state with the clicked value

        const payload = {
            businessBankingInterestedOrNot: interestStatus,
        };

        try {
            const response = await fetch(`${config.baseUrl}/registration/form-9/${extractedId}`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(payload)
            });

            if (response.ok) {
                const data = await response.json();
                navigate("/tax-invite");
                console.log('Form submission successful:', data);
            } else {
                console.error('Error in form submission:', response.statusText);
            }
        } catch (error) {
            console.error('Error:', error);
        } finally {
            setIsSubmitting(false);
        }
    };

    return (
        <>
            <Header />
            <section className="registration-from">
                <div className="container">
                    <div className="registerformstart">
                        <div className="row">
                            <div className="col-md-8">
                                <form onSubmit={(e) => { e.preventDefault(); handleSubmit("Interested"); }}>
                                    <div className="register-left">
                                        <div className="box__heading1 business-banking">
                                            Setting up Small Business Banking
                                        </div>
                                        <p>No-Fee Business Banking for the Modern Business</p>
                                        <p className="font600">
                                            When forming a business, separating your business and personal accounts is a crucial step to protecting your assets.
                                        </p>
                                        <p>
                                            Relay, is an online business banking and money management platform that helps small businesses take control of their cash flow and get crystal clear on what they’re earning, spending, and saving.
                                        </p>
                                        <p className="mt-3">
                                            *With Relay, you can apply in a few minutes, once your state formation and EIN are complete.
                                        </p>
                                        <div className="divider mb25" />
                                        <div className="p-4 pt-5 mb25 common-border-radius-blk">
                                            <div className="d-flex justify-content-between align-items-center">
                                                <div className="">
                                                    <img src="images/relay.png" alt="" />
                                                </div>
                                                <a href="/relay-disclosure" target="_blank" className="disclaimer">
                                                    Disclaimer
                                                </a>
                                            </div>
                                            <hr className="" />
                                            <ul className="list-unstyled">
                                                <li>
                                                    <i className="fa fa-check" aria-hidden="true" /> No account fees or minimums
                                                </li>
                                                <li>
                                                    <i className="fa fa-check" aria-hidden="true" />
                                                    Up to 20 individual checking accounts to help you control projects, budgets, and taxes
                                                </li>
                                                <li>
                                                    <i className="fa fa-check" aria-hidden="true" />
                                                    Have employees? No problem, issue up to 50 Visa debit cards to keep your business moving
                                                </li>
                                                <li>
                                                    <i className="fa fa-check" aria-hidden="true" /> FDIC Insured*
                                                </li>
                                            </ul>
                                        </div>
                                        <div className="p-4 pt-5 common-border-radius-blk">
                                            <div className="d-flex justify-content-between align-items-center mb25">
                                                <img src="images/star.svg" alt="4.5 stars rating" />
                                                <span className="">4 days ago</span>
                                            </div>
                                            <blockquote className="">
                                                “Relay has been perfect for our small business. We are able to have multiple accounts to separate funds into different categories and they do not charge any fees per account. We are able to get physical cards for each account sent to us easily as well as generate virtual cards instantly for any account.”
                                            </blockquote>
                                            <div className="">John</div>
                                        </div>
                                        <div className="button-nest-prev">
                                            <div className="left-next left-small-banking">
                                       
                                                <Link to="/process-ss4">
                                                <button type="button">
                                                    <i className="fa fa-arrow-left" aria-hidden="true" />
                                                    Back
                                                    </button>
                                                </Link>
                                         
                                                <div className="right-next nex2 ask-later">
                                                    <button type="button" onClick={() => handleSubmit("Ask Later")}>
                                                        Ask Later
                                                    </button>
                                                </div>
                                            </div>
                                            <div className="right-next nex2">
                                                <button type="submit" disabled={isSubmitting}>
                                                    {isSubmitting ? 'Submitting...' : "Yes, I'm interested"}
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                </form>
                            </div>
                            <div className="col-md-4">
                                <div className="register-right">
                                    <h4>Order Summary</h4>
                                    <h2>{apiData ? apiData.full_business_name : ''}</h2>
                                    <div className="progress">
                                        <div
                                            className="progress-bar"
                                            role="progressbar"
                                            style={{ width: "63%" }}
                                            aria-valuenow={63}
                                            aria-valuemin={0}
                                            aria-valuemax={100}
                                        >
                                            63%
                                        </div>
                                    </div>
                                    <div className="order-items flex flex-col gap-3 mt-8">
                                    <div className="item">
                                            <div className="name">{apiData ? apiData.package : ''} Package</div>
                                            <div className="value ">${apiData ? apiData.package_fee : ''}</div>
                                        </div>
                                        {additionalServices.map((service, index) => (
                                            <div className="item" key={index}>
                                                <div className="name">{service.name}</div>
                                                <div className="value">${service.price}</div>
                                            </div>
                                        ))}
                                        <div className="item">
                                            <div className="name">{apiData ? apiData.selected_state : ''} State Filing Fee</div>
                                            <div className="value">
                                                <span id="dynamic-filing-fee">${apiData ? apiData.stateFee : ''}</span>
                                            </div>
                                        </div>
                                        {apiData?.companyAddress === "Professional Business Address" && (
                                            <div className="item" id="order-item-legal-consulation">
                                                <div className="name">Business Address</div>
                                                <div className="value">
                                                    $<span id="dynamic-filing-fee">29</span>
                                                </div>
                                            </div>
                                        )}
                                        <div className="item hide" id="order-item-legal-consulation">
                                            <div className="name">Banking Resolution</div>
                                            <div className="value">
                                                <i className="fa fa-check" aria-hidden="true" />
                                            </div>
                                        </div>
                                        <div className="item hide" id="order-item-expedited">
                                            <div className="name">Banking Resolution</div>
                                            <div className="value">
                                                <i className="fa fa-check" aria-hidden="true" />
                                            </div>
                                        </div>
                                        <div className="item hide" id="order-item-legal-consulation">
                                            <div className="name">Minutes Meeting</div>
                                            <div className="value">
                                                <i className="fa fa-check" aria-hidden="true" />
                                            </div>
                                        </div>
                                        <div className="item hide" id="order-item-license-package">
                                            <div className="name">Bylaws</div>
                                            <div className="value">
                                                <i className="fa fa-check" aria-hidden="true" />
                                            </div>
                                        </div>
                                        {registeredAgentInformation && (
                                            <div className="item">
                                                <div className="name">Registered Agent (1st Year)</div>
                                                <div className="value">
                                                    Free
                                                </div>
                                            </div>
                                        )}
                                        <div className="item hide" id="order-item-va">
                                            <div className="name">EIN / Tax ID #</div>
                                            <div className="value">
                                                <i className="fa fa-check" aria-hidden="true" />
                                            </div>
                                        </div>
                                        <div className="item">
                                            <div className="name">Electronic Delivery</div>
                                            <div className="value">
                                                <i className="fa fa-check" aria-hidden="true" />
                                            </div>
                                        </div>
                                        <div className="item">
                                            <div className="name">Form 2553</div>
                                            <div className="value">
                                                <i className="fa fa-check" aria-hidden="true" />
                                            </div>
                                        </div>

                                        <div className="item">
                                            <div className="name flex flex-col">
                                                <span>Phone Support</span>
                                                <span className="text-xs text-gray-700 mt-[6px]">
                                                    Available Monday - Friday from 9 a.m. to 6 p.m. CST
                                                </span>
                                            </div>
                                            <div className="value">
                                                <i className="fa fa-check" aria-hidden="true" />
                                            </div>
                                        </div>
                                        <hr className="!mt-1 !mb-1" />
                                        <div className="totla-value1">
                                            <div className="item items-center mb-8">
                                                <div className="name">Total</div>
                                                <div className="value bold text-black">
                                                    <span className="SumPackageTotal text-2xl">
                                                        $<span className="totalPrice">
                                                            {apiData
                                                                ? parseFloat(apiData.form_total_price || 0) +
                                                                (apiData.companyAddress === "Professional Business Address" ? 29 : 0)
                                                                : ''}
                                                        </span>
                                                    </span>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="trustpilot">
                                            <img src="images/Trustpilot-Reviews-Services-1.png" alt="" />
                                            <p>TrustScore 4.7 | 19,076 reviews</p>
                                        </div>
                             
                                        <div className="join-business">
                                            <div className="font-radwave text-black">
                                                <h2 className="text-base tracking-wide text-left  my-0">
                                                    JOIN THE
                                                </h2>
                                                <p className="text-base text-red-500 tracking-wide ">
                                                    1,000,000+
                                                </p>
                                                <h3 className="text-base tracking-wide uppercase my-0">
                                                    Businesses
                                                </h3>
                                                <h6>
                                                    That have trusted Corpquick to start, manage, and grow their
                                                    business{" "}
                                                </h6>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <Footer />
        </>
    );
};

export default SmallBanking;
