import React, {useState , useEffect} from 'react';
import { useNavigate } from 'react-router-dom';
import config from '../../config';

const ConfirmOrder = ({ onClose }) => {
    const navigate = useNavigate()
    const resultData = localStorage.getItem('formOrderResult');
      const [apiData, setApiData] = useState(null);
    let extractedId = null;
    if (resultData) {
        const parsedResult = JSON.parse(resultData);
        extractedId = parsedResult.data.id; // Accessing the id
        console.log('Extracted ID:', extractedId); // Log the extracted ID
    }
    useEffect(() => {
      const fetchData = async () => {
          try {
              const response = await fetch(`${config.baseUrl}/registration/get-form-1/${extractedId}`);
              if (response.ok) {
                  const data = await response.json();
                  setApiData(data.data); 
              } else {
                  console.error('Error fetching data:', response.statusText);
              }
          } catch (error) {
              console.error('Error:', error);
          }
      };

      fetchData();
  }, [extractedId]);
  const handleStartOver = async () => {
    try {
      const response = await fetch(`${config.baseUrl}/registration/delete/${extractedId}`, {
        method: 'DELETE',
      });

      if (response.ok) {
        console.log('Order deleted successfully.');
        localStorage.removeItem('formOrderResult');
        navigate("/")
      } else {
        console.error('Failed to delete the order.');
      }
    } catch (error) {
      console.error('Error occurred while deleting the order:', error);
    }
    
    onClose(); // Close the popup after starting over
  };

  const handleResumeOrder = () => {
    // Logic for resuming the order
    onClose(); // Close the popup after resuming
    navigate(`/form-order?entity=${apiData ? apiData.selectedEntityId : ''}&state=${apiData ? apiData.selectedStateId : ''}`)
    // navigate("/form-order")
  };

  return (
    <div style={styles.overlay}>
      <div style={styles.container}>
        <div style={styles.iconContainer}>
          <span style={styles.icon}>🌐</span>
        </div>
        <h3 style={styles.title}>Confirm</h3>
        <p style={styles.message}>It appears you may already have an order in progress.</p>
        <div style={styles.buttonContainer}>
          <button style={styles.startOverButton} onClick={handleStartOver}>
            Start Over
          </button>
          <button style={styles.resumeOrderButton} onClick={handleResumeOrder}>
            Resume Order
          </button>
        </div>
      </div>
    </div>
  );
};

const styles = {
  overlay: {
    position: 'fixed',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    backgroundColor: 'rgba(0, 0, 0, 0.5)', // Semi-transparent background
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    zIndex: 1000, // Ensure it appears above all other content
  },
  container: {
    backgroundColor: '#fff',
    borderRadius: '10px',
    padding: '20px',
    textAlign: 'center',
    width: '300px',
    boxShadow: '0 2px 10px rgba(0, 0, 0, 0.1)',
  },
  iconContainer: {
    backgroundColor: '#f2f2f2',
    borderRadius: '50%',
    width: '50px',
    height: '50px',
    margin: '0 auto 10px auto',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  icon: {
    fontSize: '24px',
  },
  title: {
    fontSize: '18px',
    margin: '10px 0',
  },
  message: {
    fontSize: '14px',
    color: '#666',
    marginBottom: '20px',
  },
  buttonContainer: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  startOverButton: {
    backgroundColor: '#f2f2f2',
    border: 'none',
    borderRadius: '5px',
    padding: '10px 20px',
    cursor: 'pointer',
    fontSize: '14px',
  },
  resumeOrderButton: {
    backgroundColor: '#ff6600',
    border: 'none',
    borderRadius: '5px',
    padding: '10px 20px',
    color: '#fff',
    cursor: 'pointer',
    fontSize: '14px',
  },
};

export default ConfirmOrder;
