import React from 'react';
import Header from '../Common/Header';
import Footer from '../Common/Footer';

const Thankyou = () => {
  
const styles = {
  container: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    height: '40vh', // Adjusted height
    backgroundColor: '#f9f9f9',
    margin: 0,
    textAlign: 'center',
  },
  text: {
    fontSize: '2.5rem',
    fontWeight: 'bold',
    color: '#333',
  },
  subText: {
    fontSize: '1.2rem',
    color: '#555',
    marginTop: '10px',
  },
  email: {
    fontSize: '1rem',
    color: '#007bff',
    fontWeight: 'bold',
    marginTop: '10px',
  },
};

  return (
    <>
      <Header />
      <div style={styles.container}>
        <h1 style={styles.text}>Thank you for choosing Us</h1>
        <p style={styles.subText}>Our team will contact you within 24 hours.</p>
        <p style={styles.email}>
          If you have any queries, email us at <a href="mailto:info@corpquick.com">info@corpquick.com</a>
        </p>
      </div>
      <Footer />
    </>
  );
};

export default Thankyou;
