import React, { useState, useEffect } from 'react';
import Header from '../../Common/Header';
import Footer from '../../Common/Footer';
import { Link, useNavigate } from 'react-router-dom';
import config from '../../../config';

const ProcessAgent = () => {

    const [formOrderData, setFormOrderData] = useState(null);
    const [openSection, setOpenSection] = useState(null);
    const [agentInformationFirstName, setAgentInformationFirstName] = useState('');
    const [agentInformationLastName, setAgentInformationLastName] = useState('');
    const [agentInformationCompanyName, setAgentInformationCompanyName] = useState('');
    const [agentInformationStreet, setAgentInformationStreet] = useState('');
    const [agentInformationCont, setAgentInformationCont] = useState('');
    const [apiData, setApiData] = useState(null);
    const [agentInformationCity, setAgentInformationCity] = useState('');
    const [isTermsVisible, setIsTermsVisible] = useState(false);
    const [agentInformationState, setAgentInformationState] = useState('');
    const [agentInformationZipcode, setAgentInformationZipcode] = useState('');
    const [registeredAgentInformation, setRegisteredAgentInformation] = useState("");
    const [agentInformationIndividualCompany, setAgentInformationIndividualCompany] = useState("individual");
    const [showCompanyName, setShowCompanyName] = useState(false);
      const [additionalServices, setAdditionalServices] = useState([]); 
    const [error, setError] = useState({});
    const [isSubmitting, setIsSubmitting] = useState(false);
    const navigate = useNavigate();

    const resultData = localStorage.getItem('formOrderResult');
    let extractedId = null;
    if (resultData) {
        const parsedResult = JSON.parse(resultData);
        extractedId = parsedResult.data.id;
        console.log('Extracted ID:', extractedId);
    }

    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await fetch(`${config.baseUrl}/registration/get-form-1/${extractedId}`);
                if (response.ok) {
                    const data = await response.json();
                    setApiData(data.data);

                    const fields = {
                        agentInformationFirstName: setAgentInformationFirstName,
                        agentInformationLastName: setAgentInformationLastName,
                        agentInformationStreet: setAgentInformationStreet,
                        agentInformationCont: setAgentInformationCont,
                        agentInformationCity: setAgentInformationCity,
                        agentInformationState: setAgentInformationState,
                        agentInformationZipcode: setAgentInformationZipcode,
                        agentInformationCompanyName: setAgentInformationCompanyName,
                    };

                    Object.entries(fields).forEach(([key, setter]) => {
                        if (data.data[key]) {
                            setter(data.data[key]);
                        }
                    });

                    if (data.data.registeredAgentInformation) {
                        setRegisteredAgentInformation(data.data.registeredAgentInformation);
                    } else {
                        // Set default to 'free' if registeredAgentInformation is empty
                        setRegisteredAgentInformation('free');
                    }

                    if (data.data.agentInformationIndividualCompany) {
                        setAgentInformationIndividualCompany(data.data.agentInformationIndividualCompany);
                        setShowCompanyName(data.data.agentInformationIndividualCompany === 'company'); // Automatically show company name input
                    }
                    if (data.data.additional_services) {
                        const parsedServices = JSON.parse(data.data.additional_services);
                        setAdditionalServices(parsedServices);
                    }
                } else {
                    console.error('Error fetching data:', response.statusText);
                }
            } catch (error) {
                console.error('Error:', error);
            }
        };

        fetchData();
    }, [extractedId]);


    const handleRegisterAgent = (e) => {
        const selectedValue = e.target.value;
    
        if (selectedValue === 'free') {
            // Reset all other fields when "free" is selected
            setAgentInformationFirstName('');
            setAgentInformationLastName('');
            setAgentInformationCompanyName('');
            setAgentInformationStreet('');
            setAgentInformationCont('');
            setAgentInformationCity('');
            setAgentInformationState('');
            setAgentInformationZipcode('');
            setAgentInformationIndividualCompany('individual');
            setShowCompanyName(false);
        }
    
        setRegisteredAgentInformation(selectedValue === 'free' ? 'free' : 'own registered agent');
    };
    
    const handleSubmit = async (e) => {
    
        e.preventDefault();
        setError({}); // Clear previous errors

        // Validate required fields
        const errors = {};
        if (registeredAgentInformation === 'own registered agent') {
            if (!agentInformationStreet) errors.agentInformationStreet = 'Street address is required.';
            if (!agentInformationCity) errors.agentInformationCity = 'City is required.';
            if (!agentInformationState) errors.agentInformationState = 'State is required.';
            if (!agentInformationZipcode) errors.agentInformationZipcode = 'Zipcode is required.';
    
            if (agentInformationIndividualCompany === 'company' && !agentInformationCompanyName) {
                errors.agentInformationCompanyName = 'Company name is required for company registration.';
            }
    
            if (agentInformationIndividualCompany === 'individual') {
                if (!agentInformationFirstName) errors.agentInformationFirstName = 'First name is required.';
                if (!agentInformationLastName) errors.agentInformationLastName = 'Last name is required.';
            }
        }

        if (Object.keys(errors).length > 0) {
            setError(errors);
            return; // Stop submission if there are errors
        }

        setIsSubmitting(true);

        const payload = {
            registeredAgentInformation,
            agentInformationIndividualCompany,
            agentInformationFirstName,
            agentInformationCompanyName,
            agentInformationLastName,
            agentInformationStreet,
            agentInformationCont,
            agentInformationCity,
            agentInformationState,
            agentInformationZipcode,
        };


        try {
            const response = await fetch(`${config.baseUrl}/registration/form-7/${extractedId}`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(payload),
            });

            if (response.ok) {
                const data = await response.json();
                navigate("/process-ss4");
                console.log('Form submission successful:', data);
            } else {
                console.error('Error in form submission:', response.statusText);
            }
        } catch (error) {
            console.error('Error:', error);
        } finally {
            setIsSubmitting(false);
        }
    };
    const handleRegisterAs = (e) => {
        const selectedValue = e.target.value;
        setAgentInformationIndividualCompany(selectedValue);
        setShowCompanyName(selectedValue === 'company'); // Toggle visibility of the company name field
    };
    const showFreeYear = registeredAgentInformation === 'free';
    const toggleSection = (section) => {
        setOpenSection(openSection === section ? null : section);
    };
    const toggleTerms = (e) => {
        e.preventDefault(); // Prevent form submission
        setIsTermsVisible(!isTermsVisible);
    };

    return (
        <>
            <Header />
            <section className="registration-from">
                <div className="container">
                    <div className="registerformstart">
                        <div className="row">
                            <div className="col-md-8">
                                <form onSubmit={handleSubmit}>
                                    <div className="register-left">
                                        <div class="box__heading">Registered Agent Information </div>
                                        <div id="" className="mb-0">
                                            <div className="mb-3"><strong>
                                                {" "}
                                                {apiData ? apiData.selected_state : ''} requires an {apiData ? apiData.selected_entity : ''} to appoint a Registered Agent:{" "}
                                            </strong></div>
                                            <ul className="list-unstyled">
                                                <li>
                                                    Only Corpquick offers{" "}
                                                    <b>1 full year of Registered Agent service FREE</b> with every
                                                    new business formation order - a $119.00 value!
                                                </li>
                                            </ul>
                                        </div>
                                        <div className="mb-0">
                                            <div className="box__heading">
                                                Typical documents received by your Registered Agent can include:
                                            </div>
                                            <ol className="list-unstyled list-agent">
                                                <li>
                                                    Service of Process, i.e. notification of a pending lawsuit or
                                                    court order
                                                </li>
                                                <li>State correspondence, i.e. annual reports or statements</li>
                                            </ol>
                                        </div>
                                        <div className="divider mb25" />
                                        <div className="custom_radio mb25 radio-btn11">
                                            <div>
                                                <input
                                                    type="radio"
                                                    id="freeRegisteredAgent"
                                                    name="registeredAgent"
                                                    value="free"
                                                    onChange={handleRegisterAgent}
                                                    checked={registeredAgentInformation === 'free'}
                                                />
                                                <label htmlFor="freeRegisteredAgent">
                                                    <span>Assign Corpquick as my Registered Agent FREE For 1 year.</span>
                                                </label>
                                            </div>
                                            <div>
                                                <input
                                                    type="radio"
                                                    id="ownRegisteredAgent"
                                                    name="registeredAgent"
                                                    value="own"
                                                    onChange={handleRegisterAgent}
                                                    checked={registeredAgentInformation === 'own registered agent'}
                                                />
                                                <label htmlFor="ownRegisteredAgent">
                                                    <span>I would like to act as my own registered agent.</span>
                                                </label>
                                            </div>
                                        </div>
                                    </div>

                                    {showFreeYear && (
                                        <div id="selectedFreeYear ">
                                            <div className="accordion " id="selectedFreeYear">
                                                <div className="accordion-item">
                                                    <div className="accordion-header">
                                                        <button
                                                            className={`accordion-button ${openSection === 'sectionOne' ? '' : 'collapsed'}`}
                                                            type="button"
                                                            onClick={() => toggleSection('sectionOne')}
                                                        >
                                                            Free First Year
                                                        </button>
                                                    </div>
                                                    <div
                                                        id="panelsStayOpen-collapseOne"
                                                        className={`accordion-collapse collapse ${openSection === 'sectionOne' ? 'show' : ''}`}
                                                    >
                                                        <div className="accordion-body">
                                                            Every new order for incorporation service includes 1 year of
                                                            FREE Registered Agent service. Service automatically renews
                                                            each year, but you may cancel at any time by changing your
                                                            agent and then contacting us.<button className="btn btn-link" onClick={toggleTerms}>
                                                                {isTermsVisible ? 'View Terms' : 'View Terms'}
                                                            </button>
                                                            {isTermsVisible && (
                                                                <p className="">
                                                                    The first year FREE Registered Agent Service begins on your date of
                                                                    formation when your business entity is legally active. The service
                                                                    renews automatically each year, billed to your card, for the annual
                                                                    service fee ($119), but you may cancel anytime by assigning a new
                                                                    registered agent with the state and notifying us of the change.
                                                                </p>
                                                            )}
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="accordion-item">
                                                    <div className="accordion-header">
                                                        <button
                                                            className={`accordion-button ${openSection === 'sectionTwo' ? '' : 'collapsed'}`}
                                                            type="button"
                                                            onClick={() => toggleSection('sectionTwo')}

                                                        >
                                                            Guaranteed Rates
                                                        </button>
                                                    </div>
                                                    <div
                                                        id="panelsStayOpen-collapseTwo"
                                                        className={`accordion-collapse collapse ${openSection === 'sectionTwo' ? 'show' : ''}`}
                                                    >
                                                        <div className="accordion-body">
                                                            After the first year your renewal rate will remain $119 per
                                                            year and is guaranteed for the life of the service. When
                                                            your renewal is due you will be notified and be allowed to
                                                            decide if renewing the service is in your best interests.
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="accordion-item">
                                                    <div className="accordion-header">
                                                        <button
                                                            className={`accordion-button ${openSection === 'sectionThree' ? '' : 'collapsed'}`}
                                                            type="button"
                                                            onClick={() => toggleSection('sectionThree')}
                                                        >
                                                            All-Inclusive
                                                        </button>
                                                    </div>
                                                    <div
                                                        id="panelsStayOpen-collapseThree"
                                                        className={`accordion-collapse collapse ${openSection === 'sectionThree' ? 'show' : ''}`}
                                                    >
                                                        <div className="accordion-body">
                                                            We never charge a dime in additional fees for postage paid
                                                            to deliver you your company's important documents.
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="accordion-item">
                                                    <div className="accordion-header">
                                                        <button
                                                            className={`accordion-button ${openSection === 'sectionFour' ? '' : 'collapsed'}`}
                                                            type="button"
                                                            onClick={() => toggleSection('sectionFour')}
                                                        >
                                                            Reduce Junk Mail
                                                        </button>
                                                    </div>
                                                    <div
                                                        id="panelsStayOpen-collapseForth"
                                                        className={`accordion-collapse collapse ${openSection === 'sectionFour' ? 'show' : ''}`}
                                                    >
                                                        <div className="accordion-body">
                                                            Many companies will acquire distribution lists of new
                                                            companies and use the registered agent address to send
                                                            annoying solicitation mail. By having a registered agent
                                                            address you can reduce the amount of junk mail received.
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>

                                        </div>
                                    )}

                                    <div id="agentInformation" style={{ display: showFreeYear ? 'none' : 'block' }}>
                                        <div className='box__heading'>Agent Information </div>
                                        <div className="divider mb25" />
                                        <p>You may serve as your own Registered Agent if you have a physical address in the state of formation.</p>

                                        <div className="custom_radio mb25">
                                            <div>
                                                <input
                                                    type="radio"
                                                    id="RegisteredAsIndividual"
                                                    name="registeredAs"
                                                    value="individual"
                                                    onChange={handleRegisterAs}
                                                    checked={agentInformationIndividualCompany === 'individual'}
                                                />
                                                <label htmlFor="RegisteredAsIndividual">
                                                    <span>Individual</span>
                                                    <span>
                                                        The registered agent will be an individual.
                                                    </span>
                                                </label>
                                            </div>
                                            <div>
                                                <input
                                                    type="radio"
                                                    id="RegisteredAsCompany"
                                                    name="registeredAs"
                                                    value="company"
                                                    onChange={handleRegisterAs}
                                                    checked={agentInformationIndividualCompany === 'company'}
                                                />
                                                <label htmlFor="RegisteredAsCompany">
                                                    <span>Company</span>
                                                    <span>
                                                        The registered agent will be a company.
                                                    </span>
                                                </label>
                                            </div>
                                        </div>


                                        {showCompanyName ? (
                                            <div className="form-group" id="">
                                                <label>Company Name</label>
                                                <input
                                                    type="text"
                                                    className="form-control"
                                                    id="agentInformationCompanyName"
                                                    name="agentInformationCompanyName"
                                                    value={agentInformationCompanyName}
                                                    onChange={(e) => setAgentInformationCompanyName(e.target.value.trimStart())}
                                                />
                                                {error.agentInformationCompanyName && (
                                                    <div className="error-message">{error.agentInformationCompanyName}</div>
                                                )}
                                            </div>
                                        ) : (
                                            <div className="twoBox">
                                                <div className="form-group" id="firstNameBlock">
                                                    <label>First Name</label>
                                                    <input
                                                        type="text"
                                                        className="form-control"
                                                        id="firstName"
                                                        value={agentInformationFirstName}
                                                        onChange={(e) => setAgentInformationFirstName(e.target.value.trimStart())}
                                                
                                                    />
                                                    {error.agentInformationFirstName && (
                                                        <div className="error-message">{error.agentInformationFirstName}</div>
                                                    )}
                                                </div>
                                                <div className="form-group" id="lastNameBlock">
                                                    <label>Last Name</label>
                                                    <input
                                                        type="text"
                                                        className="form-control"
                                                        id="lastName"
                                                        value={agentInformationLastName}
                                                        onChange={(e) => setAgentInformationLastName(e.target.value.trimStart())}
                                                  
                                                    />
                                                    {error.agentInformationLastName && (
                                                        <div className="error-message">{error.agentInformationLastName}</div>
                                                    )}
                                                </div>
                                            </div>
                                        )}
                                        <div class="twoBox">
                                            <div class="form-group" id="firstNameBlock">
                                                <label>Street Address</label>
                                                <input
                                                    type="text"
                                                    class="form-control" id="streetAddress" name="streetAddress"
                                                    value={agentInformationStreet}
                                                    onChange={(e) => setAgentInformationStreet(e.target.value.trimStart())}
                                                  
                                                />
                                                {error.agentInformationStreet && (
                                                    <div className="error-message">{error.agentInformationStreet}</div>
                                                )}
                                            </div>
                                            <div class="form-group" id="firstNameBlock">
                                                <label>Address (Cont)</label>
                                                <input
                                                    type="text"
                                                    class="form-control" id="addressContOptional" name="addressContOptional"
                                                    value={agentInformationCont}
                                                    onChange={(e) => setAgentInformationCont(e.target.value.trimStart())}
                                               
                                                />
                                                {error.agentInformationCont && (
                                                    <div className="error-message">{error.agentInformationCont}</div>
                                                )}
                                            </div>
                                        </div>
                                        <div class="form-group" id="firstNameBlock">
                                            <label>City</label>
                                            <input
                                                type="text"
                                                class="form-control" id="city" name="city"
                                                value={agentInformationCity}
                                                onChange={(e) => setAgentInformationCity(e.target.value.trimStart())}
                                           
                                            />
                                            {error.agentInformationCity && (
                                                <div className="error-message">{error.agentInformationCity}</div>
                                            )}
                                        </div>
                                        <div className="twoBox">
                                            <div class="form-group" id="firstNameBlock">
                                                <label>State</label>
                                                <input
                                                    type="text"
                                                    class="form-control" id="state" name="state"
                                                    value={agentInformationState}
                                                    onChange={(e) => setAgentInformationState(e.target.value.trimStart())}
                                                  
                                                />
                                                {error.agentInformationState && (
                                                    <div className="error-message">{error.agentInformationState}</div>
                                                )}
                                            </div>
                                            <div className="form-group" id="firstNameBlock">
                                                <label>Zip Code</label>
                                                <input
                                                    type="number"
                                                    className="form-control"
                                                    id="zipCode"
                                                    name="zipCode"
                                                    value={agentInformationZipcode}
                                                    onChange={(e) => {
                                                        // Restrict to numbers only
                                                        const value = e.target.value;
                                                        if (/^[0-9]*$/.test(value)) {
                                                            setAgentInformationZipcode(value); // Set the value only if it is a number
                                                        }
                                                    }}
                                                    pattern="^[0-9]+$"  // Only numbers allowed
                                                    title="Please enter a valid zip code (numbers only)"
                                                />
                                                {error.agentInformationZipcode && (
                                                    <div className="error-message">{error.agentInformationZipcode}</div>
                                                )}
                                            </div>

                                        </div>
                                    </div>

                                    <div className="button-nest-prev">
                                        <div className="left-next">
                                       
                                            <Link to="/process-member">
                                            <button type="button">
                                                <i className="fa fa-arrow-left" aria-hidden="true" /> Back
                                                </button>
                                            </Link>
                                         
                                        </div>
                                        <div className="right-next nex2">
                                            <button type="submit" disabled={isSubmitting}>
                                                Next
                                            </button>
                                        </div>
                                    </div>
                                </form>
                            </div>
                            <div className="col-md-4">
                                <div className="register-right">
                                    <h4>Order Summary</h4>
                                    <h2>{apiData ? apiData.full_business_name : ''}</h2>
                                    <div className="progress">
                                        <div
                                            className="progress-bar"
                                            role="progressbar"
                                            style={{ width: "55%" }}
                                            aria-valuenow={55}
                                            aria-valuemin={0}
                                            aria-valuemax={100}
                                        >
                                            55%
                                        </div>
                                    </div>
                                    <div className="order-items flex flex-col gap-3 mt-8">
                                    <div className="item">
                                            <div className="name">{apiData ? apiData.package : ''} Package</div>
                                            <div className="value ">${apiData ? apiData.package_fee : ''}</div>
                                        </div>
                                        {additionalServices.map((service, index) => (
                                            <div className="item" key={index}>
                                                <div className="name">{service.name}</div>
                                                <div className="value">${service.price}</div>
                                            </div>
                                        ))}
                                        <div className="item">
                                            <div className="name">{apiData ? apiData.selected_state : ''} State Filing Fee</div>
                                            <div className="value">
                                                <span id="dynamic-filing-fee">${apiData ? apiData.stateFee : ''}</span>
                                            </div>
                                        </div>
                                        {apiData?.companyAddress === "Professional Business Address" && (
                                            <div className="item" id="order-item-legal-consulation">
                                                <div className="name">Business Address</div>
                                                <div className="value">
                                                    $<span id="dynamic-filing-fee">29</span>
                                                </div>
                                            </div>
                                        )}
                                        <div className="item hide" id="order-item-legal-consulation">
                                            <div className="name">Banking Resolution</div>
                                            <div className="value">
                                                <i className="fa fa-check" aria-hidden="true" />
                                            </div>
                                        </div>
                                        <div className="item hide" id="order-item-expedited">
                                            <div className="name">Banking Resolution</div>
                                            <div className="value">
                                                <i className="fa fa-check" aria-hidden="true" />
                                            </div>
                                        </div>
                                        <div className="item hide" id="order-item-legal-consulation">
                                            <div className="name">Minutes Meeting</div>
                                            <div className="value">
                                                <i className="fa fa-check" aria-hidden="true" />
                                            </div>
                                        </div>
                                        <div className="item hide" id="order-item-license-package">
                                            <div className="name">Bylaws</div>
                                            <div className="value">
                                                <i className="fa fa-check" aria-hidden="true" />
                                            </div>
                                        </div>

                                        {registeredAgentInformation === 'free' && (
                                            <div className="item" id="order-item-legal-consulation">
                                                <div className="name">Registered Agent (1st Year)</div>
                                                <div className="value">
                                                    Free
                                                </div>
                                            </div>
                                        )}

                                        <div className="item hide" id="order-item-va">
                                            <div className="name">EIN / Tax ID #</div>
                                            <div className="value">
                                                <i className="fa fa-check" aria-hidden="true" />
                                            </div>
                                        </div>
                                        <div className="item">
                                            <div className="name">Electronic Delivery</div>
                                            <div className="value">
                                                <i className="fa fa-check" aria-hidden="true" />
                                            </div>
                                        </div>
                                        <div className="item">
                                            <div className="name">Form 2553</div>
                                            <div className="value">
                                                <i className="fa fa-check" aria-hidden="true" />
                                            </div>
                                        </div>

                                        <div className="item">
                                            <div className="name flex flex-col">
                                                <span>Phone Support</span>
                                                <span className="text-xs text-gray-700 mt-[6px]">
                                                    Available Monday - Friday from 9 a.m. to 6 p.m. CST
                                                </span>
                                            </div>
                                            <div className="value">
                                                <i className="fa fa-check" aria-hidden="true" />
                                            </div>
                                        </div>
                                        <hr className="!mt-1 !mb-1" />
                                        <div className="totla-value1">
                                            <div className="item items-center mb-8">
                                                <div className="name">Total</div>
                                                <div className="value bold text-black">
                                                    <span className="SumPackageTotal text-2xl">
                                                        $<span className="totalPrice">
                                                            {apiData
                                                                ? parseFloat(apiData.form_total_price || 0) +
                                                                (apiData.companyAddress === "Professional Business Address" ? 29 : 0)
                                                                : ''}
                                                        </span>
                                                    </span>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="trustpilot">
                                            <img src="images/Trustpilot-Reviews-Services-1.png" alt="" />
                                            <p>TrustScore 4.7 | 19,076 reviews</p>
                                        </div>
                                   
                                        <div className="join-business">
                                            <div className="font-radwave text-black">
                                                <h2 className="text-base tracking-wide text-left  my-0">
                                                    JOIN THE
                                                </h2>
                                                <p className="text-base text-red-500 tracking-wide ">
                                                    1,000,000+
                                                </p>
                                                <h3 className="text-base tracking-wide uppercase my-0">
                                                    Businesses
                                                </h3>
                                                <h6>
                                                    That have trusted Corpquick to start, manage, and grow their
                                                    business{" "}
                                                </h6>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <Footer />
        </>
    );
};

export default ProcessAgent;
