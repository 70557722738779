import React from 'react';
import { Link } from 'react-router-dom';

const Footer = () => {
  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth' // Optional: Adds smooth scrolling
    });
  };
  return (
    <footer>
      <div className="footer-section">
        <div className="container-fluid">
          <div className="row">

            <div className="col-md-1">
              <div className="footer-logo">
                <img src="images/footerlogo.png" alt="" />
              </div>
            </div>

            <div className="col-md-11 footerright">
              <div className="footer-menu">
                <div className="row">
                  <div className="col-md-4">
                    <h5>Support</h5>
                    <ul>
                      <li>
                        <Link to="/contact-us">Contact</Link>
                      </li>
                      <li>
                        <Link to="/affiliates">Affiliates</Link>
                      </li>
                      {/* <li>
                        <Link to="#" onClick={scrollToTop}>Sitemap</Link>
                      </li> */}
                      <li>
                        <Link to="/cancellation-policy">Cancellation Policy</Link>
                      </li>
                      <li>
                        <Link to="/privacy">Privacy Policy</Link>
                      </li>
                      <li>
                        <Link to="/disclamer">Legal Disclaimer</Link>
                      </li>
                      {/* <li>
                    <Link to="#">Glossary</Link>
                  </li>
                  <li>
                    <Link to="#">Cookie Settings</Link>
                  </li> */}
                    </ul>
                  </div>
                  <div className="col-md-4">
                    <h5>services</h5>
                    <ul>
                      <li>
                        <Link to="/services">Registered Agent</Link>
                      </li>
                      <li>
                        <Link to="/services" >Annual Report</Link>
                      </li>
                      <li>
                        <Link to="/services" >Certificate of Good Standing</Link>
                      </li>
                      <li>
                        <Link to="/services">Change Of Registered Agent</Link>
                      </li>
                      <li>
                        <Link to="/services">Amendment</Link>
                      </li>
                      {/*    <li>
                    <Link to="#">Dissolution</Link>
                  </li> */}
                      <li>
                        <Link to="/services">Get an EIN / TAX Number</Link>
                      </li>
                      <li>
                        <Link to="/services" >Business License Search</Link>
                      </li>
                    </ul>
                  </div>
                  <div className="col-md-4">
                    <h5>get in touch</h5>
                    <h4>(804) 916-1974</h4>
                    <p>
                      Monday – Saturday: 8AM – 6PM
                      <br />
                      Sunday: OFF DAY
                    </p>
                    <div className="addresss">
                      <p>901 N Pitt Str., Suite 170 Alexandria, USA</p>
                    </div>
                  </div>
                </div>
                <div></div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="copt-right">
        <div className="container-fluid">
          <div className="row">
            <div className="col-md-1"></div>
            <div className="col-md-11 copy">
              <p>© 2025 CorpQuick. All rights reserved. | <Link to="/privacy" style={{ color: 'white' }}>Privacy Policy</Link> | <Link to="/disclamer" style={{ color: 'white' }}>Legal Disclamer</Link>
              </p>

            </div>
          </div>
        </div>
      </div>
    </footer>

  )
}

export default Footer
