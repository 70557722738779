import React from 'react';
import Header from './Components/Common/Header';
import Footer from './Components/Common/Footer';

const NotFound = () => {
  return (
    <>
    <Header/>
      <div className="nothing">
        <div className='nothing1'>
          <h2>404</h2>
          <h4>!Opps Nothing Found Here</h4>
        </div>
      </div>
      <Footer/>
    </>
  )
}

export default NotFound

