import React from 'react';
import { Link } from 'react-router-dom';

const Straightforward = () => {
  return (
    <div className="stright-formward">
    <div className="container">
      <div className="row">
        <div className="col-md-4">
          <div className="strainght-content">
            <h2>Straightforward Pricing No Hidden Charges</h2>
            <p>
              Incorporating your business with CorpQuick is free—you only pay the
              state fee. Customize your package with additional services to suit
              your business requirements.
            </p>
            < Link to="/form-order">
            <button type="button">View formation Packages</button>
            </Link>
          </div>
        </div>
        <div className="col-md-8">
          <div className="box-stright">
            <div className="row">
              <div className="col-md-6 col-6">
                <div className="strght-con1">
                  <div className="left-stright">
                    <img src="images/icon1.png" alt=""/>
                  </div>
                  <div className="right-stright">
                    <h5>100% FREE Formation</h5>
                  </div>
                </div>
              </div>
              <div className="col-md-6 col-6">
                <div className="strght-con1">
                  <div className="left-stright">
                    <img src="images/icon4.png" alt=""/>
                  </div>
                  <div className="right-stright">
                    <h5>Super Fast Filing Speeds</h5>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="box-stright">
            <div className="row">
              <div className="col-md-6 col-6">
                <div className="strght-con1">
                  <div className="left-stright">
                    <img src="images/icon3.png" alt=""/>
                  </div>
                  <div className="right-stright">
                    <h5>First Year FREE Registered Agent</h5>
                  </div>
                </div>
              </div>
              <div className="col-md-6 col-6">
                <div className="strght-con1">
                  <div className="left-stright">
                    <img src="images/icon2.png" alt=""/>
                  </div>
                  <div className="right-stright">
                    <h5>Free Compliance Reminders</h5>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  
  )
}

export default Straightforward
