import React, { useState, useEffect } from 'react';
import config from '../../config';

const Package = () => {
  const [entityNames, setEntityNames] = useState([]);
  const [stateNames, setStateNames] = useState([]);
  const [selectedEntityId, setSelectedEntityId] = useState("");
  const [selectedEntityName, setSelectedEntityName] = useState("");
  const [selectedStateName, setSelectedStateName] = useState("");
  const [stateFee, setStateFee] = useState(0);
  const [selectedPackage, setSelectedPackage] = useState("Standard");
  const [selectedFilingType, setSelectedFilingType] = useState("Select");
  const [selectedStateId, setSelectedStateId] = useState("");
  const [filingOptions, setFilingOptions] = useState([]);
  const [selectedFiling, setSelectedFiling] = useState("");
  const [selectedOption, setSelectedOption] = useState("");
  const [planData, setPlanData] = useState([]);
  const [additionalData, setAdditionalData] = useState([]);
  const [toggledButtons, setToggledButtons] = useState({});
  const [selectedServices, setSelectedServices] = useState([]);


  const url = new URL(window.location.href);
  const entityParam = url.searchParams.get("entity");
  const stateParam = url.searchParams.get("state");
  const resultData = localStorage.getItem('formOrderResult');
  const [apiData, setApiData] = useState(null);
  let extractedId = null;

  if (resultData) {
    const parsedResult = JSON.parse(resultData);
    extractedId = parsedResult.data.id; // Accessing the id
    console.log('Extracted ID:', extractedId); // Log the extracted ID
  }

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch(`${config.baseUrl}/registration/get-form-1/${extractedId}`);
        if (response.ok) {
          const data = await response.json();
          const apiData = data.data;

          setApiData(apiData); // Store the API data in state
          console.log('Fetched API Data:', apiData);

          // Set default values for the form fields
          if (apiData) {
            setSelectedEntityId(
              entityNames.find((e) => e.entity_name === apiData.selected_entity)?.id || ""
            );
            setSelectedStateId(
              stateNames.find((s) => s.state_name === apiData.selected_state)?.id || ""
            );
            setSelectedFiling(apiData.select_filling_time);
            setStateFee(Number(apiData.stateFee || 0));
            setSelectedPackage(apiData.package);

            // Determine the filing type based on select_filling_time
            const filingType = apiData.select_filling_time.toLowerCase().includes("days")
              ? "Standard"
              : "Expedited";
            setSelectedFilingType(filingType);

            // Parse additional_services JSON string and set as default
            const additionalServicesArray = JSON.parse(apiData.additional_services || "[]");
            setSelectedServices(additionalServicesArray);

            // Initialize toggledButtons state to reflect selected services
            const toggledState = {};
            additionalServicesArray.forEach((service) => {
              additionalData.forEach((item) => {
                item.items.forEach((subItem) => {
                  if (
                    subItem.price === service.price &&
                    item.name === service.name &&
                    apiData.package === subItem.plan_name // Match the package
                  ) {
                    toggledState[subItem.id] = { active: true };
                  }
                });
              });
            });
            setToggledButtons(toggledState);
          }
        } else {
          console.error('Error fetching data:', response.statusText);
        }
      } catch (error) {
        console.error('Error:', error);
      }
    };

    fetchData();
  }, [entityNames, stateNames, additionalData]);


  useEffect(() => {
    // Fetch entity and state names
    Promise.all([
      fetch(`${config.baseUrl}/entity/list`).then((res) => res.json()),
      fetch(`${config.baseUrl}/state/list`).then((res) => res.json()),
    ])
      .then(([entityData, stateData]) => {
        if (entityData.status === "success") {
          setEntityNames(entityData.data);
          const defaultEntity = entityData.data.find(
            (entity) => entity.id === parseInt(entityParam)
          );
          if (defaultEntity) {
            setSelectedEntityId(defaultEntity.id);
            setSelectedEntityName(defaultEntity.entity_name);
          }
        } else {
          throw new Error("Failed to fetch entity names");
        }

        if (stateData.status === "success") {
          setStateNames(stateData.data);
          const defaultState = stateData.data.find(
            (state) => state.id === parseInt(stateParam)
          );
          if (defaultState) {
            setSelectedStateId(defaultState.id);
            setSelectedStateName(defaultState.state_name);
          }
        } else {
          throw new Error("Failed to fetch state names");
        }
      })
      .catch((error) => console.error(error));
  }, [entityParam, stateParam]);

  useEffect(() => {
    // Fetch data from the API
    fetch(`${config.baseUrl}/business/plan`)
      .then((res) => res.json())
      .then((data) => {
        if (data.status === "success") {
          setPlanData(data.data); // Update state with API data
        } else {
          throw new Error("Failed to fetch data");
        }
      })
      .catch((error) => console.error("Failed to fetch API data", error));
  }, []);
  useEffect(() => {
    // Fetch data from the API
    fetch(`${config.baseUrl}/business/packages`)
      .then((res) => res.json())
      .then((data) => {
        if (data.status === "success") {
          setAdditionalData(data.data); // Update state with API data
        } else {
          throw new Error("Failed to fetch data");
        }
      })
      .catch((error) => console.error("Failed to fetch API data", error));
  }, []);

  useEffect(() => {
    if (selectedEntityId && selectedStateId) {
      const endpoint =
        selectedFilingType === "Standard"
          ? `${config.baseUrl}/state/standard-filing`
          : `${config.baseUrl}/state/expedited-filing`;

      fetch(endpoint, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          entity_id: selectedEntityId,
          state_id: selectedStateId,
        }),
      })
        .then((response) => response.json())
        .then((data) => {
          if (data.status === "success") {
            setFilingOptions(data.data);

            // Find the fee for the selected option
            const selectedOptionData = data.data.find(
              (option) => option.days_name === selectedOption
            );
            if (selectedOptionData) {
              setStateFee(selectedOptionData.state_fee);
            } else {
              setStateFee(0);
            }
          } else {
            setStateFee(0);
          }
        })
        .catch((error) => console.error("Failed to fetch state fee", error));
    } else {
      setStateFee(0);
    }
  }, [selectedEntityId, selectedStateId, selectedFilingType, selectedOption]);

  const stateFeeTotal = () => {
    return stateFee;
  };


  const getPrice = () => {
    const selectedFiling = planData.find((filing) => filing.plan_name === selectedPackage);
    return selectedFiling ? selectedFiling.price : 0; // Return the price if found, otherwise 0
  };
  const handlePackageChange = (newPackage) => {
    setSelectedPackage(newPackage);
    setSelectedServices([]); // Clear additional services when package changes
    setToggledButtons({}); // Reset the toggled buttons state
  };

  return (
    <>
      <div className="comparion-part">
        <div className="container">
          <div className="table-responsive">
            <table style={{ width: "100%" }}>
              <tbody>
                <tr>
                  <th />

                  {planData.map((filing) => (
                    <th>

                      <div
                        className="table-btn"
                        style={{ textAlign: "center" }}
                      >
                        <button
                          type="button"
                          className={`basic-btn2 ${selectedPackage === filing.plan_name ? "btn-red-600" : ""}`}
                          onClick={() => handlePackageChange(filing.plan_name)} // Use a function to handle package change
                        >
                          {filing.plan_name} Filing Time
                        </button>

                      </div>
                    </th>
                  ))}
                </tr>
                <tr
                  style={{
                    width: "100%",
                    backgroundColor: "var(--white)",
                    borderRadius: 4,
                  }}
                >
                  <th style={{ padding: "18px 20px", borderRadius: 6 }}>
                    <h2>Compare Packages</h2>
                    <h6>
                      <strong>One-time fee</strong>
                    </h6>
                    <p>
                      Unlike other companies that charge annual fees, our
                      business formation fee is one-time.
                    </p>
                  </th>
                  {planData.map((filing) => (
                    <th
                      style={{ padding: "18px 20px", textAlign: "center" }}
                      className={
                        selectedPackage === `${filing.plan_name}`
                          ? ""
                          : ""
                      }
                      key={filing.plan_name}
                    >
                      <p>
                        <strong>Select Package</strong>
                      </p>
                      <h5>
                        <span>${filing.price}</span> + State Fee
                      </h5>

                    </th>
                  ))}
                </tr>
                {additionalData.map((item) => (
                  <tr style={{ backgroundColor: "var(--white)" }} key={item.id}>
                    <td style={{ padding: 15, borderRadius: 6 }}>
                      <h6
                        style={{
                          color: "var(--black)",
                          marginBottom: 0,
                          fontSize: 14,
                        }}
                      >
                        {item.name}
                      </h6>
                      <div className="select-box">{item.description}</div>
                    </td>

                    {planData.map((filing) => (
                      <td
                        className={
                          selectedPackage === `${filing.plan_name}` ? "" : ""
                        }
                        style={{ padding: "18px 20px", textAlign: "center" }}
                        key={filing.plan_name}
                      >
                        <div>
                          <span>
                            {item.items
                              .filter((subItem) => subItem.plan_name === filing.plan_name)
                              .map((subItem) => (
                                subItem.price === 0 ? (
                                  <img src="images/Vector (2).png" alt="No Data" key={subItem.id} />
                                ) : (
                                  <button

                                  >
                                    {toggledButtons[subItem.id]?.active
                                      ? ` $${subItem.price}`
                                      : ` $${subItem.price}`}
                                  </button>
                                )
                              ))}
                          </span>
                        </div>
                      </td>
                    ))}
                  </tr>
                ))}

              </tbody>
            </table>
          </div>
        </div>
        </div>
    </>
  )
}

export default Package
