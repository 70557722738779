import React, { useState, useEffect } from "react";
import Header from "../Common/Header";
import Footer from "../Common/Footer";
import config from "../../config";
import { useNavigate } from "react-router-dom";

const FormOrder = () => {
  const [entityNames, setEntityNames] = useState([]);
  const [stateNames, setStateNames] = useState([]);
  const [selectedEntityId, setSelectedEntityId] = useState("");
  const [selectedEntityName, setSelectedEntityName] = useState("");
  const [selectedStateName, setSelectedStateName] = useState("");
  const [stateFee, setStateFee] = useState(0);
  const [selectedPackage, setSelectedPackage] = useState("Standard");
  const [selectedFilingType, setSelectedFilingType] = useState("Select");
  const [selectedStateId, setSelectedStateId] = useState("");
  const [filingOptions, setFilingOptions] = useState([]);
  const [selectedFiling, setSelectedFiling] = useState("");
  const [selectedOption, setSelectedOption] = useState("");
  const [planData, setPlanData] = useState([]);
  const [additionalData, setAdditionalData] = useState([]);
  const [toggledButtons, setToggledButtons] = useState({});
  const [selectedServices, setSelectedServices] = useState([]);
  const [entityError, setEntityError] = useState(false);
  const [stateError, setStateError] = useState(false);
  const [filingError, setFilingError] = useState(false);
  const [filingTypeError, setFilingTypeError] = useState(false);

  let navigate = useNavigate();

  const url = new URL(window.location.href);
  const entityParam = url.searchParams.get("entity");
  const stateParam = url.searchParams.get("state");
  const smallSelectedPackage = selectedPackage.toLowerCase();
  const resultData = localStorage.getItem('formOrderResult');
  const [apiData, setApiData] = useState(null);
  let extractedId = null;

  if (resultData) {
    const parsedResult = JSON.parse(resultData);
    extractedId = parsedResult.data.id; // Accessing the id
    console.log('Extracted ID:', extractedId); // Log the extracted ID
  }

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch(`${config.baseUrl}/registration/get-form-1/${extractedId}`);
        if (response.ok) {
          const data = await response.json();
          const apiData = data.data;

          setApiData(apiData); // Store the API data in state
          console.log('Fetched API Data:', apiData);

          // Set default values for the form fields
          if (apiData) {
            setSelectedEntityId(
              entityNames.find((e) => e.entity_name === apiData.selected_entity)?.id || ""
            );
            setSelectedStateId(
              stateNames.find((s) => s.state_name === apiData.selected_state)?.id || ""
            );
            setSelectedFiling(apiData.select_filling_time);
            setStateFee(Number(apiData.stateFee || 0));
            setSelectedPackage(apiData.package);

            // Determine the filing type based on select_filling_time
            const filingType = apiData.select_filling_time.toLowerCase().includes("days")
              ? "Standard"
              : "Expedited";
            setSelectedFilingType(filingType);

            // Parse additional_services JSON string and set as default
            const additionalServicesArray = JSON.parse(apiData.additional_services || "[]");
            setSelectedServices(additionalServicesArray);

            // Initialize toggledButtons state to reflect selected services
            const toggledState = {};
            additionalServicesArray.forEach((service) => {
              additionalData.forEach((item) => {
                item.items.forEach((subItem) => {
                  if (
                    subItem.price === service.price &&
                    item.name === service.name &&
                    apiData.package === subItem.plan_name // Match the package
                  ) {
                    toggledState[subItem.id] = { active: true };
                  }
                });
              });
            });
            setToggledButtons(toggledState);
          }
        } else {
          console.error('Error fetching data:', response.statusText);
        }
      } catch (error) {
        console.error('Error:', error);
      }
    };

    fetchData();
  }, [entityNames, stateNames, additionalData]);


  useEffect(() => {
    // Fetch entity and state names
    Promise.all([
      fetch(`${config.baseUrl}/entity/list`).then((res) => res.json()),
      fetch(`${config.baseUrl}/state/list`).then((res) => res.json()),
    ])
      .then(([entityData, stateData]) => {
        if (entityData.status === "success") {
          setEntityNames(entityData.data);
          const defaultEntity = entityData.data.find(
            (entity) => entity.id === parseInt(entityParam)
          );
          if (defaultEntity) {
            setSelectedEntityId(defaultEntity.id);
            setSelectedEntityName(defaultEntity.entity_name);
          }
        } else {
          throw new Error("Failed to fetch entity names");
        }

        if (stateData.status === "success") {
          setStateNames(stateData.data);
          const defaultState = stateData.data.find(
            (state) => state.id === parseInt(stateParam)
          );
          if (defaultState) {
            setSelectedStateId(defaultState.id);
            setSelectedStateName(defaultState.state_name);
          }
        } else {
          throw new Error("Failed to fetch state names");
        }
      })
      .catch((error) => console.error(error));
  }, [entityParam, stateParam]);

  useEffect(() => {
    // Fetch data from the API
    fetch(`${config.baseUrl}/business/plan`)
      .then((res) => res.json())
      .then((data) => {
        if (data.status === "success") {
          setPlanData(data.data); // Update state with API data
        } else {
          throw new Error("Failed to fetch data");
        }
      })
      .catch((error) => console.error("Failed to fetch API data", error));
  }, []);
  useEffect(() => {
    // Fetch data from the API
    fetch(`${config.baseUrl}/business/packages`)
      .then((res) => res.json())
      .then((data) => {
        if (data.status === "success") {
          setAdditionalData(data.data); // Update state with API data
        } else {
          throw new Error("Failed to fetch data");
        }
      })
      .catch((error) => console.error("Failed to fetch API data", error));
  }, []);

  useEffect(() => {
    if (selectedEntityId && selectedStateId) {
      const endpoint =
        selectedFilingType === "Standard"
          ? `${config.baseUrl}/state/standard-filing`
          : `${config.baseUrl}/state/expedited-filing`;

      fetch(endpoint, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          entity_id: selectedEntityId,
          state_id: selectedStateId,
        }),
      })
        .then((response) => response.json())
        .then((data) => {
          if (data.status === "success") {
            setFilingOptions(data.data);

            // Find the fee for the selected option
            const selectedOptionData = data.data.find(
              (option) => option.days_name === selectedOption
            );
            if (selectedOptionData) {
              setStateFee(selectedOptionData.state_fee);
            } else {
              setStateFee(0);
            }
          } else {
            setStateFee(0);
          }
        })
        .catch((error) => console.error("Failed to fetch state fee", error));
    } else {
      setStateFee(0);
    }
  }, [selectedEntityId, selectedStateId, selectedFilingType, selectedOption]);

  const stateFeeTotal = () => {
    return stateFee;
  };

  const handleEntityChange = (event) => {
    const selectedId = event.target.value ? parseInt(event.target.value) : "";
    setSelectedEntityId(selectedId);
    const selectedEntity = entityNames.find(
      (entity) => entity.id === selectedId
    );
    setSelectedEntityName(selectedEntity ? selectedEntity.entity_name : "");

    // Remove the error message immediately when a valid option is selected
    if (selectedId) {
      setEntityError(false);
    }

    // Reset related states when the entity changes
    setSelectedFilingType("Select");
    setSelectedFiling("");
    setStateFee(0);
    setFilingOptions([]);
  };

  const handleStateChange = (event) => {
    const selectedId = event.target.value ? parseInt(event.target.value) : "";
    setSelectedStateId(selectedId);
    const selectedState = stateNames.find((state) => state.id === selectedId);
    setSelectedStateName(selectedState ? selectedState.state_name : "");

    // Remove the error message immediately when a valid option is selected
    if (selectedId) {
      setStateError(false);
    }

    // Reset related states when the state changes
    setSelectedFilingType("Select");
    setSelectedFiling("");
    setStateFee(0);
    setFilingOptions([]);
  };

  const handleFilingTypeChange = (event) => {
    const selectedType = event.target.value;
    setSelectedFilingType(selectedType);

    // Remove the error message immediately when a valid option is selected
    if (selectedType !== "Select") {
      setFilingTypeError(false);
    }

    // Reset selectedFiling and filingOptions when filing type changes
    setSelectedFiling("");
    setFilingOptions([]);
  };

  const handleFilingChange = (event) => {
    const selectedType = event.target.value;
    const selectedFilingName = event.target.value;
    setSelectedFiling(selectedFilingName);

    // Find the fee for the selected option
    const selectedOptionData = filingOptions.find(
      (option) => option.days_name === selectedFilingName
    );
    if (selectedType !== "Select") {
      setFilingError(false);
    }
    if (selectedOptionData) {
      setStateFee(selectedOptionData.state_fee);
    } else {
      setStateFee(0);
    }
  };

  const getPrice = () => {
    const selectedFiling = planData.find((filing) => filing.plan_name === selectedPackage);
    return selectedFiling ? selectedFiling.price : 0; // Return the price if found, otherwise 0
  };
  const handlePackageChange = (newPackage) => {
    setSelectedPackage(newPackage);
    setSelectedServices([]); // Clear additional services when package changes
    setToggledButtons({}); // Reset the toggled buttons state
  };




  const getTotalPrice = () => {
    const additionalServicesTotal = selectedServices.reduce(
      (sum, service) => sum + service.price,
      0
    );
    return getPrice() + stateFeeTotal() + additionalServicesTotal;
  };
  const handleSubmit = async (event) => {
    event.preventDefault(); // Prevent default form submission behavior

    // Scroll to the top of the page

    let isValid = true;

    if (!selectedEntityId) {
      setEntityError(true);
      window.scrollTo({ top: 0, behavior: 'smooth' });
      isValid = false;
    } else {
      setEntityError(false);
    }

    if (!selectedStateId) {
      setStateError(true);
      window.scrollTo({ top: 0, behavior: 'smooth' });
      isValid = false;
    } else {
      setStateError(false);
    }
    if (!selectedFilingType) {
      setFilingTypeError(true);
      window.scrollTo({ top: 0, behavior: 'smooth' });
      isValid = false;
    } else {
      setFilingTypeError(false);
    }

    if (!selectedFiling) {
      setFilingError(true);
      window.scrollTo({ top: 0, behavior: 'smooth' });
      isValid = false;
    } else {
      setFilingError(false);
    }


    if (!isValid) {
      return; // Prevent submission if validation fails
    }


    const additionalServicesTotal = selectedServices.reduce(
      (sum, service) => sum + service.price,
      0
    );

    const formOrderData = {
      selectedState: selectedStateName, // Pass state_name
      selectedStateId: selectedStateId,
      selectedEntity: selectedEntityName,
      selectedEntityId: selectedEntityId,
      stateFee: stateFee,
      selectFillingTime: selectedFiling,
      filing_type: selectedFilingType,
      package: selectedPackage,
      packageFee: getPrice(),
      state_fee: stateFeeTotal(),
      additionalServices: JSON.stringify(selectedServices),
      additionalServicesPrice: additionalServicesTotal,
      formTotalPrice: getTotalPrice(),
    };

    const endpoint = extractedId
      ? `${config.baseUrl}/registration/form-1-update/${extractedId}`
      : `${config.baseUrl}/registration/form-1`;

    try {
      const response = await fetch(endpoint, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(formOrderData),
      });

      if (!response.ok) {
        throw new Error("Failed to submit data");
      }

      const result = await response.json();
      console.log("Success:", result);
      localStorage.setItem("formOrderResult", JSON.stringify(result));

      navigate(`/process-coinfo`);

    } catch (error) {

      console.error("Error:", error);
    }
  };

  return (
    <>
      <Header />
      <section className="form-order">
        <div className="container">
          <div className="form-sub">
            <div className="row">
              <div className="col-md-12">
                <div className="form-order-heading1">
                  <h1>
                    Start your business <span>with confidence</span>
                  </h1>
                  <p>
                    Join over 1,000,000 happy business owners. Get started by
                    choosing your entity type and state of formation.
                  </p>
                </div>
              </div>
              <div className="col-md-6">
                <div className="select1">
                  <div className="left-entity">
                    <div className="entiity">1</div>
                  </div>
                  <div className="right-select">
                    <label className="floating-label">Entity Type</label>
                    <select
                      className="form-select"
                      aria-label="Select Entity Type"
                      value={selectedEntityId}
                      onChange={handleEntityChange}

                    >
                      <option value="" disabled>Select Entity</option>
                      {entityNames.map((entity) => (
                        <option key={entity.id} value={entity.id}>
                          {entity.entity_name}
                        </option>
                      ))}
                    </select>
                  </div>
                </div>
                {entityError && <span className="error-text">Entity Type is required</span>}
              </div>
              <div className="col-md-6">
                <div className="select1">
                  <div className="left-entity">
                    <div className="entiity">2</div>
                  </div>
                  <div className="right-select">
                    <label className="floating-label">State</label>
                    <select
                      id="state-select"
                      className="form-select"
                      value={selectedStateId}
                      onChange={handleStateChange}
                    >
                      <option value="" disabled>Select State</option>
                      {stateNames.map((state) => (
                        <option key={state.id} value={state.id}>
                          {state.state_name}
                        </option>
                      ))}
                    </select>
                  </div>

                </div>
                {stateError && <span className="error-text">State is required</span>}
              </div>

              {selectedEntityId && selectedStateId && (
                <div className="col-md-6">
                  <div className="select1">
                    <div className="left-entity">
                      <div className="entiity">3</div>
                    </div>
                    <div className="right-select">
                      <label className="floating-label">Filing Type</label>
                      <select
                        id="filingTypeSelect"
                        className="form-control"
                        value={selectedFilingType}
                        onChange={handleFilingTypeChange}
                      >
                        <option value="Select" disabled>Select</option>
                        <option value="Standard">Standard</option>
                        <option value="Expedited">Expedited</option>
                      </select>
                    </div>
                  </div>
                  {filingTypeError && <span className="error-text">Filing Type is required</span>}
                </div>
              )}

              {selectedFilingType !== "Select" && (
                <div className="col-md-6">
                  <div className="select1">
                    <div className="left-entity">
                      <div className="entiity">4</div>
                    </div>
                    <div className="right-select">
                      <label className="floating-label">Select Filing</label>
                      <select
                        id="filingOptionSelect"
                        className="form-control"
                        value={selectedFiling}
                        onChange={handleFilingChange}
                      >
                        <option value="" disabled>Select Filing Option</option>
                        {filingOptions.map((option) => (
                          <option key={option.days_name} value={option.days_name}>
                            {option.days_name}
                          </option>
                        ))}
                      </select>
                    </div>
                  </div>
                  {filingError && <span className="error-text">Filling Days is required</span>}
                </div>
              )}
              {filingTypeError && <span className="error-text">Filing Type is required</span>}

            </div>
          </div>
        </div>
      </section>
      <div className="compare-1">
        <div className="comparion-part1">
          <div className="container">
            <div className="table-responsive">
              <table style={{ width: "100%" }}>
                <tbody>
                  <tr
                    style={{
                      width: "100%",
                      backgroundColor: "var(--white)",
                      borderRadius: 4,
                    }}
                  >
                    <th style={{ padding: "18px 20px", borderRadius: 6 }}>
                      <h2>Compare Packages</h2>
                      <h6>
                        <strong>One-time fee</strong>
                      </h6>
                      <p>
                        Unlike other companies that charge annual fees, our
                        business formation fee is one-time.
                      </p>
                    </th>
                    {planData.map((filing) => (
                      <th
                        style={{ padding: "18px 20px", textAlign: "center" }}
                        className={
                          selectedPackage === `${filing.plan_name}`
                            ? "active-header"
                            : ""
                        }
                        key={filing.plan_name}
                      >
                        <p>
                          <strong>{filing.plan_name}</strong>
                        </p>
                        <h5>
                          <span>${filing.price}</span> + ${stateFee} state fee
                        </h5>
                        <div
                          className="table-btn"
                          style={{ textAlign: "center" }}
                        >
                          <button
                            type="button"
                            className={`basic-btn2 ${selectedPackage === filing.plan_name ? "btn-red-600" : ""}`}
                            onClick={() => handlePackageChange(filing.plan_name)} // Use a function to handle package change
                          >
                            {filing.plan_name}
                          </button>

                        </div>
                      </th>
                    ))}
                  </tr>
                  {additionalData.map((item) => (
                    <tr style={{ backgroundColor: "var(--white)" }} key={item.id}>
                      <td style={{ padding: 15, borderRadius: 6 }}>
                        <h6
                          style={{
                            color: "var(--black)",
                            marginBottom: 0,
                            fontSize: 14,
                          }}
                        >
                          {item.name}
                        </h6>
                        <div className="select-box">{item.description}</div>
                      </td>

                      {planData.map((filing) => (
                        <td
                          className={
                            selectedPackage === `${filing.plan_name}` ? "active-column" : ""
                          }
                          style={{ padding: "18px 20px", textAlign: "center" }}
                          key={filing.plan_name}
                        >
                          <div>
                            <span>
                              {item.items
                                .filter((subItem) => subItem.plan_name === filing.plan_name)
                                .map((subItem) => (
                                  subItem.price === 0 ? (
                                    <img src="images/Vector (2).png" alt="No Data" key={subItem.id} />
                                  ) : (
                                    <button
                                      className={`toggle-button ${toggledButtons[subItem.id]?.active ? "active" : ""
                                        }`}
                                      onClick={() => {
                                        // Toggle service selection
                                        const isActive = !toggledButtons[subItem.id]?.active;
                                        const newToggledButtons = {
                                          ...toggledButtons,
                                          [subItem.id]: { active: isActive },
                                        };
                                        setToggledButtons(newToggledButtons);

                                        // Add or remove from selected services
                                        if (isActive) {
                                          setSelectedServices((prev) => [
                                            ...prev,
                                            { name: item.name, price: subItem.price },
                                          ]);
                                        } else {
                                          setSelectedServices((prev) =>
                                            prev.filter(
                                              (service) =>
                                                !(
                                                  service.name === item.name &&
                                                  service.price === subItem.price
                                                )
                                            )
                                          );
                                        }
                                      }}
                                      disabled={selectedPackage !== subItem.plan_name}
                                      key={subItem.id}
                                    >
                                      {toggledButtons[subItem.id]?.active
                                        ? `✓ $${subItem.price}`
                                        : `+ $${subItem.price}`}
                                    </button>
                                  )
                                ))}
                            </span>
                          </div>
                        </td>
                      ))}
                    </tr>
                  ))}

                  <tr style={{ backgroundColor: "#fafafa" }}>
                    <td colSpan={4} style={{ padding: 15, borderRadius: 6 }}>
                      <div className="row">
                        <div className="col-md-6">
                          <div className="selected1">
                            <h4>You’ve selected: {selectedPackage} Package</h4>
                            <p>
                              Comprehensive features to get your business
                              started.
                            </p>
                            <p>
                              State of Formation:{" "}
                              <strong>{selectedStateName}</strong>
                            </p>
                            <p>
                              Business Entity:{" "}
                              <strong>{selectedEntityName}</strong>
                            </p>
                            <p>One-Time Fee</p>
                          </div>
                        </div>
                        <div className="col-md-6">
                          <div className="form11">
                            <div className="form12">
                              <div className="left-11">
                                <p>{selectedPackage} package</p>
                              </div>
                              <div className="left-12">
                                <h5>${getPrice()}</h5>
                              </div>
                            </div>
                            <div className="form12">
                              <div className="left-11">
                                <p>{selectedStateName} State fee</p>
                              </div>
                              <div className="left-12">
                                <h5>${stateFee}</h5>
                              </div>
                            </div>
                            <div colSpan={additionalData.length + 1}>
                              <h3>Selected Services</h3>
                              <ul>
                                {selectedServices.map((service, index) => (
                                  <li key={index}>
                                    <div className="price-part">
                                      <div className="price-name">
                                        {service.name}
                                      </div>
                                      <div className="price-value">
                                        ${service.price}
                                      </div>
                                    </div>

                                  </li>
                                ))}
                              </ul>
                            </div>

                            <hr />
                            <div className="form12">
                              <div className="left-11">
                                <p>
                                  <strong>Total</strong>
                                </p>
                              </div>
                              <div className="left-12">
                                <h5>
                                  <strong>${getTotalPrice()}</strong>
                                </h5>
                              </div>
                            </div>
                            <div>
                              {" "}
                              <button
                                type="button"
                                className="continue"
                                onClick={handleSubmit}
                              >
                                Continue
                              </button>
                            </div>
                          </div>
                        </div>
                      </div>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default FormOrder;